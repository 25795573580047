<template>
    <v-card :style="`background-color: ${backgroundColor}`">
        <v-card-text>
            <alert v-if="message" type="warning"> {{ message }}</alert>
        </v-card-text>
    </v-card>
</template>

<script lang="ts">
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

    import { ProtocolPreviewDto } from '@/model/Exam/DrugProtocol/Preview/ProtocolPreviewDto';
    import { NhifDrugDto } from '@/model/Nomenclature/NhifDrugDto';
    import { PatientPrescriptionDrugDto } from '@/model/Query/Patient/PatientPrescriptionDrugDto';
    import { nhifProtocolService } from '@/service/Nhif/NhifProtocolService';
    import { patientPrescriptionDrugService } from '@/service/Query/Patient/PatientPrescriptionDrugService';
    import { currentPatientCache } from '@/store/CurrentPatientCache';

    @Component({})
    export default class ProtocolPrescriptionDrugLimit extends Vue {
        @Prop()
        protocolNumber!: string;

        @Prop()
        nhifDrugs!: NhifDrugDto[];

        @Prop()
        prescriptionId!: number;

        @Prop({ default: 'transparent' })
        private backgroundColor!: string;

        private message: string = '';
        private protocol: ProtocolPreviewDto | null = null;
        private patientPrescriptionDrugs: PatientPrescriptionDrugDto[] = [];

        private checkDrugsQuantity() {
            this.message = '';
            const uniqueNhifCodeDrugs = [...new Set(this.nhifDrugs.map((item) => item?.nhifCode))];
            uniqueNhifCodeDrugs.forEach((ccode) => {
                this.checkDrugQuantityExceed(ccode);
            });
        }

        private checkDrugQuantityExceed(drugNhifCode: string | null) {
            if (
                drugNhifCode &&
                this.protocol &&
                this.patientPrescriptionDrugs?.length > 0 &&
                this.nhifDrugs &&
                this.protocol?.medications?.some((medication) => medication.nhifCode === drugNhifCode)
            ) {
                let prescriptionPrescribedCount = 0;
                //Събираме количествата от всички рецепти изписани по конкрентното лекарство, като не включваме текущата рецепта
                this.patientPrescriptionDrugs.forEach((patientDrug) => {
                    if (
                        patientDrug.drug.includes(drugNhifCode) &&
                        patientDrug.prescriptionKey.prescriptionId !== this.prescriptionId
                    ) {
                        prescriptionPrescribedCount += patientDrug.packageCount;
                    }
                });
                //Сумираме количеството от всички лекарства, които са с този НЗОК код, защото в тройна рецепта може това лекарство да присъства до 3 пъти
                let drugNhifCodeAllPackageCount = 0;
                this.nhifDrugs.forEach((item) => {
                    if (item?.nhifCode === drugNhifCode) {
                        drugNhifCodeAllPackageCount += item.packageCount;
                    }
                });
                //Добавяме и количеството от текущата рецепта към общия брой на изписаното количество
                prescriptionPrescribedCount += drugNhifCodeAllPackageCount;

                const protocolDrug = this.protocol.medications.find(
                    (medication) => medication.nhifCode === drugNhifCode
                );
                if (protocolDrug?.quantity && protocolDrug.quantity < prescriptionPrescribedCount) {
                    const text = `Надвишено количество за лекарство ${drugNhifCode} : изписано до момента ${prescriptionPrescribedCount}, общо количество за целия протокол ${protocolDrug?.quantity}`;
                    this.message += `${text} \r\n`;

                    this.$notifier.showWarning('Внимание', text);
                }
            }
        }

        private async getProtocolByNrn() {
            this.$loading.show();
            try {
                this.protocol = await nhifProtocolService.getProtocolPreviewByNrn(
                    currentPatientCache.value.key.patientId,
                    this.protocolNumber
                );
            } finally {
                this.$loading.hide();
            }
        }

        private async getPatientPrescriptionDrugsByProtocol() {
            this.$loading.show();
            try {
                this.patientPrescriptionDrugs =
                    await patientPrescriptionDrugService.getPatientNhifPrescriptionDrugsByProtocol(
                        currentPatientCache.value.key.patientId,
                        this.protocolNumber
                    );
            } finally {
                this.$loading.hide();
            }
        }

        @Watch('protocolNumber')
        async onProtocolNumberChange(val: string | null) {
            const protocolNrnLen = 12;
            if (val && val.length === protocolNrnLen) {
                await this.getProtocolByNrn();
                await this.getPatientPrescriptionDrugsByProtocol();
                this.checkDrugsQuantity();
            } else {
                this.protocol = null;
                this.patientPrescriptionDrugs = [];
            }
        }

        @Watch('nhifDrugs', { immediate: true, deep: true })
        onCurrentDrugChange() {
            this.checkDrugsQuantity();
        }
    }
</script>

<style scoped></style>
