import { SickSheetStatusCode } from '@/enum/Exam/SickSheetStatusCode';

export class SickSheetViewDto {
    public id: number = 0;
    public canceledSickSheetId: number | null = null;
    public canceledNumber: string = '';
    public number: string = '';
    public patientIdentifier: string = '';
    public patientName: string = '';
    public statusCode: SickSheetStatusCode = SickSheetStatusCode.None;
    public statusName: string = '';
    public issueDate: Date = new Date();
}
