<template>
    <exam-layout
        :can-save="!disableEdit"
        :can-delete="!disableEdit && document.key.prescriptionId > 0"
        :can-print="document.key.prescriptionId > 0 && isWhite"
        :title="`${title} рецепта`"
        @updated="updateEventHandler"
        @deleted="deleteEventHandler"
        @printed="printEventHandler"
        @printed-without-preview="printWithoutPreviewHandler"
    >
        <nhis-prescription
            v-if="document.key.prescriptionId > 0 && document.nhisPrescription"
            v-model="document.nhisPrescription"
            :prescription-key="prescriptionKey"
            @input="copyObject"
            @reload-prescription="load"
        />
        <v-card :disabled="disableEdit" :style="`background-color: ${backgroundColor}`">
            <main-title>{{ title }} рецепта</main-title>
            <v-card-text>
                <v-card-text>
                    <AmbulatorySheetHeaderInfoCard v-once :background-color="backgroundColor" />
                </v-card-text>
                <v-row>
                    <v-col cols="12" md="3">
                        <date-picker v-model="document.issueDate" label="Дата на издаване:" required />
                    </v-col>
                    <v-col v-if="isWhite" cols="12" md="3">
                        <text-field v-model.number="document.repeatNumber" required label="Брой отпускания" />
                    </v-col>
                    <v-col v-else cols="12" md="6">
                        <exam-diagnosis-picker v-model="document.examDiagnosis" use-main-icd required dense />
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12">
                        <patient-prescription-drug
                            v-model="document"
                            :form-type="formType"
                            :disabled="disableEdit"
                            is-mega-prescription
                        />
                    </v-col>
                </v-row>

                <v-row v-for="(nhisDrug, index) in document.nhisDrugs" :key="index">
                    <drug-component
                        v-model="document.nhisDrugs[index]"
                        :background-color="backgroundColor"
                        :exam-id="document.key.examId"
                        :form-type="formType"
                        :position="index + 1"
                        :issue-date="document.issueDate"
                        :advertisements="advertisements"
                        required-drug
                    />
                    <btn
                        v-if="!disableEdit && document.nhisDrugs.length > 1"
                        icon
                        action="Delete"
                        @click="removeDrug(index)"
                    />
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-textarea v-model="document.supplements" label="Нелекарствени добавки към рецептата" />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <btn v-if="!disableEdit && !disableAddBtn" action="New" @click="addDrug">Добавяне на лекарство</btn>
            </v-card-actions>
        </v-card>
        <UnsavedChanges ref="leaveDialog" />
    </exam-layout>
</template>

<script lang="ts">
    import { Component, Prop, Ref, Vue } from 'vue-property-decorator';

    import ExamDiagnosisPicker from '@/component/Exam/ExamDiagnosisPicker.vue';
    import ExamLayout from '@/component/Exam/ExamLayout.vue';
    import { AdvertisementTypeCode } from '@/enum/AdvertisementTypeCode';
    import { PrescriptionFormTypeCode } from '@/enum/Exam/PrescriptionFormTypeCode';
    import { AdvertisementDto } from '@/model/Advertisement/AdvertisementDto';
    import { PrescriptionKey } from '@/model/Exam/Prescription/PrescriptionKey';
    import { PrescriptionNhifWhiteDto } from '@/model/Exam/Prescription/PrescriptionNhifWhiteDto';
    import { NhifDrugDto } from '@/model/Nomenclature/NhifDrugDto';
    import { advertisementService } from '@/service/AdvertisementService';
    import { prescriptionService } from '@/service/Exam/PrescriptionService';
    import { eventBus } from '@/service/Infrastructure/EventBus';
    import { printService } from '@/service/PrintService';
    import { currentVisitCache } from '@/store/CurrentVisitCache';
    import { userContextCache } from '@/store/User/UserContextCache';
    import { userSettingsState } from '@/store/User/UserSettingsState';
    import { arrayUtil } from '@/util/ArrayUtil';
    import AmbulatorySheetHeaderInfoCard from '@/view/Exam/AmbulatorySheet/AmbulatorySheetHeaderInfoCard.vue';
    import DrugComponent from '@/view/Exam/PrescriptionNhif/DrugComponent.vue';
    import UnsavedChanges from '@/view/Exam/UnsavedChanges.vue';
    import NhisPrescription from '@/view/Nhis/Prescription/NhisPrescription.vue';
    import PatientPrescriptionDrug from '@/view/Query/Patient/PatientPrescriptionDrug.vue';

    @Component({
        components: {
            PatientPrescriptionDrug,
            AmbulatorySheetHeaderInfoCard,
            ExamDiagnosisPicker,
            UnsavedChanges,
            NhisPrescription,
            ExamLayout,
            DrugComponent
        },
        async beforeRouteLeave(to, from, next) {
            await this.$data.leaveDialogRef.check(this.$data.initialValue, this.$data.document, next);
        }
    })
    export default class PrescriptionNhifWhite extends Vue {
        @Ref()
        readonly leaveDialog!: UnsavedChanges;

        @Prop({ required: true })
        private prescriptionKey!: PrescriptionKey;

        @Prop()
        private formType!: string;

        private document: PrescriptionNhifWhiteDto = new PrescriptionNhifWhiteDto();
        private initialValue: PrescriptionNhifWhiteDto = new PrescriptionNhifWhiteDto();
        private leaveDialogRef: UnsavedChanges = new UnsavedChanges();
        private advertisements: AdvertisementDto[] = [];

        private get title() {
            return this.formType === PrescriptionFormTypeCode.Green
                ? 'Зелена'
                : this.formType === PrescriptionFormTypeCode.Yellow
                  ? 'Жълта'
                  : 'Бяла';
        }

        private get backgroundColor() {
            return this.formType === PrescriptionFormTypeCode.Green
                ? this.backgroundGreen
                : this.formType === PrescriptionFormTypeCode.Yellow
                  ? this.backgroundYellow
                  : 'transparent';
        }

        private get backgroundYellow() {
            return !userSettingsState.userSettings.ui.hasReferralsBackground
                ? 'transparent'
                : userSettingsState.userSettings.ui.isDarkTheme
                  ? '#807841'
                  : '#d5ce97';
        }

        private get backgroundGreen() {
            return !userSettingsState.userSettings.ui.hasReferralsBackground
                ? 'transparent'
                : userSettingsState.userSettings.ui.isDarkTheme
                  ? '#5c7867'
                  : '#c5e1d0';
        }

        private get isWhite() {
            return this.formType === PrescriptionFormTypeCode.White;
        }

        private get disableEdit() {
            return this.document?.nhisPrescription?.nrn?.length > 0;
        }

        private get disableAddBtn() {
            const maxDrugCount = 3;
            return this.formType !== PrescriptionFormTypeCode.White && this.document?.nhisDrugs?.length >= maxDrugCount;
        }

        private addDrug() {
            this.document.nhisDrugs.push(new NhifDrugDto());
        }

        private removeDrug(index: number) {
            arrayUtil.removeAt(this.document.nhisDrugs, index);
            this.updateDrugPosition();
        }

        private updateDrugPosition() {
            for (let index = 0; index < this.document.nhisDrugs.length; index++) {
                this.document.nhisDrugs[index].position = index + 1;
            }
        }

        private async updateEventHandler() {
            this.$loading.show();
            try {
                let { prescriptionId } = this.document.key;
                this.document.formTypeCode = this.formType;
                if (prescriptionId === 0) {
                    prescriptionId = await prescriptionService.createWhite(this.document);
                    if (prescriptionId) {
                        this.copyObject();
                        eventBus.$emit(
                            'create-referral-event',
                            prescriptionId,
                            `PrescriptionNhif${this.formType}`,
                            `${this.title} рецепта`
                        );
                        this.$router.push(
                            `/Exam/PrescriptionNhif${this.formType}/Edit/${this.prescriptionKey.examId}/${prescriptionId}`
                        );
                    }
                } else {
                    await prescriptionService.updateWhite(this.document);
                    eventBus.$emit('update-referral-event', `${this.title} рецепта`);
                    this.copyObject();
                }
            } finally {
                this.$loading.hide();
            }
        }

        private async deleteEventHandler() {
            this.$loading.show();
            try {
                this.copyObject();
                await prescriptionService.delete(this.prescriptionKey);
                this.$router.push(`/Exam/PrescriptionNhif${this.formType}/Create/${this.prescriptionKey.examId}`);
                eventBus.$emit(
                    'delete-referral-event',
                    this.prescriptionKey.prescriptionId,
                    `PrescriptionNhif${this.formType}`,
                    `${this.title} рецепта`
                );
            } finally {
                this.$loading.hide();
            }
        }

        private printEventHandler() {
            if (this.prescriptionKey.prescriptionId) {
                this.$router.push(
                    `/Print/Exam.Prescription.PrescriptionNhif${this.formType}/Prescription/${this.prescriptionKey.examId}/${this.prescriptionKey.prescriptionId}`
                );
            }
        }

        private async printWithoutPreviewHandler() {
            this.$loading.show();
            try {
                const report = `Exam.Prescription.PrescriptionNhif${this.formType}`;
                const parameters: { [key: string]: string } = {};
                parameters.examId = this.prescriptionKey.examId.toString();
                parameters.prescriptionId = this.prescriptionKey.prescriptionId.toString();
                await printService.printReport(report, parameters);
            } finally {
                this.$loading.hide();
            }
        }

        private copyObject() {
            this.initialValue = this.$lodash.cloneDeep(this.document);
        }

        private async load() {
            this.$loading.show();
            try {
                this.leaveDialogRef = this.leaveDialog;
                await currentVisitCache.load(this.prescriptionKey.examId);
                if (!this.prescriptionKey.prescriptionId) {
                    this.document = await prescriptionService.getPrescriptionWhiteInfoByExam(
                        this.prescriptionKey.examId
                    );
                    if (!this.isWhite) {
                        this.document.examDiagnosis = currentVisitCache.value.exam?.examDiagnoses[0] ?? null;
                    }
                    this.document.nhisDrugs.push(new NhifDrugDto());
                } else {
                    this.document = await prescriptionService.getPrescriptionWhite(this.prescriptionKey);
                    const diagId = this.document.examDiagnosis?.id;
                    if (diagId) {
                        const examDiagnosis = currentVisitCache.value.exam?.examDiagnoses.find(
                            (diagnosis) => diagnosis.id === diagId
                        );
                        this.document.examDiagnosis = examDiagnosis ?? null;
                    }
                }
            } finally {
                this.$loading.hide();
            }
        }

        private async getAdvertisements() {
            const { current } = userContextCache;
            if (current) {
                this.advertisements = await advertisementService.getValidAdvertisements({
                    practiceId: current.practiceId,
                    advertisementTypeCode: AdvertisementTypeCode.Prescription,
                    icdCode: null
                });
            }
        }

        private async mounted() {
            await this.load();
            this.copyObject();
            await this.getAdvertisements();
        }
    }
</script>

<style scoped>
    .v-icon.v-icon::after {
        width: 0;
    }
</style>
