import { Type } from 'class-transformer';

import { NhifVisitReasonCode } from '@/enum/Exam/NhifVisitReasonCode';
import { FundCode } from '@/enum/Nomenclature/FundCode';
import { AmbulatorySheetDiagnosisDto } from '@/model/Exam/AmbulatorySheetDiagnosisDto';
import { AmbulatorySheetMedicalProcedureDto } from '@/model/Exam/AmbulatorySheetMedicalProcedureDto';
import { AmbulatorySheetReferral3aDto } from '@/model/Exam/AmbulatorySheetReferral3aDto';
import { AmbulatorySheetReferral3Dto } from '@/model/Exam/AmbulatorySheetReferral3Dto';
import { AmbulatorySheetReferral4Dto } from '@/model/Exam/AmbulatorySheetReferral4Dto';
import { IncomingReferralDto } from '@/model/Exam/IncomingReferralDto';
import { NhisExamDto } from '@/model/Nhis/Exam/NhisExamDto';
import { NhisMedicalHistoryDto } from '@/model/Nhis/Exam/NhisMedicalHistoryDto';
import { PatientVersionKey } from '@/model/Patient/PatientVersionKey';

export class ExamDto {
    public id: number = 0;
    public isDeleted: boolean = false;

    @Type(() => PatientVersionKey)
    public patientVersionKey: PatientVersionKey = new PatientVersionKey();

    public primaryExamId: number | null = null;
    public startDateTime: Date = new Date();
    public doctorEmployeeSeqNumber: number = 0;
    public lkkCommitteeSeqNumber: number | null = null;
    public symptoms: string = '';
    public condition: string = '';
    public tests: string = '';
    public treatment: string = '';
    // Debug информация - дали текстовите полета са заредени от blob storage.
    public debugMonthETag: string = '';
    // От сървъра винаги идва число за номер на АЛ, но при започване на нов АЛ, номерът първоначално е null.
    public ambulatorySheetNumber: number | null = null;
    public isSecondary: boolean = false;
    public isDomestic: boolean = false;
    public nhifVisitReasonCode: NhifVisitReasonCode = NhifVisitReasonCode.Consultation;
    public isIncidental: boolean = false;
    public isInRegister: boolean = false;
    public fundCode: FundCode = FundCode.Patient;
    public isCourseCompleted: boolean = false;
    public signature: string | null = null;
    public nhisPreventiveExamTypeCode: string[] = [];
    @Type(() => NhisMedicalHistoryDto)
    public nhisMedicalHistory: NhisMedicalHistoryDto | null = new NhisMedicalHistoryDto();

    public practiceNumber: string = '';
    public doctorUin: string = '';
    public doctorNames: string = '';
    public deputyDoctorId: number | null = null;
    public deputyDoctorIsHired: boolean | null = null;
    public immunizations: string[] = [];
    public immunizationMantu: string = '';
    public hasMedicalExpertise: boolean = false;
    public hasHospitalization: boolean = false;
    public hasTelk: boolean = false;
    public lastRegularMenstruation: Date | null = null;
    public gestationalWeek: number | null = null;
    public probableBirthTerm: Date | null = null;
    public pregnancyEndDate: Date | null = null;
    public isInDisadvantagedRegion: boolean = false;
    public isPatientAdult: boolean = false;

    @Type(() => IncomingReferralDto)
    public incomingReferral: IncomingReferralDto = new IncomingReferralDto();

    public nhisExam: NhisExamDto = new NhisExamDto();

    @Type(() => AmbulatorySheetReferral3Dto)
    public referral3: AmbulatorySheetReferral3Dto[] = [];

    @Type(() => AmbulatorySheetReferral3aDto)
    public referral3a: AmbulatorySheetReferral3aDto[] = [];

    @Type(() => AmbulatorySheetReferral4Dto)
    public referral4: AmbulatorySheetReferral4Dto[] = [];

    @Type(() => AmbulatorySheetMedicalProcedureDto)
    public medicalProcedures: AmbulatorySheetMedicalProcedureDto[] = [
        new AmbulatorySheetMedicalProcedureDto(),
        new AmbulatorySheetMedicalProcedureDto(),
        new AmbulatorySheetMedicalProcedureDto(),
        new AmbulatorySheetMedicalProcedureDto()
    ];

    @Type(() => AmbulatorySheetDiagnosisDto)
    public diagnoses: AmbulatorySheetDiagnosisDto[] = [
        new AmbulatorySheetDiagnosisDto(),
        new AmbulatorySheetDiagnosisDto(),
        new AmbulatorySheetDiagnosisDto(),
        new AmbulatorySheetDiagnosisDto(),
        new AmbulatorySheetDiagnosisDto()
    ];

    public medicalExpertiseNumber: number | null = null;
    public sickSheetNumber: string = '';
    public sickSheetDays: number | null = null;
    public sickSheetIcdCode: string = '';
    public sickSheetStartDate: Date | null = null;
    public sickSheetEndDate: Date | null = null;
    public sickSheetIsContinuation: boolean | null = null;
    public hasPrescription: boolean = false;
    public prescriptionBookNumber: string = '';
    public hasQuickNotification: boolean = false;
    public hasStagedEpicrisis: boolean = false;
    public hasMedicalNote: boolean = false;
    public fiscalDeviceSeqNumber: number | null = null;
    public fiscalMemoryCode: string | null = null;
    public fiscalReceiptNumber: string = '';
    public copyFreePrescriptions: boolean = false;
    public copyGreenPrescriptions: boolean = false;
    public copyYellowPrescriptions: boolean = false;
    public copyWhitePrescriptions: boolean = false;
    public copiedExamId: number | null = null;
    public existingPrescriptions: boolean = false;
    public isNewFound: boolean = false;
    public isPrinted: boolean = false;
    public isSigned: boolean = false;

    // Този getter е bind-нат в template, така че да показва в реално време дали поне една от диагнозите е новооткрито заболяване.
    // Резултатът от последното изпълнение на getter-а се копира в member променлива isNewFound, за да може тя да се запише в базата.
    public get isNewFoundComputed(): boolean {
        this.isNewFound = this.diagnoses.some((diagnosis) => diagnosis.isNewFound);
        return this.isNewFound;
    }

    public get hasReferral3(): boolean {
        return this.referral3.length > 0;
    }

    public get hasReferral3a(): boolean {
        return this.referral3a.length > 0;
    }

    public get hasReferral4(): boolean {
        return this.referral4.length > 0;
    }

    public get hasSickSheet(): boolean {
        return Boolean(this.sickSheetDays);
    }

    public get hasPrescriptionBook(): boolean {
        return Boolean(this.prescriptionBookNumber);
    }
}
