export enum ExamDocumentTypeCode {
    Immunization = 'Immunization',
    EImmunization = 'EImmunization',
    Referral3 = 'Referral3',
    Referral3a = 'Referral3a',
    Referral4 = 'Referral4',
    PrescriptionNhif = 'PrescriptionNhif',
    PrescriptionNhifTypeA = 'PrescriptionNhifTypeA',
    PrescriptionNhifTypeB = 'PrescriptionNhifTypeB',
    PrescriptionNhifTypeC = 'PrescriptionNhifTypeC',
    PrescriptionNhifWhite = 'PrescriptionNhifWhite',
    PrescriptionNhifGreen = 'PrescriptionNhifGreen',
    PrescriptionNhifYellow = 'PrescriptionNhifYellow',
    Referral6 = 'Referral6',
    Referral7 = 'Referral7',
    Referral8 = 'Referral8',
    Referral8a = 'Referral8a',
    ReferralRequestFor = 'ReferralRequestFor',
    Manipulation = 'Manipulation',
    SickSheet = 'SickSheet',
    StagedEpicrisis = 'StagedEpicrisis',
    CertificateWork = 'CertificateWork',
    CertificateDrive = 'CertificateDrive',
    ECertificateDrive = 'ECertificateDrive',
    CertificateMariage = 'CertificateMariage',
    CertificatePhysicalExam = 'CertificatePhysicalExam',
    MedicalNote = 'MedicalNote',
    ReferralDispObserv = 'ReferralDispObserv',
    ReferralTelk = 'ReferralTelk',
    CertificateSocialServices = 'CertificateSocialServices',
    QuickNotice = 'QuickNotice',
    InformedConsentTreatment = 'InformedConsentTreatment',
    InformedConsentDiagnostics = 'InformedConsentDiagnostics',
    PhysiotherapyCard = 'PhysiotherapyCard',
    ConsultationTelk = 'ConsultationTelk',
    RequestForHistopathologicalTest = 'RequestForHistopathologicalTest',
    RequestForCytosmear = 'RequestForCytosmear',
    RequestForImagingTest = 'RequestForImagingTest',
    DrugProtocol = 'DrugProtocol',
    NhifProtocol = 'NhifProtocol',
    OfficialNotice = 'OfficialNotice',
    ChildHealthCard = 'ChildHealthCard',
    Anthropometry = 'Anthropometry',
    FileUpload = 'FileUpload',
    Results = 'Results',
    RiskAssessmentCard = 'RiskAssessmentCard',
    PrescriptionV2Nhif5 = 'PrescriptionV2Nhif5',
    PrescriptionV2Nhif5a = 'PrescriptionV2Nhif5a',
    PrescriptionV2Nhif5b = 'PrescriptionV2Nhif5b',
    PrescriptionV2Nhif5c = 'PrescriptionV2Nhif5c',
    LkkDecision = 'LkkDecision'
}
