import { Route, RouteConfig } from 'vue-router';

const reportChildRoutes: RouteConfig[] = [
    // Отчети - ПИС.
    {
        path: 'Pis/SpecificationReport/:practiceId',
        props: {
            report: (route: Route) => ({
                practiceId: Number(route.params.practiceId)
            })
        },
        components: {
            report: () => import('@/view/Report/Pis/SpecificationReport.vue')
        }
    },
    {
        path: 'Pis/FiscalReceipt/:practiceId/:doctorEmployeeSeqNumber',
        props: {
            report: (route: Route) => ({
                practiceId: Number(route.params.practiceId),
                doctorEmployeeSeqNumber: Number(route.params.doctorEmployeeSeqNumber)
            })
        },
        components: {
            report: () => import('@/view/Report/Pis/FiscalReceiptReport.vue')
        }
    },
    {
        path: 'Pis/FiscalReport/:practiceId/:doctorEmployeeSeqNumber',
        props: {
            report: (route: Route) => ({
                practiceId: Number(route.params.practiceId),
                doctorEmployeeSeqNumber: Number(route.params.doctorEmployeeSeqNumber)
            })
        },
        components: {
            report: () => import('@/view/Report/Pis/FiscalReport.vue')
        }
    },
    {
        path: 'Pis/GpExamClaim/:practiceId/:doctorEmployeeSeqNumber',
        props: {
            report: (route: Route) => ({
                practiceId: Number(route.params.practiceId),
                doctorEmployeeSeqNumber: Number(route.params.doctorEmployeeSeqNumber)
            })
        },
        components: {
            report: () => import('@/view/Report/Pis/Gp/GpExamClaim.vue')
        }
    },
    {
        path: 'Pis/PatientList/:practiceId/:doctorEmployeeSeqNumber',
        props: {
            report: (route: Route) => ({
                practiceId: Number(route.params.practiceId),
                doctorEmployeeSeqNumber: Number(route.params.doctorEmployeeSeqNumber)
            })
        },
        components: {
            report: () => import('@/view/Report/Pis/PatientListReport.vue')
        }
    },
    {
        path: 'Pis/RiskAssessmentCard/:practiceId/:doctorEmployeeSeqNumber',
        props: {
            report: (route: Route) => ({
                practiceId: Number(route.params.practiceId),
                doctorEmployeeSeqNumber: Number(route.params.doctorEmployeeSeqNumber)
            })
        },
        components: {
            report: () => import('@/view/Report/Pis/Gp/RiskAssessmentCardReport.vue')
        }
    },
    {
        path: 'Pis/Invoice/Details/:practiceId',
        props: {
            report: (route: Route) => ({ practiceId: Number(route.params.practiceId) })
        },
        components: {
            report: () => import('@/view/Invoice/PisInvoiceLines.vue')
        }
    },
    {
        path: 'Pis/Invoice/:practiceId/:doctorEmployeeSeqNumber',
        props: {
            report: (route: Route) => ({
                practiceId: Number(route.params.practiceId),
                doctorEmployeeSeqNumber: Number(route.params.doctorEmployeeSeqNumber)
            })
        },
        components: {
            report: () => import('@/view/Invoice/PisInvoice.vue')
        }
    },

    // Отчети - РЗИ.
    {
        path: 'Rhi/RhiImmunizationReport/:practiceId/:doctorEmployeeSeqNumber',
        props: {
            report: (route: Route) => ({
                practiceId: Number(route.params.practiceId),
                doctorEmployeeSeqNumber: Number(route.params.doctorEmployeeSeqNumber)
            })
        },
        components: {
            report: () => import('@/view/Report/Rhi/Immunization/ImmunizationReport.vue')
        }
    },
    {
        path: 'Rhi/ImmunizationReport/Create/:practiceId/:doctorEmployeeSeqNumber',
        props: {
            report: (route: Route) => ({
                practiceId: Number(route.params.practiceId),
                doctorEmployeeSeqNumber: Number(route.params.doctorEmployeeSeqNumber)
            })
        },
        components: {
            report: () => import('@/view/Report/Rhi/Immunization/ImmunizationReportEdit.vue')
        }
    },
    {
        path: 'Rhi/ImmunizationReport/Edit/:practiceId/:doctorEmployeeSeqNumber/:seqNumber',
        props: {
            report: (route: Route) => ({
                practiceId: Number(route.params.practiceId),
                doctorEmployeeSeqNumber: Number(route.params.doctorEmployeeSeqNumber),
                seqNumber: Number(route.params.seqNumber)
            })
        },
        components: {
            report: () => import('@/view/Report/Rhi/Immunization/ImmunizationReportEdit.vue')
        }
    },
    {
        path: 'Rhi/Report365/:practiceId/:doctorEmployeeSeqNumber',
        props: {
            report: (route: Route) => ({
                practiceId: Number(route.params.practiceId),
                doctorEmployeeSeqNumber: Number(route.params.doctorEmployeeSeqNumber)
            })
        },
        components: {
            report: () => import('@/view/Report/Rhi/Report365/Report365.vue')
        }
    },
    {
        path: 'Rhi/Report365/Create/:practiceId/:doctorEmployeeSeqNumber',
        props: {
            report: (route: Route) => ({
                practiceId: Number(route.params.practiceId),
                doctorEmployeeSeqNumber: Number(route.params.doctorEmployeeSeqNumber)
            })
        },
        components: {
            report: () => import('@/view/Report/Rhi/Report365/Report365Edit.vue')
        }
    },
    {
        path: 'Rhi/Report365/Edit/:practiceId/:doctorEmployeeSeqNumber/:seqNumber',
        props: {
            report: (route: Route) => ({
                practiceId: Number(route.params.practiceId),
                doctorEmployeeSeqNumber: Number(route.params.doctorEmployeeSeqNumber),
                seqNumber: Number(route.params.seqNumber)
            })
        },
        components: {
            report: () => import('@/view/Report/Rhi/Report365/Report365Edit.vue')
        }
    },
    {
        path: 'NhisGroupUpload',
        components: {
            report: () => import('@/view/Query/Nhis/NhisGroupUpload.vue')
        }
    }
];

export const reportRoutes: RouteConfig = {
    path: '/Report',
    component: () => import('@/view/Report/ReportMenu.vue'),
    children: reportChildRoutes
};
