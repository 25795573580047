<template>
    <ExamLayout
        :can-delete="document.id > 0 && !disableForEdit"
        :can-print="document.id > 0"
        :can-save="canSave"
        title="Решение на лекарска консултативна комисия"
        @updated="updateEventHandler"
        @deleted="deleteEventHandler"
        @printed="printEventHandler"
        @printed-without-preview="printWithoutPreviewHandler"
    >
        <lkk-decision-upload
            v-if="documentId"
            class="mb-1"
            :document-id="documentId"
            :status="document.statusCode"
            @status-change="statusChange"
            @reload-document="loadDocumentById"
        />
        <v-card :style="`background-color: ${backgroundColor}`">
            <v-card-text>
                <v-card-title>Решение на лекарска консултативна комисия </v-card-title>
                <v-card-text>
                    <v-row no-gutters>
                        <v-col cols="6" md="2">
                            <text-field
                                v-model="document.lkkDecisionNumber"
                                label="Решение №"
                                type="number"
                                required
                                readonly
                                :disabled="disableForEdit"
                            />
                        </v-col>
                        <v-col cols="6" md="2">
                            <date-picker
                                v-model="document.lkkDecisionDate"
                                label="Решение дата"
                                :disabled="disableForEdit"
                            />
                        </v-col>
                        <v-col cols="6" md="2">
                            <text-field
                                v-model="document.sessionNumber"
                                label="Заседание №"
                                required
                                :disabled="disableForEdit"
                            />
                        </v-col>
                        <v-col cols="6" md="2">
                            <date-picker v-model="document.issueDate" label="От дата" :disabled="disableForEdit" />
                        </v-col>
                        <v-col cols="6" md="2">
                            <dropdown
                                v-model="isDeleted"
                                :items="[
                                    { id: false, name: 'Издаване' },
                                    { id: true, name: 'Заличаване' }
                                ]"
                                label="По повод"
                                disabled
                            />
                        </v-col>
                        <v-col v-if="isDeleted" cols="6" md="2">
                            <text-field
                                v-model="document.deleteReason"
                                label="Причина за заличаване"
                                required
                                :disabled="!canEditDeleteReason"
                            />
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-title>Лечебно заведение ЛКК</v-card-title>
                <v-card-text>
                    <v-row no-gutters>
                        <v-col cols="4" md="2">
                            <text-field v-model="document.nhifCode" disabled label="Рег. № на л.з." />
                        </v-col>
                        <v-col cols="8" md="4">
                            <text-field v-model="document.nameOfHospital" disabled label="Име на лз" />
                        </v-col>
                        <v-col cols="6" md="3">
                            <text-field v-model="document.cityOfHospital" disabled label="гр." />
                        </v-col>
                        <v-col cols="6" md="3">
                            <text-field v-model="document.addressOfHospital" disabled label="адрес" />
                        </v-col>
                    </v-row>
                </v-card-text>
                <lkk-decision-commision v-model="document" :disable-for-edit="disableForEdit" />
                <v-card-title>По повод</v-card-title>
                <v-card-text>
                    <v-row no-gutters>
                        <v-col cols="6" md="3">
                            <text-field
                                v-model="document.complaintNumber"
                                label="Жалба Вх. №"
                                required
                                :disabled="disableForEdit"
                            />
                        </v-col>
                        <v-col cols="6" md="3">
                            <date-picker
                                v-model="document.complaintDate"
                                label="От дата"
                                required
                                :disabled="disableForEdit"
                            />
                        </v-col>
                        <v-col cols="6" md="3">
                            <dropdown
                                v-model="document.appealerTypeCode"
                                :items="appealerTypeCodes"
                                item-value="code"
                                label="Тип заинтересовано лице"
                                required
                                :disabled="disableForEdit"
                            />
                        </v-col>
                        <v-col cols="6" md="3">
                            <text-field
                                v-model="document.appealerName"
                                label="Жалбоподател"
                                required
                                :disabled="disableForEdit"
                            />
                        </v-col>
                        <v-col cols="4" md="3">
                            <dropdown
                                v-model="document.appealReasonCode"
                                :items="appealReasons"
                                item-value="code"
                                label="на основание чл. 112"
                                required
                                :disabled="disableForEdit"
                            />
                        </v-col>
                        <v-col cols="4" md="3">
                            <text-field
                                v-model="document.sickSheetNumber"
                                label="Болничен лист №"
                                required
                                :rules="[$validator.minLength(12), $validator.maxLength(12)]"
                                :disabled="disableForEdit"
                            />
                        </v-col>
                        <v-col cols="4" md="2">
                            <text-field
                                v-model="document.sickSheetDays"
                                label="Брой дни"
                                required
                                type="number"
                                :disabled="disableForEdit"
                                @input="changeSickSheetEndDate"
                            />
                        </v-col>
                        <v-col cols="4" md="2">
                            <date-picker
                                v-model="document.sickSheetStartDate"
                                label="Начало"
                                required
                                :disabled="disableForEdit"
                            />
                        </v-col>
                        <v-col cols="4" md="2">
                            <date-picker
                                v-model="document.sickSheetEndDate"
                                label="Край"
                                required
                                :disabled="disableForEdit"
                            />
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-title>Издаден от</v-card-title>
                <v-card-text>
                    <v-row no-gutters>
                        <v-col cols="4" md="2">
                            <text-field
                                v-model="document.issuedByPracticeNumber"
                                label="Рег. № на ЛЗ."
                                required
                                :disabled="disableForEdit"
                            />
                        </v-col>
                        <v-col cols="8" md="4">
                            <text-field
                                v-model="document.issuedByPracticeName"
                                label="Издаден от ЛЗ"
                                required
                                :disabled="disableForEdit"
                            />
                        </v-col>
                        <v-col cols="6" md="3">
                            <city-picker
                                v-model="document.issuedByPracticeCityId"
                                label="гр."
                                required
                                :disabled="disableForEdit"
                            />
                        </v-col>
                        <v-col cols="6" md="3">
                            <text-field
                                v-model="document.issuedByPracticeAddress"
                                label="адрес"
                                required
                                :disabled="disableForEdit"
                            />
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-title>Осигурен</v-card-title>
                <v-card-text>
                    <v-row no-gutters>
                        <v-col cols="2" md="1">
                            <PidTypePicker v-model="currentPatient.personIdentification.pidTypeCode" disabled />
                        </v-col>

                        <v-col cols="4" md="2">
                            <text-field
                                v-model="currentPatient.personIdentification.identifier"
                                disabled
                                label="Идентификатор"
                            />
                        </v-col>
                        <v-col cols="6" md="3">
                            <text-field v-model="currentPatient.firstName" disabled label="Име" />
                        </v-col>
                        <v-col cols="6" md="3">
                            <text-field v-model="currentPatient.middleName" disabled label="Презиме" />
                        </v-col>
                        <v-col cols="6" md="3">
                            <text-field v-model="currentPatient.lastName" disabled label="Фамилия" />
                        </v-col>
                        <v-col cols="6" md="4">
                            <text-field v-model="currentPatient.address.cityName" disabled label="Нас. място" />
                        </v-col>
                        <v-col cols="6" md="4">
                            <text-field v-model="currentPatient.address.street" disabled label="Улица" />
                        </v-col>
                        <v-col cols="6" md="4">
                            <text-field v-model="currentPatient.address.streetNumber" disabled label="Номер" />
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-title>Месторабота</v-card-title>
                <v-card-text>
                    <v-row no-gutters>
                        <v-spacer />
                        <v-col>
                            <dropdown
                                v-model="document.patientWorkplaceId"
                                :items="patientWorkplaces"
                                :item-text="workplacePosition"
                                no-data-text="Няма въведена месторабота"
                                label="Избор на месторабота"
                                required
                                :disabled="disableForEdit"
                            />
                        </v-col>
                        <v-col cols="auto">
                            <btn icon action="Edit" :disabled="disableForEdit" @click="openWorkplaceDialog" />
                        </v-col>

                        <v-dialog v-model="workplaceDialogIsVisible" persistent>
                            <v-card>
                                <v-card-title>
                                    <v-toolbar flat>
                                        <v-toolbar-title>Избор на Месторабота</v-toolbar-title>
                                        <v-spacer></v-spacer>
                                        <btn icon action="Close" @click="closeDialog" />
                                    </v-toolbar>
                                </v-card-title>
                                <patient-workplace
                                    :id="currentPatient.key.patientId"
                                    :close-clicked="isCloseClicked"
                                    @change="updateWorkplaces"
                                />
                            </v-card>
                        </v-dialog>
                    </v-row>

                    <text-field
                        v-model="workplace.company"
                        disabled
                        required
                        label="Предприятие, учреждение, организация, фирма"
                    />
                    <v-row no-gutters>
                        <v-col cols="6">
                            <CityPicker
                                v-model="workplace.cityId"
                                :required="Boolean(!workplace.foreignCity)"
                                label="гр."
                                disabled
                            />
                        </v-col>
                        <v-col cols="6">
                            <text-field
                                v-model="workplace.foreignCity"
                                label="гр. извън България"
                                disabled
                                :required="!workplace.cityId"
                            ></text-field>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="5">
                            <text-field v-model="workplace.street" disabled label="ул." />
                        </v-col>
                        <v-col cols="1">
                            <text-field v-model="workplace.streetNumber" disabled label="№" />
                        </v-col>
                        <v-col cols="6">
                            <text-field v-model="workplace.neighborhood" disabled label="ж.к." />
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="6">
                            <text-field v-model="workplace.profession" disabled label="Професия" />
                        </v-col>
                        <v-col cols="6">
                            <text-field v-model="workplace.position" disabled label="Длъжност" />
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-title>Диагноза</v-card-title>
                <v-card-text>
                    <v-row v-if="visitHasExam" :disabled="disableForEdit">
                        <v-col cols="12" md="6">
                            <icd-picker
                                v-if="useOtherIcdCode"
                                v-model="document.icdCode"
                                required
                                :disabled="disableForEdit"
                            />
                            <exam-diagnosis-picker
                                v-else
                                v-model="selectedExamDiagnosis"
                                required
                                use-main-icd
                                :disabled="disableForEdit"
                            />
                        </v-col>
                        <v-col cols="12" md="6" class="py-0">
                            <v-checkbox
                                v-model="useOtherIcdCode"
                                label="Искам да използвам друга диагноза"
                                :disabled="disableForEdit"
                            />
                        </v-col>
                    </v-row>
                    <icd-picker v-else v-model="document.icdCode" required :disabled="disableForEdit" />

                    <alert :value="isIcdCodeInvalid" type="error">
                        Системата на НОИ не приема МКБ кодове с повече от 2 знака след точката. Моля променете.
                    </alert>
                    <v-row>
                        <v-col cols="12">
                            <v-textarea
                                v-model="document.labTest"
                                rows="2"
                                class="required"
                                :rules="[$validator.required]"
                                label="Изследвания"
                                :disabled="disableForEdit"
                            />
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-title>Заключение на ЛКК</v-card-title>
                <v-card-text>
                    <v-row no-gutters>
                        <v-col cols="4" md="3">
                            <dropdown
                                v-model="document.conclusionCode"
                                :items="conclusions"
                                item-value="code"
                                label="Заключение"
                                required
                                :disabled="disableForEdit"
                            />
                        </v-col>
                        <v-col cols="4" md="3">
                            <text-field
                                v-model="document.sickSheetNumber"
                                label="Болничен лист №"
                                required
                                readonly
                                :disabled="disableForEdit"
                            />
                        </v-col>
                        <v-col cols="4" md="2">
                            <text-field
                                v-model="document.lkkDecisionSickSheetDays"
                                label="Брой дни"
                                required
                                type="number"
                                :disabled="disableForEdit"
                                @input="changeLkkDecisionSickSheetEndDate"
                            />
                        </v-col>
                        <v-col cols="4" md="2">
                            <date-picker
                                v-model="document.lkkDecisionSickSheetStartDate"
                                label="Начало"
                                required
                                :disabled="disableForEdit"
                            />
                        </v-col>
                        <v-col cols="4" md="2">
                            <date-picker
                                v-model="document.lkkDecisionSickSheetEndDate"
                                label="Край"
                                required
                                :disabled="disableForEdit"
                            />
                        </v-col>
                        <v-col cols="12">
                            <v-textarea
                                v-model="document.lkkMotive"
                                rows="2"
                                class="required"
                                :rules="[$validator.required]"
                                label="Мотиви"
                                :disabled="disableForEdit"
                            />
                        </v-col>
                        <v-col cols="6" md="3">
                            <text-field :value="`Решението е съставено в `" readonly :disabled="disableForEdit" />
                        </v-col>
                        <v-col cols="2" md="1">
                            <text-field
                                v-model="document.lkkDecisionCopies"
                                type="number"
                                required
                                :min="2"
                                label="брой ек."
                                :disabled="disableForEdit"
                            />
                        </v-col>
                        <v-col cols="4" md="2">
                            <text-field :value="` екземпляра.`" readonly :disabled="disableForEdit" />
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card-text>
        </v-card>
        <UnsavedChanges ref="leaveDialog" />
    </ExamLayout>
</template>

<script lang="ts">
    import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';

    import Dropdown from '@/component/Dropdown/Dropdown.vue';
    import ExamDiagnosisPicker from '@/component/Exam/ExamDiagnosisPicker.vue';
    import ExamLayout from '@/component/Exam/ExamLayout.vue';
    import CityPicker from '@/component/Picker/CityPicker.vue';
    import IcdPicker from '@/component/Picker/IcdPicker.vue';
    import PidTypePicker from '@/component/Picker/PidTypePicker.vue';
    import { LkkDecisionStatusCode } from '@/enum/Exam/LkkDecisionStatusCode';
    import { LkkDecisionDto } from '@/model/Exam/LkkDecision/LkkDecisionDto';
    import { LkkDecisionAppealerTypeDto } from '@/model/Nomenclature/LkkDecisionAppealerTypeDto';
    import { LkkDecisionAppealReasonDto } from '@/model/Nomenclature/LkkDecisionAppealReasonDto';
    import { LkkDecisionConclusionDto } from '@/model/Nomenclature/LkkDecisionConclusionDto';
    import { PatientWorkplaceDto } from '@/model/Patient/PatientWorkplaceDto';
    import { lkkDecisionService } from '@/service/Exam/LkkDecisionService';
    import { sickSheetService } from '@/service/Exam/SickSheetService';
    import { eventBus } from '@/service/Infrastructure/EventBus';
    import { lkkDecisionAppealerTypeService } from '@/service/Nomenclature/LkkDecisionAppealerTypeService';
    import { lkkDecisionAppealReasonService } from '@/service/Nomenclature/LkkDecisionAppealReasonService';
    import { lkkDecisionConclusionService } from '@/service/Nomenclature/LkkDecisionConclusionService';
    import { patientWorkplaceService } from '@/service/Patient/PatientWorkplaceService';
    import { printService } from '@/service/PrintService';
    import { currentPatientCache } from '@/store/CurrentPatientCache';
    import { currentVisitCache } from '@/store/CurrentVisitCache';
    import { userContextCache } from '@/store/User/UserContextCache';
    import { userSettingsState } from '@/store/User/UserSettingsState';
    import LkkDecisionCommision from '@/view/Exam/LkkDecision/LkkDecisionCommision.vue';
    import LkkDecisionUpload from '@/view/Exam/LkkDecision/LkkDecisionUpload.vue';
    import UnsavedChanges from '@/view/Exam/UnsavedChanges.vue';
    import PatientWorkplace from '@/view/Patient/PatientEdit/PatientWorkplaceView.vue';

    @Component({
        components: {
            LkkDecisionCommision,
            LkkDecisionUpload,
            UnsavedChanges,
            IcdPicker,
            ExamDiagnosisPicker,
            PatientWorkplace,
            CityPicker,
            PidTypePicker,
            Dropdown,
            ExamLayout
        },
        async beforeRouteLeave(to, from, next) {
            await this.$data.leaveDialogRef.check(this.$data.initialValue, this.$data.document, next);
        }
    })
    export default class LkkDecision extends Vue {
        @Ref()
        leaveDialog!: UnsavedChanges;

        @Prop()
        documentId!: number;

        @Prop()
        visitId!: number;

        initialValue: LkkDecisionDto = new LkkDecisionDto();
        document: LkkDecisionDto = new LkkDecisionDto();
        appealerTypeCodes: LkkDecisionAppealerTypeDto[] = [];
        appealReasons: LkkDecisionAppealReasonDto[] = [];
        conclusions: LkkDecisionConclusionDto[] = [];
        workplaceDialogIsVisible: boolean = false;
        workplace: PatientWorkplaceDto = new PatientWorkplaceDto();
        patientWorkplaces: PatientWorkplaceDto[] = [];
        isCloseClicked: boolean = false;
        useOtherIcdCode: boolean = false;
        leaveDialogRef: UnsavedChanges = new UnsavedChanges();

        get disableForEdit() {
            return this.document.statusCode !== LkkDecisionStatusCode.None;
        }

        get canSave() {
            return (
                this.document.statusCode === LkkDecisionStatusCode.None ||
                this.document.statusCode === LkkDecisionStatusCode.ActiveToBeDeleted
            );
        }

        get canEditDeleteReason() {
            return this.document.statusCode !== LkkDecisionStatusCode.Deleted;
        }

        get isIcdCodeInvalid() {
            const validIcdCodeLength = 5;
            return (this.document?.icdCode?.length ?? 0) > validIcdCodeLength;
        }

        get visitHasExam() {
            return Boolean(currentVisitCache.value.exam);
        }

        get currentPatient() {
            return currentPatientCache.value;
        }

        get isDeleted() {
            return (
                this.document?.statusCode === LkkDecisionStatusCode.Deleted ||
                this.document?.statusCode === LkkDecisionStatusCode.ActiveToBeDeleted
            );
        }

        get backgroundColor() {
            return !userSettingsState.userSettings.ui.hasReferralsBackground
                ? 'transparent'
                : userSettingsState.userSettings.ui.isDarkTheme
                  ? '#949463'
                  : '#e7e79d';
        }

        get selectedExamDiagnosis() {
            return currentVisitCache.value.exam?.examDiagnoses.find(
                (diagnosis) => diagnosis.icdCode === this.document.icdCode
            );
        }

        set selectedExamDiagnosis(examDiagnosis) {
            this.document.icdCode = examDiagnosis?.icdCode ?? '';
        }

        async getPatientWorkplaces() {
            this.patientWorkplaces = await patientWorkplaceService.getActivePatientWorkplaces(
                this.currentPatient.key.patientId
            );
        }

        workplacePosition(workplace: PatientWorkplaceDto) {
            return `${workplace.company} - ${workplace.position}`;
        }

        updateWorkplaces(workplaces: PatientWorkplaceDto[]) {
            this.patientWorkplaces = workplaces.filter((workplace) => !workplace.isDeleted);
            this.onChangeWork(this.document.patientWorkplaceId);
            this.workplaceDialogIsVisible = false;
        }

        closeDialog() {
            this.workplaceDialogIsVisible = false;
            this.isCloseClicked = true;
        }

        openWorkplaceDialog() {
            this.workplaceDialogIsVisible = true;
            this.isCloseClicked = false;
        }

        private copyObject() {
            this.initialValue = this.$lodash.cloneDeep(this.document);
        }

        async updateEventHandler() {
            this.$loading.show();
            try {
                if (!this.document.id) {
                    const newId = await lkkDecisionService.createLkkDecision(
                        userContextCache.currentDoctorEmployeeSeqNumber ?? 0,
                        this.document
                    );
                    const title = `Решение на ЛКК №${this.document.lkkDecisionNumber}`;
                    eventBus.$emit('create-referral-event', newId, 'LkkDecision', title);
                    this.copyObject();
                    this.$router.push(`/Exam/LkkDecision/Edit/${newId}`);
                } else {
                    await lkkDecisionService.updateLkkDecision(this.document);
                    eventBus.$emit('update-referral-event', 'Решение на ЛКК');
                    this.copyObject();
                }
            } finally {
                this.$loading.hide();
            }
        }

        async deleteEventHandler() {
            this.$loading.show();
            try {
                const documentId = this.document.id;
                const title = `Решение на ЛКК №${this.document.lkkDecisionNumber}`;
                const visitIsDeleted = await sickSheetService.deleteSickSheet(documentId);
                this.copyObject();
                this.$router.push(
                    visitIsDeleted
                        ? `/Patient/VisitSummary/${currentPatientCache.value.key.patientId}`
                        : `/Exam/LkkDecision/Create/${currentVisitCache.value.id}`
                );
                eventBus.$emit('delete-referral-event', documentId, 'LkkDecision', title);
            } finally {
                this.$loading.hide();
            }
        }

        printEventHandler() {
            if (this.documentId) {
                this.$router.push(`/Print/Exam.LkkDecision/${this.documentId}`);
            }
        }

        async printWithoutPreviewHandler() {
            this.$loading.show();
            try {
                const parameters: { [key: string]: string } = {};
                parameters.id = this.documentId.toString();
                const report = 'Exam.LkkDecision';
                await printService.printReport(report, parameters);
            } finally {
                this.$loading.hide();
            }
        }

        async loadAppealerTypes() {
            this.$loading.show();
            try {
                this.appealerTypeCodes = await lkkDecisionAppealerTypeService.getLkkDecisionAppealerTypes();
            } finally {
                this.$loading.hide();
            }
        }

        async loadAppealReasons() {
            this.$loading.show();
            try {
                this.appealReasons = await lkkDecisionAppealReasonService.getLkkDecisionAppealReasons();
            } finally {
                this.$loading.hide();
            }
        }

        async loadConclusions() {
            this.$loading.show();
            try {
                this.conclusions = await lkkDecisionConclusionService.getLkkDecisionConclusions();
            } finally {
                this.$loading.hide();
            }
        }

        @Watch('document.patientWorkplaceId')
        onChangeWork(val: number) {
            if (val && this.patientWorkplaces) {
                const [chosenWorkplace] = this.patientWorkplaces.filter((work) => work.id === val);
                if (chosenWorkplace) {
                    this.workplace = chosenWorkplace;
                }
            }
        }

        async loadDocumentById() {
            this.document = await lkkDecisionService.getLkkDecisionById(this.documentId);
        }

        async load() {
            this.$loading.show();
            try {
                await this.loadAppealerTypes();
                await this.loadAppealReasons();
                await this.loadConclusions();
                await this.getPatientWorkplaces();
                if (this.documentId) {
                    await this.loadDocumentById();
                    await currentVisitCache.load(this.document.visitId);
                } else if (this.visitId) {
                    this.document = await lkkDecisionService.getLkkDecisionInfoByVisit(this.visitId);
                } else {
                    const doctorEmployeeSeqNumber = userContextCache.currentDoctorEmployeeSeqNumber;
                    if (doctorEmployeeSeqNumber) {
                        this.document = await lkkDecisionService.getLkkDecisionInfoByPatientAndDoctor(
                            currentPatientCache.value.key.patientId,
                            doctorEmployeeSeqNumber
                        );
                    }
                }
            } finally {
                this.$loading.hide();
            }
        }

        changeSickSheetEndDate() {
            const defaultHour = 12;
            const days = this.document.sickSheetDays;
            if (days && this.document.sickSheetEndDate && this.document.sickSheetStartDate) {
                const date = new Date(this.document.sickSheetStartDate);
                date.setDate(date.getDate() + days - 1);
                date.setHours(defaultHour);
                this.document.sickSheetEndDate = date;
            }
        }

        changeLkkDecisionSickSheetEndDate() {
            const defaultHour = 12;
            const days = this.document.lkkDecisionSickSheetDays;
            if (days && this.document.lkkDecisionSickSheetEndDate && this.document.lkkDecisionSickSheetStartDate) {
                const date = new Date(this.document.lkkDecisionSickSheetStartDate);
                date.setDate(date.getDate() + days - 1);
                date.setHours(defaultHour);
                this.document.lkkDecisionSickSheetEndDate = date;
            }
        }

        statusChange(statusCode: string) {
            if (!this.isDeleted) {
                this.document.deleteReason = '';
            }
            this.document.statusCode = statusCode;
        }

        @Watch('document.sickSheetStartDate')
        onSickSheetStartDateChange() {
            this.changeSickSheetEndDate();
        }

        @Watch('document.lkkDecisionSickSheetStartDate')
        onLkkDecisionSickSheetStartDateChange() {
            this.changeLkkDecisionSickSheetEndDate();
        }

        async mounted() {
            this.leaveDialogRef = this.leaveDialog;
            await this.load();
            if (this.document.patientWorkplaceId) {
                this.onChangeWork(this.document.patientWorkplaceId);
            }
            if (!this.selectedExamDiagnosis) {
                this.useOtherIcdCode = true;
            }
            this.copyObject();
        }
    }
</script>

<style scoped>
    .v-card__title {
        padding: 2px;
    }
    .v-card__text {
        padding: 2px;
    }
</style>
