export class ProcessMemoryDto {
    public privateMb: number = 0;
    public workingMb: number = 0;
    public workingPeakMb: number = 0;
    public systemNonpagedKb: number = 0;
    public systemPagedMb: number = 0;
    public pagedMb: number = 0;
    public pagedPeakMb: number = 0;
    public virtualGb: number = 0;
    public virtualPeakDiffMb: number = 0;

    public get appMemoryText() {
        if (!this.workingMb) {
            return '';
        }
        let text = `${this.workingMb}${this.workingPeakMb !== this.workingMb ? ` (до ${this.workingPeakMb})` : ''} / ${this.privateMb}`;

        // PagedPeak не се поддържа под Linux (винаги е 0), а под Windows се показва вместо Private-максимум, защото Paged съвпада с Private.
        if (this.pagedPeakMb !== 0 && this.pagedPeakMb !== this.pagedMb) {
            text += ` (до ${this.pagedPeakMb})`;
        }

        // Paged съвпада с Private под Windows и със SystemPaged под Linux, но ако случайно е различно и от двете, се показва и то.
        if (this.pagedMb !== this.privateMb && this.pagedMb !== this.systemPagedMb) {
            text += ` / ${this.pagedMb}`;
        }
        text += ' MB';
        return text;
    }

    public get systemMemoryText() {
        return this.workingMb ? `${this.systemNonpagedKb} KB + ${this.systemPagedMb} MB` : null;
    }

    public get virtualMemoryText() {
        return this.workingMb
            ? `${this.virtualGb} GB${this.virtualPeakDiffMb ? ` (до +${this.virtualPeakDiffMb} MB)` : ''}`
            : null;
    }
}
