import { plainToInstance } from 'class-transformer';

import { WorkTimeVisitReasonCode } from '@/enum/WorkTime/WorkTimeVisitReasonCode';
import { PrevNextExamDto } from '@/model/Exam/ExamDuration/PrevNextExamDto';
import { PrevNextExamSearchCommand } from '@/model/Exam/ExamDuration/PrevNextExamSearchCommand';
import { ExamDurationSettingDto } from '@/model/Setting/ExamDuration/ExamDurationSettingDto';
import { httpService } from '@/service/Infrastructure/HttpService';
import { notifierService } from '@/service/Infrastructure/NotifierService';
import { userContextCache } from '@/store/User/UserContextCache';
import { userSettingsState } from '@/store/User/UserSettingsState';

const minuteAsMs = 60000;

// Кодовете в examDurationSettings всъщност са кодове на WorkTimeVisitReason-и - това е номенклатура за календара на лекаря.
// Някои от WorkTimeVisitReason-ите имат същите кодове като NhifVisitReason-ите.
// Така, по examDto.nhifVisitReasonCode се стига до настройка за продължителност със същия WorkTimeVisitReason.Code.
// Ако лекарят не е настроил продължителност за избрания WorkTimeVisitReason == НЗОК-reason,
// по подразбиране се ползва настройката за амбулаторен преглед, което е само WorkTimeVisitReason, няма такъв НЗОК-reason.
const getExamDurationSetting = (nhifVisitReasonCode: string) =>
    userSettingsState.userSettings.examDurationSettings.find((setting) => setting.code === nhifVisitReasonCode) ??
    userSettingsState.userSettings.examDurationSettings.find(
        (setting) => setting.code === WorkTimeVisitReasonCode.Ambulatory
    ) ??
    new ExamDurationSettingDto();

const formatMinutes = (minutes: number) => `${minutes} ${minutes === 1 ? 'минута' : 'минути'}`;

export const examDurationService = {
    async getPrevNextExamForDoctor(referenceDateTime: Date, currentExamId: number | null): Promise<PrevNextExamDto> {
        const { current } = userContextCache;
        if (!current) {
            throw Error('Не е избрана месторабота.');
        }
        const command = new PrevNextExamSearchCommand();
        command.practiceId = current.practiceId;
        command.doctorEmployeeSeqNumber = current.employeeSeqNumber;
        command.referenceDateTime = referenceDateTime;
        command.currentExamId = currentExamId;
        const response = await httpService.get<PrevNextExamDto>('ExamDuration/GetPrevNextExamForDoctor', {
            params: command
        });
        return plainToInstance(PrevNextExamDto, response.data);
    },

    async getPrevNextExamForPatient(
        referenceDateTime: Date,
        patientId: number,
        currentExamId: number | null
    ): Promise<PrevNextExamDto> {
        const { current } = userContextCache;
        if (!current) {
            throw Error('Не е избрана месторабота.');
        }
        const command = new PrevNextExamSearchCommand();
        command.practiceId = current.practiceId;
        command.doctorEmployeeSeqNumber = current.employeeSeqNumber;
        command.referenceDateTime = referenceDateTime;
        command.currentExamId = currentExamId;
        command.patientId = patientId;
        const response = await httpService.get<PrevNextExamDto>('ExamDuration/GetPrevNextExamForPatient', {
            params: command
        });
        return plainToInstance(PrevNextExamDto, response.data);
    },

    async getNextExamTimeForToday(): Promise<Date> {
        const { current } = userContextCache;
        if (!current) {
            throw Error('Не е избрана месторабота.');
        }
        const response = await httpService.get<Date>('ExamDuration/GetNextExamTimeForToday', {
            params: {
                practiceId: current.practiceId,
                doctorEmployeeSeqNumber: current.employeeSeqNumber
            }
        });
        return response.data;
    },

    timeOverlapCheck(
        prevVisitStartDateTime: Date,
        prevExamNhifVisitReasonCode: string,
        nextVisitStartDateTime: Date,
        examsDescription: string
    ) {
        if (nextVisitStartDateTime < prevVisitStartDateTime) {
            console.log('Неочаквано състояние: Датите на сравняваните прегледи са в обратен ред.');
            return;
        }

        const { examDurationInMinutes } = getExamDurationSetting(prevExamNhifVisitReasonCode);
        if (examDurationInMinutes) {
            const actualDurationInMinutes = Math.floor(
                (nextVisitStartDateTime.getTime() - prevVisitStartDateTime.getTime()) / minuteAsMs
            );
            if (actualDurationInMinutes < examDurationInMinutes) {
                notifierService.showWarning(
                    '',
                    `Времетo между ${examsDescription} преглед е само ${formatMinutes(
                        actualDurationInMinutes
                    )}, а трябва да бъде поне ${formatMinutes(examDurationInMinutes)}.`
                );
            }
        }
    }
};
