import axios from 'axios';

import { localServerHttpService } from '@/service/Infrastructure/LocalServerHttpService';
import { notifierService } from '@/service/Infrastructure/NotifierService';
import { localServerUpdateService } from '@/service/LocalServer/LocalServerUpdateService';

export const localServerDiagnosticService = {
    async checkIsLocalServerActive(): Promise<boolean> {
        let isActive = false;
        // Следното правило иска да се import-не { CancelToken }, вместо да се достъпва през default import-а.
        // В случая обаче { CancelToken } е interface, а axios.CancelToken е променлива и то от друг interface. Проблем на axios.
        // eslint-disable-next-line import/no-named-as-default-member
        const source = axios.CancelToken.source();
        const timeForResponse = 2000;
        const timeout = setTimeout(() => {
            source.cancel();
        }, timeForResponse);

        try {
            const config = localServerUpdateService.getConfig();
            const response = await localServerHttpService.get('/server/version', { cancelToken: source.token });
            if (response?.data) {
                clearTimeout(timeout);
                if (
                    config.requiredVersion.localeCompare(response.data, 'en', {
                        numeric: true,
                        sensitivity: 'base'
                    }) > 0
                ) {
                    notifierService.showWarning(
                        'Внимание',
                        `Версията на локалния сървър е ${response.data}, изисква се версия ${config.requiredVersion}!
                              Може да свалите от: <a href="${config.downloadUrl}">Линк</a>`
                    );
                }
                isActive = true;
            }
        } catch (error) {
            notifierService.showError('Локалният сървър е спрян или липсва', (error as Error).message);
        }

        return isActive;
    },

    async getSettings(): Promise<object> {
        const response = await localServerHttpService.get('/server/settings');
        const settings: Record<string, string> = {};
        for (const item of response.data) {
            settings[item.key] = item.value;
        }
        return settings;
    },

    async getCapability(): Promise<string[]> {
        const response = await localServerHttpService.get('/server/caps');
        return response.data;
    },

    async getEdition(): Promise<string> {
        const response = await localServerHttpService.get('/server/edition');
        return response.data;
    },

    async getVersion(): Promise<string> {
        const response = await localServerHttpService.get('/server/version');
        return response.data;
    }
};
