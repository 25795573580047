<template>
    <v-container fluid>
        <!-- В повечето случаи се изписва цялото лого+надпис "Хипократ". При размер xs излиза само лого "Х". -->
        <v-row>
            <v-col>
                <v-row>
                    <v-col class="hidden-xs-only">
                        <v-img
                            src="@/asset/Logo/Nadpis.png"
                            alt="Хипократ"
                            :max-width="advertisementImg ? '300px' : '650px'"
                        />
                    </v-col>
                    <v-col class="hidden-sm-and-up">
                        <img class="mx-auto" src="@/asset/Logo/Logo.png" alt="Хипократ" max-width="175px" />
                    </v-col>
                </v-row>
                <v-row v-if="advertisementImg" dense>
                    <h2>Здравно-информационна система за ОПЛ и специалисти в извънболнични лечебни заведения</h2>
                </v-row>
                <v-row v-else dense>
                    <h1>Здравно-информационна система за ОПЛ и специалисти в извънболнични лечебни заведения</h1>
                </v-row>
                <v-row v-if="advertisementImg" justify="center" align="center">
                    <v-col sm="6">
                        <v-img max-width="750px" :src="advertisementImg"></v-img>
                    </v-col>
                </v-row>
            </v-col>
            <v-divider v-if="currentUserContext" vertical></v-divider>
            <v-col v-if="currentUserContext" cols="2">
                <bar-chart :chart-data="chartData" :title="'Брой прегледи за последната година'" />
                <pie-chart
                    v-if="pieChartRef3Data && pieChartRef3Data.sumValue"
                    :chart-data="pieChartRef3MainData"
                    :title="`Регулативен стандарт - общ брой използвани бл.МЗ - НЗОК №3/3а (${pieChartRef3Data.sumValue})`"
                />
                <pie-chart
                    v-if="pieChartRef4Data && pieChartRef4Data.sumValue"
                    :chart-data="pieChartRef4MainData"
                    :title="`Регулативен стандарт - използвана обща сума бл.МЗ - НЗОК №4 (${pieChartRef4Data.sumValue}лв.)`"
                />
            </v-col>
        </v-row>
    </v-container>
</template>

<script lang="ts">
    import { Component, Vue, Watch } from 'vue-property-decorator';

    import BarChart from '@/component/Chart/BarChart.vue';
    import PieChart from '@/component/Chart/PieChart.vue';
    import { AdvertisementTypeCode } from '@/enum/AdvertisementTypeCode';
    import { ChartDataModel } from '@/model/Common/Chart/ChartDataModel';
    import { ChartDatasetModel } from '@/model/Common/Chart/ChartDatasetModel';
    import { PieChartDto } from '@/model/Dashboard/PieChartDto';
    import { BarChartExamDto } from '@/model/Exam/BarChartExamDto';
    import { advertisementService } from '@/service/AdvertisementService';
    import { dashboardService } from '@/service/Dashboard/DashboardService';
    import { userContextCache } from '@/store/User/UserContextCache';

    @Component({ components: { BarChart, PieChart } })
    export default class Home extends Vue {
        private chartData: ChartDataModel = new ChartDataModel();
        private barChartDataset: ChartDatasetModel = new ChartDatasetModel();
        private barChartData: BarChartExamDto = new BarChartExamDto();
        private pieChartRef3MainData: ChartDataModel = new ChartDataModel();
        private pieChartRef3Dataset: ChartDatasetModel = new ChartDatasetModel();
        private pieChartRef3Data: PieChartDto = new PieChartDto();
        private pieChartRef4MainData: ChartDataModel = new ChartDataModel();
        private pieChartRef4Dataset: ChartDatasetModel = new ChartDatasetModel();
        private pieChartRef4Data: PieChartDto = new PieChartDto();
        private advertisementImg: string | null = null;

        private get currentUserContext() {
            return userContextCache.current;
        }

        @Watch('currentUserContext', { immediate: true })
        private onCurrentUserContextChanged() {
            if (userContextCache.current) {
                this.loadBarChartData();
                this.loadPieChartData(true);
                this.loadPieChartData(false);
                this.loadHomeAdvertisement();
            }
        }

        private async getBarData() {
            if (userContextCache.current) {
                this.barChartData = (
                    await dashboardService.getBarChartExamData(userContextCache.current.practiceId)
                ).data;
                this.barChartDataset.data = this.barChartData.monthExamCounts;
                this.chartData.labels = Array.from({ length: 12 }, (element, index) =>
                    new Date(0, index + this.barChartData.startingMonth, 0).toLocaleDateString('bg-BG', {
                        month: 'long'
                    })
                );
            }
        }

        private loadBarChartData() {
            this.getBarData();
            this.barChartDataset.backgroundColor = [
                'rgba(255, 99, 132, 0.2)',
                'rgba(255, 159, 64, 0.2)',
                'rgba(255, 205, 86, 0.2)',
                'rgba(75, 192, 192, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(153, 102, 255, 0.2)',
                'rgba(201, 203, 207, 0.2)',
                'rgba(100, 68, 213, 0.2)',
                'rgba(151, 68, 213, 0.2)',
                'rgba(213, 68, 208, 0.2)',
                'rgba(139, 213, 68, 0.2)',
                'rgba(76, 213, 68, 0.2)'
            ];
            this.barChartDataset.borderColor = [
                'rgb(255, 99, 132)',
                'rgb(255, 159, 64)',
                'rgb(255, 205, 86)',
                'rgb(75, 192, 192)',
                'rgb(54, 162, 235)',
                'rgb(153, 102, 255)',
                'rgb(201, 203, 207)',
                'rgb(100, 68, 213)',
                'rgb(151, 68, 213)',
                'rgb(213, 68, 208)',
                'rgb(139, 213, 68)',
                'rgb(76, 213, 68)'
            ];
            this.barChartDataset.borderWidth = 1;
            this.chartData.datasets = [this.barChartDataset];
        }

        private loadPieChartData(isRef3PieLoaded: boolean) {
            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            this.getPieData(isRef3PieLoaded);
            const backgroundColors = ['rgb(255, 99, 132)', 'rgb(54, 162, 235)'];
            if (isRef3PieLoaded) {
                this.pieChartRef3Dataset.backgroundColor = backgroundColors;
                this.pieChartRef3MainData.datasets = [this.pieChartRef3Dataset];
            } else {
                this.pieChartRef4Dataset.backgroundColor = backgroundColors;
                this.pieChartRef4MainData.datasets = [this.pieChartRef4Dataset];
            }
        }

        private async getPieData(isRef3PieLoaded: boolean) {
            const { current } = userContextCache;
            if (current) {
                if (isRef3PieLoaded) {
                    this.pieChartRef3Data = (
                        await dashboardService.getReferral3PieChartData(current.practiceId, current.employeeSeqNumber)
                    ).data;
                    this.pieChartRef3Dataset.data = this.pieChartRef3Data.pieData;
                    this.pieChartRef3MainData.labels = this.pieChartRef3Data.labels;
                } else {
                    this.pieChartRef4Data = (
                        await dashboardService.getReferral4PieChartData(current.practiceId, current.employeeSeqNumber)
                    ).data;
                    this.pieChartRef4Dataset.data = this.pieChartRef4Data.pieData;
                    this.pieChartRef4MainData.labels = this.pieChartRef4Data.labels;
                }
            }
        }

        private async loadHomeAdvertisement() {
            const { current } = userContextCache;
            if (current) {
                const advertisements = await advertisementService.getValidAdvertisements({
                    practiceId: current.practiceId,
                    advertisementTypeCode: AdvertisementTypeCode.Home,
                    icdCode: null
                });
                const [homeScreenAdvertisement] = advertisements;

                if (homeScreenAdvertisement) {
                    this.advertisementImg = `data:image/png;base64,${homeScreenAdvertisement.fileContent}`;
                } else {
                    this.advertisementImg = null;
                }
            }
        }
    }
</script>
