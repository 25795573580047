import Vue from 'vue';

import { GenderDto } from '@/model/Nomenclature/GenderDto';
import { genderService } from '@/service/Nomenclature/GenderService';
import { ArrayCacheBase } from '@/store/Nomenclature/ArrayCacheBase';

class GenderCache extends ArrayCacheBase<GenderDto> {
    protected async load() {
        const response = await genderService.getGenders();
        this._items = response.data;
    }
}

export const genderCache = Vue.observable(new GenderCache());
