import { AxiosResponse } from 'axios';

import { NhisVaccineLotDto } from '@/model/Nhis/VaccineLot/NhisVaccineLotDto';
import { NhisVaccineLotRequestDto } from '@/model/Nhis/VaccineLot/NhisVaccineLotRequestDto';
import { NhisVaccineLotResultDto } from '@/model/Nhis/VaccineLot/NhisVaccineLotResultDto';
import { httpService } from '@/service/Infrastructure/HttpService';
import { nhisKey } from '@/store/NhisNhifAccessTokenCache';
import { userContextCache } from '@/store/User/UserContextCache';

export const nhisVaccineLotService = {
    getActiveNhisVaccineLots(
        nhisVaccineCode: string,
        examId: number | null
    ): Promise<AxiosResponse<NhisVaccineLotDto[]>> {
        const params = new NhisVaccineLotRequestDto();
        params.practiceId = userContextCache.currentPracticeId ?? 0;
        params.doctorEmployeeSeqNumber = userContextCache.currentDoctorEmployeeSeqNumber ?? 0;
        params.nhisVaccineCode = nhisVaccineCode;
        return httpService.get('NhisVaccineLot/GetActiveNhisVaccineLots', {
            params: { ...params, examId }
        });
    },

    getNhisVaccineLots(
        practiceId: number,
        doctorEmployeeSeqNumber: number,
        nhisVaccineCode: string
    ): Promise<AxiosResponse<NhisVaccineLotDto[]>> {
        const params = new NhisVaccineLotRequestDto();
        params.practiceId = practiceId;
        params.doctorEmployeeSeqNumber = doctorEmployeeSeqNumber;
        params.nhisVaccineCode = nhisVaccineCode;
        return httpService.get('NhisVaccineLot/GetNhisVaccineLots', { params });
    },

    createOrUpdateVaccineLot(data: NhisVaccineLotDto): Promise<AxiosResponse<void>> {
        return httpService.post('NhisVaccineLot/CreateOrUpdateVaccineLot', data);
    },

    isVaccineLotInUse(data: NhisVaccineLotDto): Promise<AxiosResponse<boolean>> {
        return httpService.post('NhisVaccineLot/IsVaccineLotInUse', data);
    },

    deleteVaccineLot(data: NhisVaccineLotDto): Promise<AxiosResponse<void>> {
        return httpService.delete('NhisVaccineLot/DeleteVaccineLot', { data });
    },

    async getCovidBatches(practiceId: number, doctorEmployeeSeqNumber: number): Promise<NhisVaccineLotResultDto> {
        const response = await httpService.get<NhisVaccineLotResultDto>(`/${nhisKey}/GetVaccineLots`, {
            params: {
                practiceId,
                doctorEmployeeSeqNumber
            }
        });

        return response?.data;
    }
};
