import Vue from 'vue';

import { IcdDto } from '@/model/Nomenclature/IcdDto';
import { icd10Service } from '@/service/Nomenclature/Icd10Service';
import { ArrayCacheBase } from '@/store/Nomenclature/ArrayCacheBase';

class Icd10Cache extends ArrayCacheBase<IcdDto> {
    protected async load() {
        const response = await icd10Service.getAllIcd10Areas();
        this._items = response.data;
    }
}

export const icd10Cache = Vue.observable(new Icd10Cache());
