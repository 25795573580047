import { AxiosResponse } from 'axios';

import { IcdDto } from '@/model/Nomenclature/IcdDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const icd10Service = {
    getAllIcd10Areas(): Promise<AxiosResponse<IcdDto[]>> {
        return httpService.get('/Icd/GetAllIcd10Areas');
    },

    async getIcd10SubAreasByAreaRange(areaRange: string): Promise<IcdDto[]> {
        const response = await httpService.get<IcdDto[]>(`/Icd/GetIcd10SubAreasByAreaRange?areaRange=${areaRange}`);
        return response.data;
    },

    async getIcd10sBySubAreaRange(subAreaRange: string): Promise<IcdDto[]> {
        const response = await httpService.get<IcdDto[]>(`/Icd/GetIcd10sBySubAreaRange?subAreaRange=${subAreaRange}`);
        return response.data;
    }
};
