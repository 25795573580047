<template>
    <v-row>
        <v-col cols="12" md="6">
            <dropdown
                v-model="value.resultEnumCode"
                :items="getPossibleResultsByProcedure"
                item-value="nhisCode"
                required
                label="Резултат тип"
            />
        </v-col>
        <v-col
            v-if="
                value.resultEnumCode && resultAnswerEnum && resultAnswerEnum.answerTypeCode == ExamAnswerTypeCode.Bool
            "
            md="3"
            cols="6"
        >
            <v-checkbox v-model="value.resultBoolean" dense :label="`Резултат процедура ${position}`" />
        </v-col>
        <v-col
            v-else-if="
                value.resultEnumCode && resultAnswerEnum && resultAnswerEnum.answerTypeCode == ExamAnswerTypeCode.Number
            "
            md="3"
            cols="6"
        >
            <text-field
                v-model="value.resultQuantity"
                type="number"
                :label="`Резултат процедура ${position}`"
                required
            />
        </v-col>
        <v-col
            v-else-if="
                value.resultEnumCode && resultAnswerEnum && resultAnswerEnum.answerTypeCode == ExamAnswerTypeCode.Text
            "
            md="3"
            cols="6"
        >
            <v-textarea
                v-model="value.resultText"
                dense
                :label="`Резултат процедура ${position}`"
                rows="2"
                class="required"
                :rules="[$validator.required]"
            />
        </v-col>
        <v-col
            v-else-if="
                value.resultEnumCode && resultAnswerEnum && resultAnswerEnum.answerTypeCode == ExamAnswerTypeCode.Date
            "
            md="3"
            cols="6"
        >
            <date-picker v-model="value.resultDate" :label="`Резултат процедура ${position}`" required />
        </v-col>
        <v-col v-else md="3" cols="6">
            <dropdown
                v-if="value.resultEnumCode && resultAnswerEnum"
                v-model="value.resultCode"
                :items="getAnswersByType(resultAnswerEnum.answerTypeCode)"
                item-value="code"
                required
            />
        </v-col>
    </v-row>
</template>

<script lang="ts">
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

    import TextField from '@/component/Common/TextField.vue';
    import DatePicker from '@/component/Date/DatePicker.vue';
    import { ExamAnswerTypeCode } from '@/enum/Exam/ExamAnswerTypeCode';
    import { NhisMedicalProcedureResultDto } from '@/model/Nhis/Exam/PreventiveActivities/NhisMedicalProcedureResultDto';
    import { examAnswerEnumCache } from '@/store/Nomenclature/ExamAnswerEnumCache';
    import { medicalProcedureAnswerEnumCache } from '@/store/Nomenclature/MedicalProcedureAnswerEnumCache';

    @Component({
        computed: {
            ExamAnswerTypeCode() {
                return ExamAnswerTypeCode;
            }
        },
        components: { DatePicker, TextField }
    })
    export default class NhisExamMedicalProcedureResult extends Vue {
        @Prop()
        value!: NhisMedicalProcedureResultDto;

        @Prop()
        procedureNhisCode!: string;

        @Prop()
        position!: number;

        get getPossibleResultsByProcedure() {
            return medicalProcedureAnswerEnumCache.items.filter(
                (answer) => answer.medicalProcedureNhisCode === this.procedureNhisCode
            );
        }

        @Watch('getPossibleResultsByProcedure')
        onPossibleResultsChange() {
            if (!this.value.resultEnumCode) {
                this.value.resultEnumCode =
                    this.getPossibleResultsByProcedure.length === 1
                        ? this.getPossibleResultsByProcedure[0].nhisCode
                        : null;
            }
        }

        getAnswersByType(typeCode: string) {
            return examAnswerEnumCache.items.filter((answer) => answer.examAnswerTypeCode === typeCode);
        }

        get resultAnswerEnum() {
            return this.getPossibleResultsByProcedure.find(
                (procedure) => procedure.nhisCode === this.value.resultEnumCode
            );
        }
    }
</script>

<style scoped></style>
