<template>
    <div>
        <v-card-title>ЛКК Комисия</v-card-title>
        <v-card-text>
            <v-row no-gutters>
                <v-col cols="12" class="mb-2">
                    <dropdown
                        :items="lkkCommittees"
                        item-value="seqNumber"
                        label="ЛКК комисия"
                        :disabled="disableForEdit"
                        @input="fillLkkCommission"
                    />
                </v-col>
                <v-col cols="6" md="4">
                    <text-field v-model="isSpecializedCommission" label="Вид ЛКК" required disabled />
                </v-col>
                <v-col cols="6" md="8">
                    <text-field v-model="value.lkkCommissionName" label="Име на комисия" required disabled />
                </v-col>
                <v-col cols="6" md="4">
                    <text-field v-model="value.familyNameDoctor1" label="Председател" required disabled />
                </v-col>
                <v-col cols="6" md="4">
                    <text-field v-model="value.specialtyNameDoctor1" label="Специалност" required disabled />
                </v-col>
                <v-col cols="6" md="4">
                    <text-field v-model="value.uin1" label="УИН" required disabled />
                </v-col>
                <v-col cols="6" md="4">
                    <text-field v-model="value.familyNameDoctor2" label="Член 1" required disabled />
                </v-col>
                <v-col cols="6" md="4">
                    <text-field v-model="value.specialtyNameDoctor2" label="Специалност" required disabled />
                </v-col>
                <v-col cols="6" md="4">
                    <text-field v-model="value.uin2" label="УИН" required disabled />
                </v-col>
                <v-col cols="6" md="4">
                    <text-field v-model="value.familyNameDoctor3" label="Член 2" disabled />
                </v-col>
                <v-col cols="6" md="4">
                    <text-field v-model="value.specialtyNameDoctor3" label="Специалност" disabled />
                </v-col>
                <v-col cols="6" md="4">
                    <text-field v-model="value.uin3" label="УИН" disabled />
                </v-col>
                <v-col cols="6" md="4">
                    <text-field v-model="value.familyNameDoctor4" label="Член 3" disabled />
                </v-col>
                <v-col cols="6" md="4">
                    <text-field v-model="value.specialtyNameDoctor4" label="Специалност" disabled />
                </v-col>
                <v-col cols="6" md="4">
                    <text-field v-model="value.uin4" label="УИН" disabled />
                </v-col>
                <v-col cols="6" md="4">
                    <text-field v-model="value.familyNameDoctor5" label="Член 4" disabled />
                </v-col>
                <v-col cols="6" md="4">
                    <text-field v-model="value.specialtyNameDoctor5" label="Специалност" disabled />
                </v-col>
                <v-col cols="6" md="4">
                    <text-field v-model="value.uin5" label="УИН" disabled />
                </v-col>
            </v-row>
        </v-card-text>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import Dropdown from '@/component/Dropdown/Dropdown.vue';
    import { LkkDecisionDto } from '@/model/Exam/LkkDecision/LkkDecisionDto';
    import { LkkCommitteeDoctorEmployeeDto } from '@/model/Practice/LkkCommittee/LkkCommitteeDoctorEmployeeDto';
    import { LkkCommitteeDropdownDto } from '@/model/Practice/LkkCommittee/LkkCommitteeDropdownDto';
    import { lkkCommitteeService } from '@/service/Practice/LkkCommittee/LkkCommitteeService';
    import { userContextCache } from '@/store/User/UserContextCache';

    @Component({
        components: { Dropdown }
    })
    export default class LkkDecisionCommision extends Vue {
        @Prop()
        value!: LkkDecisionDto;

        @Prop({ default: false })
        disableForEdit!: boolean;

        lkkCommittees: LkkCommitteeDropdownDto[] = [];

        get isSpecializedCommission() {
            return this.value?.IsSpecializedCommission ? 'Специализирана' : 'Обща';
        }

        async fillLkkCommission(seqNumber: number | null) {
            this.$loading.show();
            try {
                if (!seqNumber) {
                    return;
                }
                const result = await lkkCommitteeService.getLkkCommittee(
                    userContextCache.currentPracticeId ?? 0,
                    seqNumber
                );
                if (result) {
                    this.value.IsSpecializedCommission = result.isSpecialized;
                    this.value.lkkCommissionName = result.name;

                    const members = result.lkkCommitteeDoctorEmployees;
                    this.value.familyNameDoctor1 = members[0].doctorName;
                    this.value.uin1 = members[0].doctorUin;
                    this.value.specialtyNameDoctor1 = members[0].doctorSpecialtyName;
                    this.fillCommissionMembers(members);
                }
            } finally {
                this.$loading.hide();
            }
        }

        fillCommissionMembers(members: LkkCommitteeDoctorEmployeeDto[]) {
            /* eslint-disable @typescript-eslint/no-magic-numbers */
            if (members.length >= 2) {
                this.value.familyNameDoctor2 = members[1].doctorName;
                this.value.uin2 = members[1].doctorUin;
                this.value.specialtyNameDoctor2 = members[1].doctorSpecialtyName;
            }
            if (members.length >= 3) {
                this.value.familyNameDoctor3 = members[2].doctorName;
                this.value.uin3 = members[2].doctorUin;
                this.value.specialtyNameDoctor3 = members[2].doctorSpecialtyName;
            }
            if (members.length >= 4) {
                this.value.familyNameDoctor4 = members[3].doctorName;
                this.value.uin4 = members[3].doctorUin;
                this.value.specialtyNameDoctor4 = members[3].doctorSpecialtyName;
            }
            if (members.length >= 5) {
                this.value.familyNameDoctor5 = members[4].doctorName;
                this.value.uin5 = members[4].doctorUin;
                this.value.specialtyNameDoctor5 = members[4].doctorSpecialtyName;
            }
            /* eslint-enable @typescript-eslint/no-magic-numbers */
        }

        async loadCommissions() {
            this.$loading.show();
            try {
                this.lkkCommittees = await lkkCommitteeService.getLkkCommitteesDropdowns(
                    userContextCache.currentPracticeId ?? 0,
                    userContextCache.currentDoctorEmployeeSeqNumber ?? 0
                );
            } finally {
                this.$loading.hide();
            }
        }

        async mounted() {
            await this.loadCommissions();
        }
    }
</script>

<style scoped></style>
