export class PrescriptionNhifV2ViewDto {
    public id: number = 0;
    public issueDate: Date | null = null;
    public protocolEndDate: Date | null = null;
    public number: string = '';
    public segment: string = '';
    public diagnoses: string[] = [];
    public drugCodes: string[] = [];
    public nrn: string | null = null;
    public status: string | null = null;
    public statusCode: string | null = null;
    public statusTime: string | null = null;
    public executionDate: string | null = null;
}
