import { plainToInstance } from 'class-transformer';

import { PatientImportFileCommand } from '@/model/File/PatientImportFileCommand';
import { InsuranceStatusDto } from '@/model/Patient/InsuranceStatus/InsuranceStatusDto';
import { PatientExistenceDto } from '@/model/Patient/PatientExistenceDto';
import { PatientSearchCommand } from '@/model/Patient/PatientSearchCommand';
import { PatientSearchResultDto } from '@/model/Patient/PatientSearchResultDto';
import { PatientVersionDropdownDto } from '@/model/Patient/PatientVersionDropdownDto';
import { PatientVersionDto } from '@/model/Patient/PatientVersionDto';
import { PatientVersionKey } from '@/model/Patient/PatientVersionKey';
import { httpService } from '@/service/Infrastructure/HttpService';

export const patientService = {
    async search(data: PatientSearchCommand): Promise<PatientSearchResultDto[]> {
        const response = await httpService.post<PatientSearchResultDto[]>('/Patient/SearchPatient', data);
        return plainToInstance(PatientSearchResultDto, response.data);
    },

    exportPatient(filter: PatientSearchCommand): Promise<void> {
        return httpService.download('/Patient/ExportPatient', { params: filter });
    },

    async getSpecificPatientVersion(key: PatientVersionKey): Promise<PatientVersionDto | null> {
        const response = await httpService.get<PatientVersionDto>('/Patient/GetSpecificPatientVersion', {
            params: key
        });
        return response.data ? plainToInstance(PatientVersionDto, response.data) : null;
    },

    async getLastPatientVersion(
        patientId: number,
        gpEmployeeSeqNumber: number | null
    ): Promise<PatientVersionDto | null> {
        const response = await httpService.get<PatientVersionDto>('/Patient/GetLastPatientVersion', {
            params: { patientId, gpEmployeeSeqNumber }
        });
        return response.data ? plainToInstance(PatientVersionDto, response.data) : null;
    },

    async createPatient(data: PatientVersionDto): Promise<number> {
        const response = await httpService.post<number>('/Patient/CreatePatient', data);
        return response.data;
    },

    async updatePatient(data: PatientVersionDto): Promise<number> {
        const response = await httpService.post<number>('/Patient/UpdatePatient', data);
        return response.data;
    },

    async getLastPatientInsuranceStatus(patientId: number): Promise<InsuranceStatusDto> {
        const response = await httpService.get<InsuranceStatusDto>(`/Patient/GetPatientLastInsuranceStatus`, {
            params: { patientId }
        });
        return plainToInstance(InsuranceStatusDto, response.data);
    },

    async getPatientVersions(patientId: number): Promise<PatientVersionDropdownDto[]> {
        const response = await httpService.get<PatientVersionDropdownDto[]>(`/Patient/GetPatientVersions`, {
            params: { patientId }
        });
        return plainToInstance(PatientVersionDropdownDto, response.data) as PatientVersionDropdownDto[];
    },

    async doesPatientExist(dto: PatientExistenceDto): Promise<number | null> {
        const response = await httpService.post<number | null>('/Patient/DoesPatientExist', dto);
        return response.data;
    },

    async savePatientNotes(data: PatientVersionDto): Promise<void> {
        await httpService.post<void>('/Patient/SavePatientNotes/', data);
    },

    async transferPatientFromOtherDoctor(dto: PatientVersionDto): Promise<number> {
        const response = await httpService.post<number>('/Patient/TransferPatientFromOtherDoctor', dto);
        return response.data;
    },

    async importPatients(data: PatientImportFileCommand): Promise<void> {
        await httpService.post<void>('/Patient/ImportPatients', data);
    }
};
