import { render, staticRenderFns } from "./PatientSearch.vue?vue&type=template&id=1661eacb&scoped=true"
import script from "./PatientSearch.vue?vue&type=script&lang=ts"
export * from "./PatientSearch.vue?vue&type=script&lang=ts"
import style0 from "./PatientSearch.vue?vue&type=style&index=0&id=1661eacb&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1661eacb",
  null
  
)

export default component.exports