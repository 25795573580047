<template>
    <div>
        <v-form ref="form" v-model="isFormValid">
            <v-card v-if="securityCodeHasConfirmed">
                <v-card-title>Финансов отчет по чл. 37 - платежни документи</v-card-title>
                <v-card-text>
                    <report-date-picker v-model="filter" @input="getTableItems" />
                    <v-row>
                        <v-col cols="12" lg="6">
                            <practice-nhif-contract-picker v-model="nhifContract" :practice-id="practiceId" />
                        </v-col>
                        <v-col cols="12" lg="6">
                            <v-checkbox
                                v-model="filter.reportByPractice"
                                dense
                                label="Генериране на отчет за практика"
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col v-if="canGenerate" cols="12" md="4" xl="3">
                            <btn
                                action="XmlReport"
                                block
                                :disabled="isGenerating"
                                @click="generateFiscalReceiptReport"
                            />
                        </v-col>
                        <v-col v-if="hasResult" cols="12" md="4" xl="2">
                            <base64-to-file
                                v-model="response.report"
                                btn-name="отчет"
                                show-open-btn
                                file-name="FiscalReceiptReport"
                            ></base64-to-file>
                        </v-col>
                        <v-col v-if="hasResult" cols="12" md="4" xl="2">
                            <btn action="List" block :disabled="isGenerating" @click="generateFiscalReceiptReportLines">
                                Опис
                            </btn>
                        </v-col>
                        <v-col v-if="hasResult" cols="12" md="4" xl="3">
                            <btn action="Send" block :disabled="isGenerating" @click="sendFiscalReceiptReport">
                                Изпращане на отчет
                            </btn>
                        </v-col>
                        <v-col v-if="hasResult" cols="12" md="4" xl="2">
                            <btn action="Export" block :disabled="isGenerating" @click="exportFiscalReceiptReport">
                                Експорт
                            </btn>
                        </v-col>
                    </v-row>
                    <v-row v-if="hasResult">
                        <p class="font-weight-bold">Брой бележки в отчета: {{ response.itemsCount }}</p>
                    </v-row>
                    <div v-if="items.length > 0" class="mt-5">
                        <pis-file v-model="items"></pis-file>
                    </div>
                    <v-dialog v-model="reportLinesDialogIsVisible">
                        <FiscalReceiptReportLines
                            :items="fiscalReceiptReport"
                            @close-dialog="reportLinesDialogIsVisible = false"
                        />
                    </v-dialog>
                </v-card-text>
                <Dialog
                    v-model="resultDialogIsVisible"
                    :text="dialogText"
                    color="primary"
                    :title="'Резултат от изпращане'"
                ></Dialog>
            </v-card>
            <FinancialStatementsProtection
                v-else
                v-model="securityCodeHasConfirmed"
                :doctor-employee-seq-number="doctorEmployeeSeqNumber"
                :practice-id="practiceId"
            />
        </v-form>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import ReportDatePicker from '@/component/Date/ReportDatePicker.vue';
    import Base64ToFile from '@/component/File/Base64ToFile.vue';
    import FinancialStatementsProtection from '@/component/PinCode/PinCode.vue';
    import { PisUploadType } from '@/enum/LocalServer/PisUploadType';
    import { SpecialtyCode } from '@/enum/Nomenclature/SpecialtyCode';
    import { PisFileTypeCode } from '@/enum/Report/Pis/PisFileTypeCode';
    import { PracticeNhifContractDto } from '@/model/Practice/PracticeNhifContractDto';
    import { FiscalReceiptReportDto } from '@/model/Report/Pis/FiscalReceipt/FiscalReceiptReportDto';
    import { PisFileCreateDto } from '@/model/Report/Pis/PisFile/PisFileCreateDto';
    import { PisFileFilterDto } from '@/model/Report/Pis/PisFile/PisFileFilterDto';
    import { PisFileRowDto } from '@/model/Report/Pis/PisFile/PisFileRowDto';
    import { PisFileUploadRequestDto } from '@/model/Report/Pis/PisFileUploadRequestDto';
    import { ReportResponseDto } from '@/model/Report/Pis/ReportResponseDto';
    import { ReportIntervalFilterDto } from '@/model/Report/ReportIntervalFilterDto';
    import { localServerDiagnosticService } from '@/service/LocalServer/LocalServerDiagnosticService';
    import { practiceService } from '@/service/Practice/PracticeService';
    import { localServerPisService } from '@/service/Report/LocalServerPisService';
    import { pisFileService } from '@/service/Report/PisFileService';
    import { pisService } from '@/service/Report/PisService';
    import { userContextCache } from '@/store/User/UserContextCache';
    import { dateUtil } from '@/util/DateUtil';
    import FiscalReceiptReportLines from '@/view/Report/Pis/FiscalReceiptReportLines.vue';

    import PisFile from './PisFile.vue';
    import PracticeNhifContractPicker from './PracticeNhifContractPicker.vue';

    @Component({
        components: {
            ReportDatePicker,
            PracticeNhifContractPicker,
            PisFile,
            Base64ToFile,
            FiscalReceiptReportLines,
            FinancialStatementsProtection
        }
    })
    export default class FiscalReceiptReport extends Vue {
        @Prop({ required: true })
        private practiceId!: number;

        @Prop({ required: true })
        private doctorEmployeeSeqNumber!: number;

        private isFormValid: boolean = false;
        private filter: ReportIntervalFilterDto = new ReportIntervalFilterDto();
        private isLocalServerActive: boolean = false;
        private dialogText: string = '';
        private items: PisFileRowDto[] = [];
        private nhifContract: PracticeNhifContractDto = new PracticeNhifContractDto();
        private canGenerate: boolean = false;
        private response: ReportResponseDto = new ReportResponseDto();
        private fiscalReceiptReport: FiscalReceiptReportDto = new FiscalReceiptReportDto();
        private isGenerating: boolean = false;
        private reportLinesDialogIsVisible: boolean = false;
        private resultDialogIsVisible: boolean = false;
        private managerIdentifier: string | null = null;
        private securityCodeHasConfirmed: boolean = false;

        private get hasResult() {
            return this.response?.report?.length > 0;
        }

        private async mounted() {
            this.filter.fillPreviousMonth();
            this.filter.reportByPractice = true;
            await this.getManagerIdentifier();
            await this.getTableItems();
            await this.isFilledPracticeAndDoctor();
        }

        private async getManagerIdentifier() {
            this.managerIdentifier = await practiceService.getPracticeManagerIdentifier(this.practiceId);
        }

        private async generateFiscalReceiptReportLines() {
            try {
                this.$loading.show();
                this.fillDoctorEmployee();
                const response = await pisService.generateFiscalReceiptReportLines(this.filter);
                if (response) {
                    this.reportLinesDialogIsVisible = true;
                    this.fiscalReceiptReport = response;
                }
            } finally {
                this.$loading.hide();
            }
        }

        private async generateFiscalReceiptReport() {
            this.$loading.show();
            try {
                this.isGenerating = true;
                this.response = new ReportResponseDto();
                this.fillDoctorEmployee();
                const response = await pisService.generateFiscalReceiptReport(this.filter);
                if (response?.data?.report) {
                    this.response.report = response.data.report;
                    this.response.itemsCount = response.data.itemsCount;
                }
            } finally {
                this.isGenerating = false;
                this.$loading.hide();
            }
        }

        private fillDoctorEmployee() {
            this.filter.practiceId = this.practiceId;
            this.filter.doctorEmployeeSeqNumber = this.doctorEmployeeSeqNumber;
        }

        private async checkServerStatus(): Promise<void> {
            const response = await localServerDiagnosticService.checkIsLocalServerActive();
            this.isLocalServerActive = response;
        }

        private async exportFiscalReceiptReport() {
            this.$loading.show();
            try {
                await pisService.exportFiscalReceiptReport(this.filter);
            } finally {
                this.$loading.hide();
            }
        }

        private async sendFiscalReceiptReport() {
            await this.checkServerStatus();
            if (this.isLocalServerActive) {
                this.$loading.show();
                console.log(this.response.report);
                const uploadRequest = new PisFileUploadRequestDto(
                    this.response.report,
                    userContextCache.current?.practiceNumber ?? '',
                    this.managerIdentifier?.toString() ?? ''
                );
                this.fillRequestData(uploadRequest);
                uploadRequest.fileName = this.createFileName();
                const response = await localServerPisService.uploadReport(
                    PisFileTypeCode.FiscalReceiptReport,
                    uploadRequest,
                    PisUploadType.FinancialReport
                );
                if (!response.isError) {
                    this.dialogText = `${response.message} Идентификаторът на документа е: ${response.additionalInformation}`;
                    this.resultDialogIsVisible = true;
                    await this.savePisFile(response.additionalInformation);
                }

                this.$loading.hide();
            }
        }

        private fillRequestData(requestData: PisFileUploadRequestDto) {
            requestData.startDate = dateUtil.formatXmlDate(this.filter.startDate);
            requestData.endDate = dateUtil.formatXmlDate(this.filter.endDate);
            // RequestData.uin = userContextCache.current?.doctorUin ?? '';
            requestData.docDate = dateUtil.formatXmlDate(this.filter.endDate);
            // RequestData.specCode = userContextCache.current?.specialtyNhifCode ?? '';
            requestData.contractNumber = this.nhifContract.contractNumber;
            requestData.contractDate = dateUtil.formatXmlDate(this.nhifContract.contractDate);
            requestData.contractType = parseInt(this.nhifContract.nhifContractTypeCode, 10);
        }

        private createFileName(): string {
            const typeLZ = userContextCache.current?.specialtyCode === SpecialtyCode.Gp ? 'PIMP' : 'SIMP';
            const fiscalReceiptDate = dateUtil.formatFiscalReceiptReportDate(this.filter.startDate);
            return `${typeLZ}_${userContextCache.current?.practiceNumber}_${fiscalReceiptDate}`;
        }

        private async savePisFile(fileId: string) {
            const file = new PisFileCreateDto();
            file.practiceId = this.practiceId;
            file.doctorEmployeeSeqNumber = this.doctorEmployeeSeqNumber;
            file.pisFileTypeCode = PisFileTypeCode.FiscalReceiptReport;
            file.pisFileId = fileId;
            file.startDate = this.filter.startDate;
            file.endDate = this.filter.endDate;

            const item = await pisFileService.createPisFile(file);
            this.items.push(item);
            this.$loading.hide();
        }

        private async getTableItems() {
            const filter = new PisFileFilterDto();
            filter.practiceId = this.practiceId;
            filter.doctorEmployeeSeqNumber = this.doctorEmployeeSeqNumber;
            filter.pisFileTypeCode = PisFileTypeCode.FiscalReceiptReport;
            filter.startDate = this.filter.startDate;
            filter.endDate = this.filter.endDate;
            if (filter.startDate && filter.endDate) {
                this.items = await pisFileService.getPisFiles(filter);
            }
        }

        private async isFilledPracticeAndDoctor() {
            const response = await pisService.isFilledPracticeAndDoctor(this.practiceId, this.doctorEmployeeSeqNumber);

            if (response?.data.length > 0) {
                this.canGenerate = false;
                this.$notifier.showWarning('Внимание', response.data);
            } else {
                this.canGenerate = true;
            }
        }
    }
</script>
