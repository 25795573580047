<template>
    <v-row>
        <v-col ref="drawerContainer" cols="0" :md="showSecondaryMenu ? 2 : 0">
            <!-- fixed скролира менюто отделно от основния content, но усложнява позиционирането на менюто. -->
            <v-navigation-drawer
                v-model="showSecondaryMenu"
                fixed
                :width="smAndDownResolution ? null : `${drawerWidth}px`"
                :style="smAndDownResolution ? '' : { 'z-index': 3, left: `${drawerLeftOffset}px` }"
            >
                <v-list
                    dense
                    style="padding-bottom: 50px; padding-top: 70px"
                    :style="mainMenuIsVisible ? 'margin-left: 50px' : null"
                >
                    <v-list-item
                        v-for="(item, index) in items"
                        :key="index"
                        :to="item.link"
                        link
                        :class="item.documentsCount > 0 ? 'success2' : ''"
                    >
                        <!-- mr-3 намалява разстоянието между иконката и текста. -->
                        <v-list-item-icon class="mr-3">
                            <v-icon v-if="item.title === 'Посещения'" color="accent">
                                {{ 'mdi-stethoscope' }}
                            </v-icon>
                            <v-icon v-else-if="item.title === 'Амбулаторен лист'" :color="iconColor">
                                {{ 'mdi-home-outline' }}
                            </v-icon>
                            <v-icon v-else-if="!item.name != 'Амбулаторен лист'" :color="iconColor">
                                {{ 'mdi-folder-outline' }}
                            </v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>
                                <!-- v-list dense сгърсява елементите, но намалява и шрифта -->
                                <div class="text-subtitle-1">{{ item.title }}</div>
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-navigation-drawer>
        </v-col>
        <v-col cols="12" :md="showSecondaryMenu ? 10 : 12">
            <v-container fluid>
                <loading-animation></loading-animation>
                <router-view v-if="startLoadingDocument" :key="$route.fullPath" name="exam" />
            </v-container>
        </v-col>
    </v-row>
</template>

<script lang="ts">
    import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';

    import LoadingAnimation from '@/component/Common/LoadingAnimation.vue';
    import { createNoExamDocumentTypes } from '@/model/Menu/ExamDocumentMenuItems';
    import { ExamMenuItem } from '@/model/Menu/ExamMenuItem';
    import { examDocumentMenuService } from '@/service/Exam/ExamDocumentMenuService';
    import { visitMenuService } from '@/service/Exam/VisitMenuService';
    import { eventBus } from '@/service/Infrastructure/EventBus';
    import { currentPatientCache } from '@/store/CurrentPatientCache';
    import { currentVisitCache } from '@/store/CurrentVisitCache';
    import { employeeSettingCache } from '@/store/EmployeeSettingCache';
    import { menuState } from '@/store/MenuState';
    import { userContextCache } from '@/store/User/UserContextCache';
    import { userSettingsState } from '@/store/User/UserSettingsState';

    @Component({
        components: { LoadingAnimation }
    })
    export default class VisitMenu extends Vue {
        @Prop()
        private visitId!: number | null;

        @Prop()
        private examId!: number | null;

        // Забавя mount-ването на компонента за документа, за да може той да използва зареденото тук текущо посещение.
        private startLoadingDocument: boolean = false;

        private items: ExamMenuItem[] = [];

        private async mounted() {
            eventBus.$on('create-referral-event', this.createExamDocumentEventHandler);
            eventBus.$on('update-referral-event', this.updateExamDocumentEventHandler);
            eventBus.$on('delete-referral-event', this.deleteExamDocumentEventHandler);
            eventBus.$on('generate-menu-event', this.generateMenuItems);

            if (!this.smAndDownResolution) {
                this.showSecondaryMenu = true;
            }
            menuState.showSecondaryMenuAppBarIcon = true;
            menuState.secondaryMenuAppBarIcon = 'mdi-stethoscope';

            // При добавяне на документ, visitId/examId е известно отнапред, затова текущото посещение се зарежда
            // възможно най-рано (тук), за да се покаже правилното меню още отначало.
            // При редактиране на документ обаче visitId/examId става известно едва след зареждането на документа,
            // така че всички компоненти за документ трябва да зареждат текущото посещение при редактиране.
            const visitOrExamId = this.visitId ?? this.examId;
            if (visitOrExamId) {
                await currentVisitCache.load(visitOrExamId);
            } else {
                // След като компонентът за документа зареди документа, visitId/examId ще стане известно,
                // компонентът за документа ще зареди и текущия преглед, а тази компонент ще прегенерира менюто.
            }
            // С този флаг се забавя mount-ването на компонента за документа, за да може той да използва зареденото тук текущо посещение.
            this.startLoadingDocument = true;

            await this.generateMenuItems();
            this.setDrawerWidth();
            window.addEventListener('resize', this.onResize);
        }

        private destroyed() {
            eventBus.$off('create-referral-event');
            eventBus.$off('update-referral-event');
            eventBus.$off('delete-referral-event');
            eventBus.$off('generate-menu-event');
            menuState.showSecondaryMenuAppBarIcon = false;
        }

        private createExamDocumentEventHandler(
            documentId: number | null,
            documentType: string | null,
            documentTitle: string | null,
            addedDocumentsCount: number | null
        ) {
            if (documentId && documentType) {
                const menuItem = this.items.find((item) => item.id === documentType);
                const visitId = currentVisitCache.value.id;
                if (menuItem) {
                    if (addedDocumentsCount) {
                        menuItem.documentsCount += addedDocumentsCount;
                    } else {
                        ++menuItem.documentsCount;
                    }
                    menuItem.link = `/Exam/${documentType}${menuItem.documentsCount === 0 ? '/Create' : ''}/${visitId}`;
                    this.items = JSON.parse(JSON.stringify(this.items));
                    this.$notifier.showSuccess('Запис', `Успешно създаден запис: ${documentTitle}`);
                }
            }
        }

        private updateExamDocumentEventHandler(documentTitle: string | null) {
            this.$notifier.showSuccess('Запис', `Успешно редактиран запис: ${documentTitle}`);
        }

        private deleteExamDocumentEventHandler(
            documentId: number | null,
            documentType: string | null,
            documentTitle: string | null
        ) {
            if (documentId && documentType) {
                const menuItem = this.items.find((item) => item.id === documentType);
                const visitId = currentVisitCache.value.id;
                if (menuItem) {
                    --menuItem.documentsCount;
                    menuItem.link = `/Exam/${documentType}${menuItem.documentsCount === 0 ? '/Create' : ''}/${visitId}`;
                    this.items = JSON.parse(JSON.stringify(this.items));
                    this.$notifier.showSuccess('Изтриване', `Успешно изтрит запис: ${documentTitle}`);
                }
            }
        }

        private get currentVisit() {
            return currentVisitCache.value;
        }

        // При редактиране(не добавяне) на документ, текущото посещение става известно едва след зареждането на документа.
        // visitId/examId идва заедно с документа и съответният компонент за документа зарежда текущото посещение.
        // Този компонент следи за промяна в текущото посещение и прегенерира менюто.
        // При добавяне на документ, visitId/examId е известно отнапред и текущото посещение се зарежда в mounted.
        @Watch('currentVisit')
        private onCurrentVisitChanged() {
            const preloadedVisitId = this.visitId ?? this.examId;
            if (!preloadedVisitId || !currentVisitCache.isLoaded || currentVisitCache.value.id !== preloadedVisitId) {
                this.generateMenuItems();
            }
        }

        private lastGenerateMenuItemsSequence: number = 0;

        private async generateMenuItems() {
            const requestSeq = ++this.lastGenerateMenuItemsSequence;

            const menuItems: ExamMenuItem[] = [];
            this.addVisitSummaryMenuItem(menuItems);
            if (currentVisitCache.isLoaded) {
                await this.addMenuItemsForExistingVisit(menuItems, requestSeq);
            } else {
                this.addMenuItemsWhichCreateVisit(menuItems);
            }

            // Показва се само менюто, върнато в резултат от последната заявка за изграждане на менюто.
            // Така по-бавна, но по-стара заявка няма да замаже менюто от по-нова, но по-бърза заявка.
            if (requestSeq === this.lastGenerateMenuItemsSequence) {
                this.items = menuItems;
            }
        }

        private addVisitSummaryMenuItem(items: ExamMenuItem[]) {
            if (currentPatientCache.isLoaded) {
                items.push(
                    new ExamMenuItem(
                        null,
                        'Посещения',
                        `/Patient/VisitSummary/${currentPatientCache.value.key.patientId}`,
                        0
                    )
                );
            }
        }

        private async addMenuItemsForExistingVisit(menuItems: ExamMenuItem[], requestSeq: number) {
            const currentVisit = currentVisitCache.value;
            const docTypeCounts = currentVisit.exam
                ? await examDocumentMenuService.getExamDocumentCount(currentVisit.id)
                : await examDocumentMenuService.getNoExamDocumentCount(currentVisit.id);

            // Ако след await-а има по-нова заявка за изграждане на менюто, няма смисъл да продължаваме с това изграждане.
            if (requestSeq !== this.lastGenerateMenuItemsSequence) {
                return;
            }

            menuItems.push(
                new ExamMenuItem(null, 'Амбулаторен лист', `/Exam/AmbulatorySheet/Edit/${currentVisit.id}`, 0)
            );

            const specialtyCode = userContextCache.current?.specialtyCode ?? null;
            const documentTypes = currentVisit.exam
                ? employeeSettingCache.settings?.visitMenuItems?.length > 0
                    ? employeeSettingCache.settings.visitMenuItems
                    : visitMenuService.getAvailableMenuItemsForSpecialty(specialtyCode)
                : createNoExamDocumentTypes();

            for (let index = 0; index < documentTypes.length; index++) {
                const examDocumentType = documentTypes[index];
                const type = docTypeCounts.find((document) => document.documentTypeCode === examDocumentType.code);
                if (type) {
                    const link =
                        type.documentsCount === 0
                            ? `/Exam/${examDocumentType.code}/Create/${currentVisit.id}`
                            : `/Exam/${examDocumentType.code}/${currentVisit.id}`;
                    const menuItem = new ExamMenuItem(
                        examDocumentType.code,
                        examDocumentType.title,
                        link,
                        type.documentsCount
                    );
                    menuItems.push(menuItem);
                }
            }
        }

        private addMenuItemsWhichCreateVisit(menuItems: ExamMenuItem[]) {
            menuItems.push(new ExamMenuItem(null, 'Амбулаторен лист', '/Exam/AmbulatorySheet/Create', 0));

            const documentTypes = createNoExamDocumentTypes();
            for (let index = 0; index < documentTypes.length; index++) {
                const examDocumentType = documentTypes[index];
                menuItems.push(
                    new ExamMenuItem(
                        examDocumentType.code,
                        examDocumentType.title,
                        `/Exam/${examDocumentType.code}/Create/Visit`,
                        0
                    )
                );
            }
        }

        @Ref()
        private drawerContainer!: HTMLElement;

        private drawerWidth: number = 0;

        private setDrawerWidth() {
            const widthCorrection = 56;
            this.drawerWidth =
                (this.drawerContainer?.clientWidth ?? 0) + (this.mainMenuIsVisible ? widthCorrection : 0);
        }

        onResize(): void {
            this.setDrawerWidth();
        }

        private get drawerLeftOffset(): number {
            const mainMenuExpandOffset = 200;
            return this.mainMenuIsVisible && !menuState.mainMenuIsCollapsed ? mainMenuExpandOffset : 0;
        }

        private get mainMenuIsVisible() {
            return this.$vuetify.breakpoint.lgAndUp;
        }

        private get smAndDownResolution() {
            return this.$vuetify.breakpoint.smAndDown;
        }

        @Watch('smAndDownResolution')
        private smAndDownResolutionChanged() {
            menuState.showSecondaryMenuAppBarIcon = this.smAndDownResolution;
            this.showSecondaryMenu = !this.smAndDownResolution;
        }

        private get showSecondaryMenu() {
            return menuState.showSecondaryMenu;
        }

        private set showSecondaryMenu(value) {
            menuState.showSecondaryMenu = value;
            this.onResize();
        }

        // Иконките в менютата изглеждат зле с primary цвят на тъмна тема, затова тогава се използва secondary.
        private get iconColor() {
            return userSettingsState.userSettings.ui.isDarkTheme ? 'secondary' : 'primary';
        }
    }
</script>
