import Vue from 'vue';

import { PidTypeDto } from '@/model/Nomenclature/PidTypeDto';
import { pidTypeService } from '@/service/Nomenclature/PidTypeService';
import { ArrayCacheBase } from '@/store/Nomenclature/ArrayCacheBase';

class PidTypeCache extends ArrayCacheBase<PidTypeDto> {
    protected async load() {
        const response = await pidTypeService.getPidTypes();
        this._items = response.data;
    }
}

export const pidTypeCache = Vue.observable(new PidTypeCache());
