<template>
    <v-row no-gutters>
        <v-col>
            <v-row no-gutters>
                <v-col md="4">
                    <v-checkbox
                        v-model="value.isNewFoundComputed"
                        class="no-margin-no-padding"
                        disabled
                        dense
                        label="Новооткрито"
                    ></v-checkbox>
                </v-col>
                <v-col md="4"
                    ><v-checkbox
                        v-model="value.isIncidental"
                        class="no-margin-no-padding"
                        dense
                        label="Инцидентно"
                    ></v-checkbox
                ></v-col>
                <v-col md="4">
                    <v-checkbox
                        v-model="value.isInRegister"
                        class="no-margin-no-padding"
                        dense
                        label="Вкл. в регистър"
                    ></v-checkbox>
                </v-col>
            </v-row>

            <v-row no-gutters>
                <v-col md="6" cols="12">
                    <dropdown
                        v-model="value.nhifVisitReasonCode"
                        class="no-margin-no-padding"
                        :items="nhifVisitReasons"
                        item-value="code"
                        label="Посещение за"
                        required
                        :disabled="value.isIncidental"
                        @input="onInput"
                    ></dropdown>
                </v-col>

                <v-col v-show="!isHighSpecializedExam" md="6" cols="12">
                    <dropdown
                        v-model="selectedExamType"
                        class="no-margin-no-padding"
                        :items="examTypes"
                        return-object
                        label="Вид преглед"
                        required
                    ></dropdown>
                </v-col>
                <v-col md="6" cols="12">
                    <multi-select-dropdown
                        v-if="isPreventiveExam && (isDevOrTest || isStaging)"
                        v-model="value.nhisPreventiveExamTypeCode"
                        class="no-margin-no-padding"
                        :items="nhisPreventiveExamTypes"
                        item-value="code"
                        label="Вид профилатичен преглед преглед"
                        required
                    ></multi-select-dropdown>
                </v-col>
                <v-col v-if="isDevOrTest || isStaging" md="6" cols="12">
                    <nhis-preventive-exam v-if="isPreventiveExam" @nhis-medical-history-change="updateMedicalHistory" />
                </v-col>
            </v-row>
            <v-row v-if="isMotherPrevention" no-gutters>
                <v-col md="6">
                    <date-picker
                        v-model="value.lastRegularMenstruation"
                        label="ПРМ:"
                        :required="!isAfterPregnancyMonitoring"
                    />
                </v-col>
                <v-col md="6">
                    <text-field
                        v-model="value.gestationalWeek"
                        label="Гестационна седмица:"
                        type="number"
                        :required="!isAfterPregnancyMonitoring"
                        :rules="[$validator.minValue(0), $validator.maxValue(43)]"
                    />
                </v-col>
            </v-row>
            <v-checkbox
                v-if="isSpecialist"
                v-model="value.isInDisadvantagedRegion"
                class="no-margin-no-padding"
                dense
                label="Необлагодетелстван район"
            ></v-checkbox>
            <v-row no-gutters>
                <v-col cols="12"><v-label class="no-margin-no-padding">Имунизации</v-label></v-col>
                <v-col md="3">
                    <text-field v-model="value.immunizations[0]" class="no-margin-no-padding" disabled label="код:" />
                </v-col>
                <v-col md="3">
                    <text-field v-model="value.immunizations[1]" class="no-margin-no-padding" disabled label="код:" />
                </v-col>
                <v-col md="3">
                    <text-field v-model="value.immunizations[2]" class="no-margin-no-padding" disabled label="код:" />
                </v-col>
                <v-col md="3">
                    <text-field
                        v-model="value.immunizationMantu"
                        class="no-margin-no-padding"
                        disabled
                        label="проба Манту:"
                    />
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script lang="ts">
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

    import MultiSelectDropdown from '@/component/Dropdown/MultiSelectDropdown.vue';
    import { config } from '@/Config';
    import { NhifVisitReasonCode } from '@/enum/Exam/NhifVisitReasonCode';
    import { SpecialtyCode } from '@/enum/Nomenclature/SpecialtyCode';
    import { PatientRegistrationTypeCode } from '@/enum/Patient/PatientRegistrationTypeCode';
    import { ExamDto } from '@/model/Exam/ExamDto';
    import { NhisMedicalHistoryDto } from '@/model/Nhis/Exam/NhisMedicalHistoryDto';
    import { NhisPreventiveExamTypeCommand } from '@/model/Nhis/Exam/NhisPreventiveExamTypeCommand';
    import { ExamTypeDropdownModel } from '@/model/Nomenclature/ExamTypeDropdownModel';
    import { NhifVisitReasonDto } from '@/model/Nomenclature/NhifVisitReasonDto';
    import { NhisPreventiveExamTypeDto } from '@/model/Nomenclature/NhisPreventiveExamTypeDto';
    import { examTypeService } from '@/service/Nomenclature/ExamTypeService';
    import { nhifVisitReasonService } from '@/service/Nomenclature/NhifVisitReasonService';
    import { nhisPreventiveExamTypeService } from '@/service/Nomenclature/NhisPreventiveExamTypeService';
    import { currentPatientCache } from '@/store/CurrentPatientCache';
    import { userContextCache } from '@/store/User/UserContextCache';
    import NhisPreventiveExam from '@/view/Nhis/LongtermCare/NhisPreventiveExam.vue';

    @Component({
        components: { MultiSelectDropdown, NhisPreventiveExam }
    })
    export default class AmbulatorySheetType extends Vue {
        private nhifVisitReasons: NhifVisitReasonDto[] = [];
        private examTypes: ExamTypeDropdownModel[] = examTypeService.getExamTypes();
        private nhisPreventiveExamTypes: NhisPreventiveExamTypeDto[] = [];

        @Prop({ type: Object })
        value!: ExamDto;

        private onInput() {
            this.$emit('visit-type-change', this.value.nhifVisitReasonCode);
            const visitReason = this.nhifVisitReasons.find((type) => type.code === this.value.nhifVisitReasonCode);
            this.$notifier.showWarning('Внимание', `Типът на прегледа е: ${visitReason?.name ?? ''}`);
        }

        private get isPreventiveExam() {
            return this.value.nhifVisitReasonCode.includes('Prevention');
        }

        private get isAfterPregnancyMonitoring() {
            const [mainDiag] = this.value.diagnoses;
            return mainDiag.icdCode === 'Z39.2';
        }

        private get isHighSpecializedExam() {
            return this.value.nhifVisitReasonCode === NhifVisitReasonCode.HighSpecialized;
        }

        private getNhisPreventiveExamTypeCommand() {
            const command = new NhisPreventiveExamTypeCommand();
            command.examDateTime = this.value.startDateTime;
            command.icdCode = this.value.diagnoses[0]?.icdCode ?? null;
            command.patientBirthDate = currentPatientCache.value.personIdentification.birthDate ?? new Date();
            command.genderCode = currentPatientCache.value.personIdentification.genderCode;
            return command;
        }

        @Watch('value.lastRegularMenstruation')
        private onLastRegularMenstruationChanged() {
            const daysInWeek: number = 7;
            const thousand: number = 1000;
            const sixty: number = 60;
            const hours: number = 24;
            if (
                this.value.lastRegularMenstruation !== null &&
                this.value.nhifVisitReasonCode === NhifVisitReasonCode.MotherPrevention
            ) {
                const timeInMilisec: number =
                    this.value.startDateTime.getTime() - this.value.lastRegularMenstruation.getTime();
                const daysBetweenDates: number = Math.ceil(timeInMilisec / (thousand * sixty * sixty * hours));
                this.value.gestationalWeek = this.isAfterPregnancyMonitoring
                    ? 0
                    : Math.floor(daysBetweenDates / daysInWeek + 1);
                this.calculateProbableBirthTerm();
            }
        }

        private updateMedicalHistory(item: NhisMedicalHistoryDto) {
            if (
                this.value.nhisPreventiveExamTypeCode.some(
                    (examType) => examType === item.nhisPreventiveExamTypeCode[0]
                )
            ) {
                return;
            }
            this.value.nhisPreventiveExamTypeCode = this.value.nhisPreventiveExamTypeCode.concat(
                item.nhisPreventiveExamTypeCode
            );

            if (!this.value.nhisMedicalHistory) {
                this.value.nhisMedicalHistory = new NhisMedicalHistoryDto();
            }
            for (let itemIndex = 0; itemIndex < Object.keys(item)?.length; itemIndex++) {
                const itemProperty = Object.keys(item)[itemIndex] as keyof typeof item;
                if (Array.isArray(item[itemProperty]) && Array.isArray(this.value.nhisMedicalHistory[itemProperty])) {
                    this.value.nhisMedicalHistory[itemProperty] = item[itemProperty].concat(
                        this.value.nhisMedicalHistory[itemProperty]
                    ) as [];
                }
            }
        }

        private get isDevOrTest() {
            return config.isDevOrTest;
        }

        private get isStaging() {
            return config.isStaging;
        }

        private get selectedExamType() {
            return (
                this.examTypes.find(
                    (et) => et.isSecondary === this.value.isSecondary && et.isDomestic === this.value.isDomestic
                ) ?? this.examTypes[0]
            );
        }

        private set selectedExamType(examType: ExamTypeDropdownModel) {
            if (examType) {
                this.value.isSecondary = examType.isSecondary;
                this.value.isDomestic = examType.isDomestic;
                if (this.value.isSecondary) {
                    this.value.isInRegister = false;
                } else {
                    this.value.primaryExamId = null;
                }
            }
        }

        private calculateProbableBirthTerm() {
            const thousand: number = 1000;
            const sixty: number = 60;
            const days: number = 280;
            const hours: number = 24;
            if (this.value.lastRegularMenstruation !== null) {
                this.value.probableBirthTerm = new Date(
                    this.value.lastRegularMenstruation.getTime() + days * (thousand * sixty * sixty * hours)
                );
            }
        }

        private async loadVisitReasons() {
            const responseVisitReasons = await nhifVisitReasonService.getNhifVisitReasons();
            this.nhifVisitReasons = responseVisitReasons.data;
            this.$emit('visit-reasons-loaded', responseVisitReasons.data);
        }

        private isIncidentalVisit() {
            this.value.isIncidental =
                currentPatientCache?.value.patientRegistrationTypeCode === PatientRegistrationTypeCode.OneTimeVisit;
        }

        private get isMotherPrevention() {
            return this.value.nhifVisitReasonCode === NhifVisitReasonCode.MotherPrevention;
        }

        private get isSpecialist() {
            return userContextCache.current?.specialtyCode !== SpecialtyCode.Gp;
        }

        private async mounted() {
            await this.loadVisitReasons();
            this.isIncidentalVisit();
        }

        @Watch('value.isIncidental')
        onIsIncidentalChange(newVal: boolean) {
            if (newVal) {
                this.value.nhifVisitReasonCode = NhifVisitReasonCode.Consultation;
            }
        }

        @Watch('value.nhifVisitReasonCode')
        private async getNhisPreventiveExamTypes() {
            if (this.isPreventiveExam) {
                const command = this.getNhisPreventiveExamTypeCommand();
                const response = await nhisPreventiveExamTypeService.getNhisPreventiveExamTypes(command);
                this.nhisPreventiveExamTypes = response.data;
            }
        }
    }
</script>
