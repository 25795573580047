export class MonthlyNotificationDto {
    activity: string = '';
    encoding: string = '';
    fileData: string = '';
    fileId: string = '';
    fileName: string = '';
    fromDate: string = '';
    fileType: string = '';
    rcz: string = '';
    rzcNo: string = '';
    taxDate: Date | null = null;
    toDate: Date | null = null;
}
