import { NhisExamDto } from '@/model/Nhis/Exam/NhisExamDto';
import { NhisExamExtractionCommand } from '@/model/Nhis/Exam/NhisExamExtractionCommand';
import { NhisExamExtractionResultDto } from '@/model/Nhis/Exam/NhisExamExtractionResultDto';
import { NhisXmlRequestDto } from '@/model/Nhis/NhisXmlRequestDto';
import { httpService } from '@/service/Infrastructure/HttpService';
import { notifierService } from '@/service/Infrastructure/NotifierService';
import { nhisService } from '@/service/Nhis/NhisService';
import { signXmlService } from '@/service/Report/SignXmlService';
import { loadingState } from '@/store/LoadingState';
import { nhisKey } from '@/store/NhisNhifAccessTokenCache';

const getOpeningExamXml = async function getOpeningExamXml(requestData: NhisXmlRequestDto): Promise<string> {
    const response = await httpService.post<string>(`/${nhisKey}/GetOpeningExamXmlV3`, requestData);
    console.log(response);
    return response?.data ?? '';
};

const getClosingExamXml = async function getClosingExamXml(requestData: NhisXmlRequestDto): Promise<string> {
    const response = await httpService.post<string>(`/${nhisKey}/GetClosingExamXmlV3`, requestData);
    console.log(response);
    return response?.data ?? '';
};

const getCorrectionExamXml = async function getCorrectionExamXml(requestData: NhisXmlRequestDto): Promise<string> {
    const response = await httpService.post<string>(`/${nhisKey}/GetCorrectionExamXmlV3`, requestData);
    console.log(response);
    return response?.data ?? '';
};

const getXmlCancellationExam = async function getXmlCancellationExam(requestData: NhisXmlRequestDto): Promise<string> {
    const response = await httpService.post<string>(`/${nhisKey}/GetCancellationExamXmlV3`, requestData);
    console.log(response);
    return response?.data ?? '';
};

const getSickSheetsXml = async function getSickSheetsXml(requestData: NhisXmlRequestDto): Promise<string> {
    const response = await httpService.post<string>(`/${nhisKey}/GetSickSheetsV3`, requestData);
    console.log(response);
    return response?.data ?? '';
};

const getExamExtractionRequestXml = async function getExamExtractionRequestXml(
    requestData: NhisExamExtractionCommand
): Promise<string> {
    const response = await httpService.post<string>(`/${nhisKey}/GetExamExtractionRequestXmlV3`, requestData);
    console.log(response);
    return response?.data ?? '';
};

const getConsultationExtractionRequestXml = async function getConsultationExtractionRequestXml(
    requestData: NhisExamExtractionCommand
): Promise<string> {
    const response = await httpService.post<string>(`/${nhisKey}/GetConsultationExtractionRequestXmlV3`, requestData);
    console.log(response);
    return response?.data ?? '';
};

export const nhisExamV3Service = {
    async postOpeningExam(requestData: NhisXmlRequestDto) {
        loadingState.show();
        try {
            const xmlString = await getOpeningExamXml(requestData);
            const signResponse = await signXmlService.signXml(xmlString);
            if (!signResponse.isError && signResponse.contents) {
                const examResult = await nhisService.postXmlToNhisWithId<NhisExamDto>(
                    'PostOpenExamRequestV3',
                    signResponse.contents,
                    requestData.entityId
                );
                console.log(examResult?.response);
                if (examResult?.error?.length > 0) {
                    await notifierService.showWarning('Внимание', `${examResult.error}`);
                } else {
                    if (examResult?.warnings?.length > 0) {
                        nhisService.showWarnings(examResult.warnings);
                    }
                    return examResult;
                }
            }
            return null;
        } finally {
            loadingState.hide();
        }
    },

    async postClosingExam(requestData: NhisXmlRequestDto) {
        loadingState.show();
        try {
            const xmlString = await getClosingExamXml(requestData);
            const signResponse = await signXmlService.signXml(xmlString);
            if (!signResponse.isError && signResponse.contents) {
                const examResult = await nhisService.postXmlToNhisWithId<NhisExamDto>(
                    'PostClosingExamRequestV3',
                    signResponse.contents,
                    requestData.entityId
                );
                console.log(examResult?.response);
                if (examResult?.error?.length > 0) {
                    await notifierService.showWarning('Внимание', `${examResult.error}`);
                } else {
                    if (examResult?.warnings?.length > 0) {
                        nhisService.showWarnings(examResult.warnings);
                    }
                    return examResult;
                }
            }
            return null;
        } finally {
            loadingState.hide();
        }
    },

    async postCorrectionExam(requestData: NhisXmlRequestDto) {
        loadingState.show();
        try {
            const xmlString = await getCorrectionExamXml(requestData);
            const signResponse = await signXmlService.signXml(xmlString);
            if (!signResponse.isError && signResponse.contents) {
                const examResult = await nhisService.postXmlToNhisWithId<NhisExamDto>(
                    'PostCorrectionExamRequestV3',
                    signResponse.contents,
                    requestData.entityId
                );
                console.log(examResult?.response);
                if (examResult?.error?.length > 0) {
                    await notifierService.showWarning('Внимание', `${examResult.error}`);
                } else {
                    await notifierService.showSuccess('Резултат', `${'Успешно редактиране'}`);
                    if (examResult?.warnings?.length > 0) {
                        nhisService.showWarnings(examResult.warnings);
                    }
                    return examResult;
                }
            }
            return null;
        } finally {
            loadingState.hide();
        }
    },

    async postSickSheets(requestData: NhisXmlRequestDto) {
        loadingState.show();
        try {
            const xmlString = await getSickSheetsXml(requestData);
            if (xmlString === '') {
                await notifierService.showWarning(
                    '',
                    'Не са намерени активни/анулирани болнични листове! Моля проверете дали бланките са изпретени към НОИ'
                );
            }
            const signResponse = await signXmlService.signXml(xmlString);
            if (!signResponse.isError && signResponse.contents) {
                const examResult = await nhisService.postXmlToNhisWithId<NhisExamDto>(
                    'PostSickSheetsRequestV3',
                    signResponse.contents,
                    requestData.entityId
                );
                console.log(examResult?.response);
                if (examResult?.error?.length > 0) {
                    await notifierService.showWarning('Внимание', `${examResult.error}`);
                } else {
                    await notifierService.showSuccess('Резултат', `${'Успешно изпращане'}`);
                    if (examResult?.warnings?.length > 0) {
                        nhisService.showWarnings(examResult.warnings);
                    }
                    return examResult;
                }
            }
            return null;
        } finally {
            loadingState.hide();
        }
    },

    async postCancelExam(requestData: NhisXmlRequestDto) {
        loadingState.show();
        try {
            const xmlString = await getXmlCancellationExam(requestData);
            const signResponse = await signXmlService.signXml(xmlString);
            if (!signResponse.isError && signResponse.contents) {
                const examResult = await nhisService.postXmlToNhisWithId<NhisExamDto>(
                    'PostCancellationExamRequestV3',
                    signResponse.contents,
                    requestData.entityId
                );
                console.log(examResult?.response);
                if (examResult?.error?.length > 0) {
                    await notifierService.showWarning('Внимание', `${examResult.error}`);
                } else {
                    return examResult;
                }
            }
            return null;
        } finally {
            loadingState.hide();
        }
    },

    async postExamExtraction(requestData: NhisExamExtractionCommand) {
        loadingState.show();
        try {
            const xmlString = await getExamExtractionRequestXml(requestData);
            const signResponse = await signXmlService.signXml(xmlString);
            if (!signResponse.isError && signResponse.contents) {
                const extractionResult = await nhisService.postXmlToNhis<NhisExamExtractionResultDto>(
                    'PostExamExtractionV3',
                    signResponse.contents
                );
                console.log(extractionResult?.response);

                if (extractionResult?.error?.length > 0) {
                    await notifierService.showWarning('Внимание', `${extractionResult.error}`);
                } else {
                    return extractionResult;
                }
            }
            return null;
        } finally {
            loadingState.hide();
        }
    },

    async postExamNrnRecovery(requestData: NhisExamExtractionCommand, entityId: number) {
        loadingState.show();
        try {
            const xmlString = await getExamExtractionRequestXml(requestData);
            const signResponse = await signXmlService.signXml(xmlString);
            if (!signResponse.isError && signResponse.contents) {
                const recoveryResult = await nhisService.postXmlToNhisWithId<NhisExamDto>(
                    'PostExamV3RecoveryByVisitId',
                    signResponse.contents,
                    entityId
                );
                console.log(recoveryResult?.response);

                if (recoveryResult?.error?.length > 0) {
                    await notifierService.showWarning('Внимание', `${recoveryResult.error}`);
                } else {
                    return recoveryResult;
                }
            }
            return null;
        } finally {
            loadingState.hide();
        }
    },

    async postConsultationExtraction(requestData: NhisExamExtractionCommand) {
        loadingState.show();
        try {
            const xmlString = await getConsultationExtractionRequestXml(requestData);
            const signResponse = await signXmlService.signXml(xmlString);
            if (!signResponse.isError && signResponse.contents) {
                const extractionResult = await nhisService.postXmlToNhis<NhisExamExtractionResultDto>(
                    'PostConsultationExtractionV3',
                    signResponse.contents
                );
                console.log(extractionResult?.response);

                if (extractionResult?.error?.length > 0) {
                    await notifierService.showWarning('Внимание', `${extractionResult.error}`);
                } else {
                    return extractionResult;
                }
            }
            return null;
        } finally {
            loadingState.hide();
        }
    },

    //Флагът setNewSignature се използва за да се сетне датата на подписване, но когато се прави проверка на подписа
    // тази дата трябва да остане същата
    async getExamDataToSign(examId: number, setNewSignature: boolean): Promise<string> {
        const response = await httpService.get<string>(`/${nhisKey}/GetExamDataToSignV3`, {
            params: {
                examId,
                setNewSignature
            }
        });
        return response?.data;
    },

    async getExamDataCorrectionToSign(examId: number, setSignatureDate: boolean): Promise<string> {
        const response = await httpService.get<string>(`/${nhisKey}/GetExamDataCorrectionToSignV3`, {
            params: {
                examId,
                setSignatureDate
            }
        });
        return response?.data;
    }
};
