import { SpecialtyCode } from '@/enum/Nomenclature/SpecialtyCode';
import { ExamDocumentTypeDto } from '@/model/Exam/ExamDocumentTypeDto';
import {
    createExamDocumentTypes,
    VisitMenuSpecialtyAdditions,
    VisitMenuSpecialtyExclusions
} from '@/model/Menu/ExamDocumentMenuItems';
import { arrayUtil } from '@/util/ArrayUtil';

export const visitMenuService = {
    getAvailableMenuItemsForSpecialty(specialtyCode: SpecialtyCode | null): ExamDocumentTypeDto[] {
        const menuItems: ExamDocumentTypeDto[] = createExamDocumentTypes();
        if (specialtyCode) {
            const exclusions = VisitMenuSpecialtyExclusions[specialtyCode];
            if (exclusions) {
                for (const excludedDocumentTitle of exclusions) {
                    arrayUtil.removeFirst(menuItems, (item) => item.code === excludedDocumentTitle);
                }
            }

            const additions = VisitMenuSpecialtyAdditions[specialtyCode];
            if (additions) {
                menuItems.push(...additions);
            }
        }

        return menuItems;
    }
};
