import { plainToInstance } from 'class-transformer';

import { LkkDecisionDto } from '@/model/Exam/LkkDecision/LkkDecisionDto';
import { LkkDecisionViewDto } from '@/model/Exam/LkkDecision/LkkDecisionViewDto';
import { NssiUploadResponseDto } from '@/model/Integration/Nssi/NssiUploadResponseDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const lkkDecisionService = {
    async getLkkDecisionInfoByPatientAndDoctor(
        patientId: number,
        doctorEmployeeSeqNumber: number
    ): Promise<LkkDecisionDto> {
        const response = await httpService.get<LkkDecisionDto>('LkkDecision/GetLkkDecisionInfoByPatientAndDoctor', {
            params: { patientId, doctorEmployeeSeqNumber }
        });
        return response.data;
    },

    async getLkkDecisionInfoByVisit(visitId: number): Promise<LkkDecisionDto> {
        const response = await httpService.get<LkkDecisionDto>('LkkDecision/GetLkkDecisionInfoByVisit', {
            params: { visitId }
        });
        return plainToInstance(LkkDecisionDto, response.data);
    },

    async getLkkDecisionById(id: number): Promise<LkkDecisionDto> {
        const response = await httpService.get<LkkDecisionDto>(`LkkDecision/GetLkkDecisionById/${id}`);
        return plainToInstance(LkkDecisionDto, response.data);
    },

    async createLkkDecision(doctorEmployeeSeqNumber: number, data: LkkDecisionDto): Promise<number> {
        const response = await httpService.post<number>('LkkDecision/CreateLkkDecision', data, {
            params: { doctorEmployeeSeqNumber }
        });
        return response.data;
    },

    async updateLkkDecision(data: LkkDecisionDto): Promise<void> {
        await httpService.post('LkkDecision/UpdateLkkDecision/', data);
    },

    // Връща дали посещението е било изтрито заедно с БЛ.
    async deleteLkkDecision(id: number): Promise<boolean> {
        const response = await httpService.delete<boolean>(`LkkDecision/DeleteLkkDecision/${id}`);
        return response.data;
    },

    async getLkkDecisionsForVisit(visitId: number): Promise<LkkDecisionViewDto[]> {
        const response = await httpService.get<LkkDecisionViewDto[]>(`LkkDecision/GetLkkDecisionsForVisit`, {
            params: { visitId }
        });
        return plainToInstance(LkkDecisionViewDto, response.data);
    },

    async generateLkkDecisionXml(lkkDecisionId: number, isFileCreate: boolean): Promise<string> {
        const response = await httpService.get<string>(`Nssi/CreateELkkDecisionRequest`, {
            params: { lkkDecisionId, isFileCreate }
        });
        return response.data;
    },

    async updateLkkDecisionStatus(practiceId: number, data: NssiUploadResponseDto): Promise<string[]> {
        const response = await httpService.post<string[]>(`Nssi/UpdateLkkDecisionStatus`, data, {
            params: { practiceId }
        });
        return response.data;
    },

    async getLkkDecisionValidateResult(practiceId: number, data: NssiUploadResponseDto): Promise<string[]> {
        const response = await httpService.post<string[]>(`Nssi/LkkDecisionTestResult`, data, {
            params: { practiceId }
        });
        return response.data;
    }
};
