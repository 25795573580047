import Vue from 'vue';

import { SpecialtyDto } from '@/model/Nomenclature/SpecialtyDto';
import { specialtyService } from '@/service/Nomenclature/SpecialtyService';
import { ArrayCacheBase } from '@/store/Nomenclature/ArrayCacheBase';

class SpecialtyCache extends ArrayCacheBase<SpecialtyDto> {
    protected async load() {
        const response = await specialtyService.getSpecialties();
        this._items = response.data;
    }
}

export const specialtyCache = Vue.observable(new SpecialtyCache());
