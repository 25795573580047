// В случая не може да се ползва destructuring, защото env се замества с константи от webpack.
//const { env } = process;
// eslint-disable-next-line prefer-destructuring
const env = process.env;

class Config {
    // BASE_URL трябва да има вида '/path/' или '/'. Виж също коментара на publicPath във vue.config.js.
    public spaBaseUrlRelative: string = env.BASE_URL || '/';

    public get spaBaseUrlAbsolute() {
        return `${window.location.origin}${this.spaBaseUrlRelative}`;
    }

    // Feature flags за скриване на части от системата, които не са готови за production или дори за тестване.
    // Тестовите environment-и са LocalIIS, PlevenTest и AzureTestWin, освен development.
    // Продукционен environment е AzureProdLinux, а AzureTestLinux се използва за обучения, затова е еднакъв с продукционния.
    public isDevelopment: boolean = env.NODE_ENV === 'development';
    public isDevOrTest: boolean = this.isDevelopment || env.VUE_APP_IS_TEST_ENVIRONMENT === '1';
    public isStaging: boolean = env.VUE_APP_IS_STAGING_ENVIRONMENT === '1';

    // Адреси на останалите модули на системата.
    public mainApiBaseUrl: string = env.VUE_APP_MAIN_API_BASE_URL || '';
    public userGuideBaseUrl: string = env.VUE_APP_USER_GUIDE_BASE_URL || '';
    public printApiBaseUrl: string = env.VUE_APP_PRINT_API_BASE_URL || '';
    public identityServerBaseUrl: string = env.VUE_APP_IDENTITY_SERVER_BASE_URL || '';
    public localServerApiBaseUrl: string = env.VUE_APP_LOCAL_SERVER_API_BASE_URL || '';
    public nhifApiBaseUrl: string = env.VUE_APP_NHIF_BASE_URL || '';
}

export const config = new Config();
