<template>
    <div>
        <v-card>
            <v-card-title>
                <v-row dense>
                    <v-col cols="12" md="2">Сървъри</v-col>
                    <v-col cols="12" md="2"><small>API</small></v-col>
                    <v-col cols="12" md="2">
                        <small>
                            <a
                                class="text--secondary"
                                :href="`${printApiBaseUrl}/Diagnostic/GetEnvironment`"
                                target="_blank"
                            >
                                Печат
                            </a>
                        </small>
                    </v-col>
                    <v-col cols="12" md="2">
                        <small>
                            <a
                                class="text--secondary"
                                :href="`${identityServerBaseUrl}/Diagnostic/${isAuthenticated ? 'Session' : 'Server'}`"
                                target="_blank"
                            >
                                IdentityServer
                            </a>
                        </small>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-row>
                            <v-col><small>Пояснение</small></v-col>
                            <v-col cols="auto" class="pt-2">
                                <btn icon action="Refresh" @click="loadEnvAndDb">Обновяване на информацията</btn>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card-title>

            <v-card-text>
                <v-row dense>
                    <v-col cols="12" md="2">Процес</v-col>
                    <v-col cols="12" md="2">
                        <a
                            v-if="apiEnvironment.scmUrl"
                            class="text--secondary"
                            :href="apiEnvironment.scmUrl"
                            target="_blank"
                        >
                            {{ apiEnvironment.processText }}
                        </a>
                        <template v-else>
                            {{ apiEnvironment.processText }}
                        </template>
                        <value-loading :value="apiEnvironmentIsLoading"></value-loading>
                    </v-col>
                    <v-col cols="12" md="2">
                        <a
                            v-if="printEnvironment.scmUrl"
                            class="text--secondary"
                            :href="printEnvironment.scmUrl"
                            target="_blank"
                        >
                            {{ printEnvironment.processText }}
                        </a>
                        <template v-else>
                            {{ printEnvironment.processText }}
                        </template>
                        <value-loading :value="printEnvironmentIsLoading"></value-loading>
                    </v-col>
                    <v-col cols="12" md="2">
                        <!--
                            От IdentityServer няма как да дойде sensitive информацията (като scmUrl),
                            защото API-то му не е официално регистрирано като scope в самия IdentityServer,
                            съответно не валидира access token-а и винаги се държи като анонимно извикано.
                        -->
                        <a
                            v-if="idSrvEnvironment.scmUrl"
                            class="text--secondary"
                            :href="idSrvEnvironment.scmUrl"
                            target="_blank"
                        >
                            {{ idSrvEnvironment.processText }}
                        </a>
                        <template v-else>
                            {{ idSrvEnvironment.processText }}
                        </template>
                        <value-loading :value="idSrvEnvironmentIsLoading"></value-loading>
                    </v-col>
                    <v-col cols="12" md="4">Instance ProcessId</v-col>
                </v-row>

                <v-row dense>
                    <v-col cols="12" md="2">Рестартиран на</v-col>
                    <v-col cols="12" md="2">{{ apiEnvironment.startupText }}</v-col>
                    <v-col cols="12" md="2">{{ printEnvironment.startupText }}</v-col>
                    <v-col cols="12" md="2">{{ idSrvEnvironment.startupText }}</v-col>
                    <v-col cols="12" md="4">Process.StartTime ; warmup</v-col>
                </v-row>

                <v-row dense>
                    <v-col cols="12" md="2">Процесор</v-col>
                    <v-col cols="12" md="2">
                        {{ apiEnvironment.cpuText }}<br />
                        {{ apiEnvironment.cpuOsText }}
                    </v-col>
                    <v-col cols="12" md="2">
                        {{ printEnvironment.cpuText }}<br />
                        {{ printEnvironment.cpuText }}
                    </v-col>
                    <v-col cols="12" md="2">
                        {{ idSrvEnvironment.cpuText }}<br />
                        {{ idSrvEnvironment.cpuText }}
                    </v-col>
                    <v-col cols="12" md="4">общо<br />OS</v-col>
                </v-row>

                <v-row dense>
                    <v-col cols="12" md="2">Нишки pool</v-col>
                    <v-col cols="12" md="2">
                        {{ apiEnvironment.poolSizeText }}<br />
                        {{ apiEnvironment.workerThreadsText }}<br />
                        {{ apiEnvironment.ioThreadsText }}
                    </v-col>
                    <v-col cols="12" md="2">
                        {{ printEnvironment.poolSizeText }}<br />
                        {{ printEnvironment.workerThreadsText }}<br />
                        {{ printEnvironment.ioThreadsText }}
                    </v-col>
                    <v-col cols="12" md="2">
                        {{ idSrvEnvironment.poolSizeText }}<br />
                        {{ idSrvEnvironment.workerThreadsText }}<br />
                        {{ idSrvEnvironment.ioThreadsText }}
                    </v-col>
                    <v-col cols="12" md="4">pooled<br />running<br />I/O</v-col>
                </v-row>

                <v-row dense>
                    <v-col cols="12" md="2">Нишки процес</v-col>
                    <v-col cols="12" md="2">{{ apiEnvironment.threadsText }}</v-col>
                    <v-col cols="12" md="2">{{ printEnvironment.threadsText }}</v-col>
                    <v-col cols="12" md="2">{{ idSrvEnvironment.threadsText }}</v-col>
                    <v-col cols="12" md="4">running / total</v-col>
                </v-row>

                <v-row dense>
                    <v-col cols="12" md="2">Опашка</v-col>
                    <v-col cols="12" md="2">{{ apiEnvironment.pendingWorkText }}</v-col>
                    <v-col cols="12" md="2">{{ printEnvironment.pendingWorkText }}</v-col>
                    <v-col cols="12" md="2">{{ idSrvEnvironment.pendingWorkText }}</v-col>
                    <v-col cols="12" md="4">ThreadPool.Pending</v-col>
                </v-row>

                <v-row dense>
                    <v-col cols="12" md="2">Работа</v-col>
                    <v-col cols="12" md="2">
                        {{ apiEnvironment.workPerSecondText }}<br />
                        {{ apiEnvironment.mbPerSecondText }}
                    </v-col>
                    <v-col cols="12" md="2">
                        {{ printEnvironment.workPerSecondText }}<br />
                        {{ printEnvironment.mbPerSecondText }}
                    </v-col>
                    <v-col cols="12" md="2">
                        {{ idSrvEnvironment.workPerSecondText }}<br />
                        {{ idSrvEnvironment.mbPerSecondText }}
                    </v-col>
                    <v-col cols="12" md="4">ThreadPool.Completed rate<br />allocations rate</v-col>
                </v-row>

                <v-row dense>
                    <v-col cols="12" md="2">GC памет</v-col>
                    <v-col cols="12" md="2">
                        {{ apiEnvironment.committedText }}<br />
                        {{ apiEnvironment.heapText }}<br />
                        {{ apiEnvironment.fragmentedText }}<br />
                        {{ apiEnvironment.allocatedText }}
                    </v-col>
                    <v-col cols="12" md="2">
                        {{ printEnvironment.committedText }}<br />
                        {{ printEnvironment.heapText }}<br />
                        {{ printEnvironment.fragmentedText }}<br />
                        {{ printEnvironment.allocatedText }}
                    </v-col>
                    <v-col cols="12" md="2">
                        {{ idSrvEnvironment.committedText }}<br />
                        {{ idSrvEnvironment.heapText }}<br />
                        {{ idSrvEnvironment.fragmentedText }}<br />
                        {{ idSrvEnvironment.allocatedText }}
                    </v-col>
                    <v-col cols="12" md="4">committed<br />heap<br />fragmented<br />allocated</v-col>
                </v-row>

                <v-row dense>
                    <v-col cols="12" md="2">Почиствания</v-col>
                    <v-col cols="12" md="2">{{ apiEnvironment.gcWorkText }}</v-col>
                    <v-col cols="12" md="2">{{ printEnvironment.gcWorkText }}</v-col>
                    <v-col cols="12" md="2">{{ idSrvEnvironment.gcWorkText }}</v-col>
                    <v-col cols="12" md="4">recent gen 0, 1, 2 ; pause diff ; last promoted</v-col>
                </v-row>

                <v-row dense>
                    <v-col cols="12" md="2">Памет приложна</v-col>
                    <v-col cols="12" md="2">{{ apiEnvironment.processMemory.appMemoryText }}</v-col>
                    <v-col cols="12" md="2">{{ printEnvironment.processMemory.appMemoryText }}</v-col>
                    <v-col cols="12" md="2">{{ idSrvEnvironment.processMemory.appMemoryText }}</v-col>
                    <v-col cols="12" md="4">working set (peak) / private</v-col>
                </v-row>

                <v-row dense>
                    <v-col cols="12" md="2">Памет системна</v-col>
                    <v-col cols="12" md="2">{{ apiEnvironment.processMemory.systemMemoryText }}</v-col>
                    <v-col cols="12" md="2">{{ printEnvironment.processMemory.systemMemoryText }}</v-col>
                    <v-col cols="12" md="2">{{ idSrvEnvironment.processMemory.systemMemoryText }}</v-col>
                    <v-col cols="12" md="4">ram + paged</v-col>
                </v-row>

                <v-row dense>
                    <v-col cols="12" md="2">Памет виртуална</v-col>
                    <v-col cols="12" md="2">{{ apiEnvironment.processMemory.virtualMemoryText }}</v-col>
                    <v-col cols="12" md="2">{{ printEnvironment.processMemory.virtualMemoryText }}</v-col>
                    <v-col cols="12" md="2">{{ idSrvEnvironment.processMemory.virtualMemoryText }}</v-col>
                    <v-col cols="12" md="4">current (peak)</v-col>
                </v-row>

                <v-row dense>
                    <v-col cols="12" md="2">Хост</v-col>
                    <v-col cols="12" md="2">{{ apiEnvironment.hostText }}</v-col>
                    <v-col cols="12" md="2">{{ printEnvironment.hostText }}</v-col>
                    <v-col cols="12" md="2">{{ idSrvEnvironment.hostText }}</v-col>
                    <v-col cols="12" md="4">ядра ; памет ; up time</v-col>
                </v-row>

                <v-row dense>
                    <v-col cols="12" md="2">App обновен на</v-col>
                    <v-col cols="12" md="2"><date-label v-model="apiEnvironment.uploadTime" show-time /></v-col>
                    <v-col cols="12" md="2"><date-label v-model="printEnvironment.uploadTime" show-time /></v-col>
                    <v-col cols="12" md="2"><date-label v-model="idSrvEnvironment.uploadTime" show-time /></v-col>
                    <v-col cols="12" md="4">EntryAssembly.Location LastWriteTime</v-col>
                </v-row>

                <v-row dense>
                    <v-col cols="12" md="2">Изчислено в</v-col>
                    <v-col cols="12" md="2">{{ apiEnvironment.loadTimeText }}</v-col>
                    <v-col cols="12" md="2">{{ printEnvironment.loadTimeText }}</v-col>
                    <v-col cols="12" md="2">{{ idSrvEnvironment.loadTimeText }}</v-col>
                    <v-col cols="12" md="4">време за събиране на горната информация</v-col>
                </v-row>

                <v-row dense>
                    <v-col cols="12" md="2">Часовник app</v-col>
                    <v-col cols="12" md="2">{{ apiEnvironment.localTimeString }}</v-col>
                    <v-col cols="12" md="2">{{ printEnvironment.localTimeString }}</v-col>
                    <v-col cols="12" md="2">{{ idSrvEnvironment.localTimeString }}</v-col>
                    <v-col cols="12" md="4">сървърни формат на датата и часова зона</v-col>
                </v-row>

                <v-row dense>
                    <v-col cols="12" md="2">Часовник база</v-col>
                    <v-col cols="12" md="2">
                        <date-label v-model="apiDbLocalTime" show-time />
                        <value-loading :value="!apiDbLocalTime"></value-loading>
                    </v-col>
                    <v-col cols="12" md="2">
                        <date-label v-model="printDbLocalTime" show-time />
                        <value-loading :value="!printDbLocalTime"></value-loading>
                    </v-col>
                    <v-col cols="12" md="2">
                        <date-label v-model="idSrvDbLocalTime" show-time />
                        <value-loading :value="!idSrvDbLocalTime"></value-loading>
                    </v-col>
                    <v-col cols="12" md="4">linq select DateTime.Now --> sql select getdate()</v-col>
                </v-row>

                <v-row dense>
                    <v-col cols="12" md="2">Изходящ IP адрес</v-col>
                    <v-col cols="12" md="2">
                        {{ apiOutboundIp }}
                        <value-loading :value="!apiOutboundIp"></value-loading>
                    </v-col>
                    <v-col cols="12" md="2">
                        {{ printOutboundIp }}
                        <value-loading :value="!printOutboundIp"></value-loading>
                    </v-col>
                    <v-col cols="12" md="2">
                        {{ idSrvOutboundIp }}
                        <value-loading :value="!idSrvOutboundIp"></value-loading>
                    </v-col>
                    <v-col cols="12" md="4">
                        <a href="http://ipecho.net/plain" target="_blank">ipecho.net</a> (ще видите Вашето IP)
                    </v-col>
                </v-row>

                <v-row dense>
                    <v-col cols="12" md="2"></v-col>
                    <v-col cols="12" md="2"></v-col>
                    <v-col cols="12" md="2">
                        <template v-if="printStorageStats.sizeInKb > 10240">
                            {{ Math.round(printStorageStats.sizeInKb / 1024) }} MB
                        </template>
                        <template v-else>{{ printStorageStats.sizeInKb }} KB</template>
                        , {{ printStorageStats.count }} бр.
                    </v-col>
                    <v-col cols="12" md="2"></v-col>
                    <v-col cols="12" md="4">фрагменти за печат</v-col>
                </v-row>
            </v-card-text>
        </v-card>

        <current-user-debug-info />

        <v-card>
            <v-card-title>SPA настройки</v-card-title>
            <v-card-text>
                <pre>{{ vueSpecificProcessEnv }}</pre>
            </v-card-text>
        </v-card>
    </div>
</template>

<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator';

    import ValueLoading from '@/component/Common/ValueLoading.vue';
    import DateLabel from '@/component/Date/DateLabel.vue';
    import { config } from '@/Config';
    import { EnvironmentDto } from '@/model/DevTool/Diagnostic/EnvironmentDto';
    import { PrintStorageStatsDto } from '@/model/DevTool/Diagnostic/PrintStorageStatsDto';
    import { diagnosticService } from '@/service/DevTool/DiagnosticService';
    import { currentUser } from '@/store/User/CurrentUser';

    import CurrentUserDebugInfo from './CurrentUserDebugInfo.vue';

    @Component({
        components: { CurrentUserDebugInfo, DateLabel, ValueLoading }
    })
    export default class Diagnostic extends Vue {
        private apiEnvironmentIsLoading: boolean = false;
        private apiEnvironment: EnvironmentDto = new EnvironmentDto();
        private apiDbLocalTime: Date | string = '';
        private apiOutboundIp: string = '';
        private printEnvironmentIsLoading: boolean = false;
        private printEnvironment: EnvironmentDto = new EnvironmentDto();
        private printDbLocalTime: Date | string = '';
        private printOutboundIp: string = '';
        private printStorageStats: PrintStorageStatsDto = new PrintStorageStatsDto();
        private idSrvEnvironmentIsLoading: boolean = false;
        private idSrvEnvironment: EnvironmentDto = new EnvironmentDto();
        private idSrvDbLocalTime: Date | string = '';
        private idSrvOutboundIp: string = '';

        private loadEnvAndDb() {
            // Пускат се паралелно по 3-4 заявки към 3 сървърите.
            this.apiEnvironmentIsLoading = true;
            diagnosticService.getEnvironment(config.mainApiBaseUrl).then((value) => {
                this.apiEnvironment = value;
                this.apiEnvironmentIsLoading = false;
            });
            this.apiDbLocalTime = '';
            diagnosticService.getDbLocalTime(config.mainApiBaseUrl).then((value) => {
                this.apiDbLocalTime = value;
            });
            this.apiOutboundIp = '';
            diagnosticService.getOutboundIp(config.mainApiBaseUrl).then((value) => {
                this.apiOutboundIp = value;
            });

            this.printEnvironmentIsLoading = true;
            diagnosticService.getEnvironment(config.printApiBaseUrl).then((value) => {
                this.printEnvironment = value;
                this.printEnvironmentIsLoading = false;
            });
            this.printDbLocalTime = '';
            diagnosticService.getDbLocalTime(config.printApiBaseUrl).then((value) => {
                this.printDbLocalTime = value;
            });
            this.printOutboundIp = '';
            diagnosticService.getOutboundIp(config.printApiBaseUrl).then((value) => {
                this.printOutboundIp = value;
            });
            diagnosticService.getPrintStorageStats().then((value) => {
                this.printStorageStats = value;
            });

            this.idSrvEnvironmentIsLoading = true;
            diagnosticService.getEnvironment(config.identityServerBaseUrl).then((value) => {
                this.idSrvEnvironment = value;
                this.idSrvEnvironmentIsLoading = false;
            });
            this.idSrvDbLocalTime = '';
            diagnosticService.getDbLocalTime(config.identityServerBaseUrl).then((value) => {
                this.idSrvDbLocalTime = value;
            });
            this.idSrvOutboundIp = '';
            diagnosticService.getOutboundIp(config.identityServerBaseUrl).then((value) => {
                this.idSrvOutboundIp = value;
            });
        }

        private mounted() {
            this.loadEnvAndDb();
        }

        private get isAuthenticated() {
            return currentUser.isAuthenticated;
        }

        private get printApiBaseUrl() {
            return config.printApiBaseUrl;
        }

        private get identityServerBaseUrl() {
            return config.identityServerBaseUrl;
        }

        private get vueSpecificProcessEnv() {
            return JSON.stringify(process.env, null, ' ');
        }
    }
</script>
