<template>
    <div>
        <v-card>
            <v-card-title>Търсене на пациенти</v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="2">
                        <text-field
                            v-model="patientFilter.identifier"
                            label="Идентификатор:"
                            @keyup="identifierChange"
                            @keyup.enter="search"
                        />
                    </v-col>
                    <v-col cols="12" md="2">
                        <text-field v-model="patientFilter.firstName" label="Име:" @keyup.enter="search" />
                    </v-col>
                    <v-col cols="12" md="2">
                        <text-field v-model="patientFilter.middleName" label="Презиме:" @keyup.enter="search" />
                    </v-col>
                    <v-col cols="12" md="2">
                        <text-field v-model="patientFilter.lastName" label="Фамилия:" @keyup.enter="search" />
                    </v-col>

                    <v-col cols="6" md="2">
                        <date-picker v-model="patientFilter.birthDateFrom" label="Рождена дата от:" />
                    </v-col>

                    <v-col cols="6" md="2">
                        <date-picker v-model="patientFilter.birthDateTo" label="Рождена дата до:" />
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <v-expansion-panels>
            <v-expansion-panel>
                <v-expansion-panel-header> Допълнителни филтри </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-row>
                        <v-col cols="12" md="2">
                            <text-field v-model="patientFilter.phone" label="Телефон:" @keyup.enter="search" />
                        </v-col>
                        <v-col cols="12" md="2">
                            <text-field v-model="patientFilter.email" label="E-mail:" @keyup.enter="search" />
                        </v-col>
                        <v-col cols="12" md="2">
                            <text-field v-model="patientFilter.address" label="Адрес:" @keyup.enter="search" />
                        </v-col>
                        <v-col cols="12" md="2"
                            ><dropdown
                                v-model="patientFilter.patientStatus"
                                :items="patientStatuses"
                                label="Статус на пациента"
                            ></dropdown
                        ></v-col>
                    </v-row>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>

        <v-row class="py-3">
            <v-col cols="12" sm="6" md="3" xl="2">
                <btn action="Search" block @click="search()" />
            </v-col>
            <v-col cols="12" sm="6" md="3" xl="2">
                <btn action="Export" block @click="exportPatients()" />
            </v-col>
            <v-col cols="12" sm="6" md="3" xl="2">
                <btn action="New" block to="/Patient/PersonalData" />
            </v-col>
            <v-col v-if="specialtyCode === 'Gp'" cols="12" sm="6" md="3" xl="2">
                <btn action="Import" block @click="showImport = true" />
            </v-col>
            <!-- TODO: Да се реализира и server-side проверка на environment-а, срещу хакери в production. -->
            <v-col v-if="isDevOrTest" cols="12" sm="6" md="12" xl="6">
                <v-checkbox
                    v-model="searchWholeDb"
                    label="Търсене във всички практики (само в тестова среда)"
                    hide-details
                    dense
                ></v-checkbox>
            </v-col>
        </v-row>
        <v-row v-if="showImport">
            <v-col cols="4"> <FileInput :value="patientsImportFile.file" accept=".csv" @change="onFileChange" /></v-col>
            <v-col cols="4">
                <date-picker
                    v-model="patientsImportFile.deregistrationDate"
                    label="Дата на отписване:"
                    required
                ></date-picker
            ></v-col>
            <v-col cols="4">
                <v-row>
                    <v-col cols="12" sm="6" md="4" xl="3">
                        <btn
                            :disabled="!patientsImportFile.file.content?.length"
                            action="Upload"
                            @click="importPatients"
                        />
                    </v-col>

                    <v-col cols="12" sm="6" md="4" xl="3">
                        <btn
                            action="Cancel"
                            @click="
                                () => {
                                    showImport = false;
                                    onFileChange(null);
                                }
                            "
                        />
                    </v-col> </v-row
            ></v-col>
        </v-row>
        <div v-if="patients.length > 0">
            <data-table title="Пациенти" :headers="headers" :items="patients" :item-class="itemRowBackground">
                <template #rowActions="{ item }">
                    <btn icon action="Edit" :to="`/Patient/PersonalData/${item.patientId}`" />
                    <btn icon action="Visit" :to="`/Patient/VisitSummary/${item.patientId}`">Посещения</btn>
                </template>
            </data-table>
        </div>
        <v-row>
            <v-col cols="auto">Легенда:</v-col>
            <v-col cols="auto"><label class="py-1 style-deregistered">Отписан</label></v-col>
            <v-col cols="auto"><label class="py-1 style-uninsured">Неосигурен</label></v-col>
        </v-row>
        <v-row />
        <v-progress-linear v-if="showImport && $loading.isVisible" :value="progressPercentage" color="info" height="25">
            <template #default="{ value }">
                <strong>{{ value }}% Моля изчакайте да приключи импорта</strong>
            </template>
        </v-progress-linear>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import FileInput from '@/component/File/FileInput.vue';
    import { config } from '@/Config';
    import { FileCommand } from '@/model/File/FileCommand';
    import { PatientImportFileCommand } from '@/model/File/PatientImportFileCommand';
    import { PatientSearchCommand } from '@/model/Patient/PatientSearchCommand';
    import { PatientSearchResultDto } from '@/model/Patient/PatientSearchResultDto';
    import { patientService } from '@/service/Patient/PatientService';
    import { userContextCache } from '@/store/User/UserContextCache';

    @Component({
        components: { FileInput }
    })
    export default class PatientSearch extends Vue {
        @Prop({ required: true })
        private practiceId!: number;

        private patientsImportFile: PatientImportFileCommand = new PatientImportFileCommand();
        private patients: PatientSearchResultDto[] = [];
        private patientFilter: PatientSearchCommand = new PatientSearchCommand();
        private patientStatuses: string[] = ['Всички', 'Без отписани и починали', 'Само отписани', 'Само починали'];
        private searchWholeDb: boolean = false;
        private showImport: boolean = false;
        private progressPercentage: number = 0;

        private get isDevOrTest() {
            return config.isDevOrTest;
        }

        private get headers() {
            const headers = [
                { text: 'Идентификатор', value: 'identifier' },
                { text: 'Име', value: 'firstName' },
                { text: 'Презиме', value: 'middleName' },
                { text: 'Фамилия', value: 'lastName' },
                { text: 'Възраст', value: 'age' },
                { text: 'Статус', value: 'patientRegistrationTypeName' }
            ];
            if (this.searchWholeDb) {
                headers.push({ text: 'Практика №', value: 'practiceNumber' });
            }
            return headers;
        }

        private get specialtyCode() {
            return userContextCache.current?.specialtyCode;
        }

        private mounted() {
            const defaultStatus = 1;
            this.patientFilter.patientStatus = this.patientStatuses[defaultStatus];
            this.patientsImportFile.deregistrationDate = new Date();
        }

        private async identifierChange() {
            const threeLetters = 3;
            this.$emit('change');
            if (this.patientFilter.identifier.length >= threeLetters) {
                await this.search();
            }
        }

        private async search() {
            this.$loading.show();
            try {
                this.fillDoctorEmployee();
                this.patients = await patientService.search(this.patientFilter);
            } finally {
                this.$loading.hide();
            }
        }

        private async exportPatients() {
            this.$loading.show();
            try {
                this.fillDoctorEmployee();
                await patientService.exportPatient(this.patientFilter);
            } finally {
                this.$loading.hide();
            }
        }

        private fillDoctorEmployee() {
            if (this.searchWholeDb) {
                this.patientFilter.practiceId = null;
                this.patientFilter.doctorEmployeeSeqNumber = null;
                this.patientFilter.doctorSpecialtyCode = null;
            } else {
                this.patientFilter.practiceId = this.practiceId;
                this.patientFilter.doctorEmployeeSeqNumber = userContextCache.currentDoctorEmployeeSeqNumber ?? 0;
                this.patientFilter.doctorSpecialtyCode = userContextCache.current?.specialtyCode ?? null;
            }
        }

        private itemRowBackground(patient: PatientSearchResultDto) {
            if (patient?.deregistrationDate && patient.deregistrationDate.getTime() < new Date().getTime()) {
                return 'style-deregistered';
            } else if (!patient?.isInsured) {
                return 'style-uninsured';
            }
            return 'style-main';
        }

        private onFileChange(file: FileCommand | null) {
            if (file) {
                this.patientsImportFile.file = file;
            } else {
                this.patientsImportFile.file = new FileCommand();
            }
        }

        private async importPatients() {
            this.$loading.show();
            try {
                this.patientsImportFile.practiceId = this.practiceId;
                this.patientsImportFile.doctorEmployeeSeqNumber = userContextCache.current?.employeeSeqNumber ?? 0;
                await patientService.importPatients(this.patientsImportFile);
                this.progressPercentage = 100;
            } catch {
                this.patientsImportFile.file = new FileCommand();
            } finally {
                this.$loading.hide();
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '../../asset/Css/Register.css';
    /* По някаква причина долният импорт на по-новия формат css не работи. Трябва да търсим причината. */
    /*@import '@/asset/Css/Register.scss'; */
</style>
