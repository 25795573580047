import { NhisHospitalizationExtractionCommand } from '@/model/Nhis/Hospitalization/NhisHospitalizationExtractionCommand';
import { NhisHospitalizationExtractionDto } from '@/model/Nhis/Hospitalization/NhisHospitalizationExtractionDto';
import { NhisNewbornExtractionCommand } from '@/model/Nhis/Hospitalization/NhisNewbornExtractionCommand';
import { NhisNewbornResultDto } from '@/model/Nhis/Hospitalization/NhisNewbornResultDto';
import { httpService } from '@/service/Infrastructure/HttpService';
import { notifierService } from '@/service/Infrastructure/NotifierService';
import { nhisService } from '@/service/Nhis/NhisService';
import { signXmlService } from '@/service/Report/SignXmlService';
import { loadingState } from '@/store/LoadingState';
import { nhisKey } from '@/store/NhisNhifAccessTokenCache';

export const nhisHospitalizationService = {
    async getNewbornExtractionXml(requestData: NhisNewbornExtractionCommand): Promise<string> {
        const response = await httpService.post<string>(`/${nhisKey}/GetNewbornExtractionContentXml/`, requestData);
        console.log(response);
        return response?.data ?? '';
    },

    async postNewbornExtraction(xmlString: string): Promise<NhisNewbornResultDto | null> {
        loadingState.show();
        try {
            const signResponse = await signXmlService.signXml(xmlString);
            if (!signResponse.isError && signResponse.contents) {
                const response = await nhisService.postXmlToNhis<NhisNewbornResultDto>(
                    'PostNewbornExtraction',
                    signResponse.contents
                );
                if (response?.error?.length > 0) {
                    await notifierService.showWarning('Внимание', `${response.error}`);
                } else {
                    if (response?.warnings?.length > 0) {
                        nhisService.showWarnings(response.warnings);
                    }
                    return response;
                }
            }
            return null;
        } finally {
            loadingState.hide();
        }
    },

    async getHospitalizationExtractionXml(requestData: NhisHospitalizationExtractionCommand): Promise<string> {
        const response = await httpService.post<string>(`/${nhisKey}/GetHospitalizationExtractionXml/`, requestData);
        console.log(response);
        return response?.data ?? '';
    },

    async postHospitalizationExtraction(xmlString: string): Promise<NhisHospitalizationExtractionDto | null> {
        loadingState.show();
        try {
            const signResponse = await signXmlService.signXml(xmlString);
            if (!signResponse.isError && signResponse.contents) {
                const response = await nhisService.postXmlToNhis<NhisHospitalizationExtractionDto>(
                    'PostHospitalizationExtraction',
                    signResponse.contents
                );
                if (
                    response?.error?.length > 0 &&
                    response.error === 'Не е намерена хоспитализация по зададените критерии'
                ) {
                    await notifierService.showSuccess('Проверка за хоспитализация', `${response.error}`);
                } else if (response?.error?.length > 0) {
                    await notifierService.showWarning('Внимание', `${response.error}`);
                } else {
                    if (response?.warnings?.length > 0) {
                        nhisService.showWarnings(response.warnings);
                    }
                    return response;
                }
            }
            return null;
        } finally {
            loadingState.hide();
        }
    }
};
