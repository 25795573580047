import { plainToInstance } from 'class-transformer';

import { LkkDecisionAppealReasonDto } from '@/model/Nomenclature/LkkDecisionAppealReasonDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const lkkDecisionAppealReasonService = {
    async getLkkDecisionAppealReasons(): Promise<LkkDecisionAppealReasonDto[]> {
        const response = await httpService.get<LkkDecisionAppealReasonDto[]>(
            '/LkkDecisionAppealReason/GetLkkDecisionAppealReasons'
        );
        return plainToInstance(LkkDecisionAppealReasonDto, response?.data);
    }
};
