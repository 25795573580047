<template>
    <btn v-if="showBtn" action="Send" block @click="checkPrescriptions">Проверка на рецепти</btn>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { NhisPrescriptionGroupCheckDto } from '@/model/Nhis/Prescription/GroupCheck/NhisPrescriptionGroupCheckDto';
    import { NhisPrescriptionGroupCheckFilterDto } from '@/model/Nhis/Prescription/GroupCheck/NhisPrescriptionGroupCheckFilterDto';
    import { localServerDiagnosticService } from '@/service/LocalServer/LocalServerDiagnosticService';
    import { nhisPrescriptionGroupCheckService } from '@/service/Nhis/NhisPrescriptionGroupCheckService';
    import { signXmlService } from '@/service/Report/SignXmlService';
    import { userContextCache } from '@/store/User/UserContextCache';
    import { dateUtil } from '@/util/DateUtil';

    @Component({})
    export default class NhisPrescriptionGroupCheck extends Vue {
        @Prop()
        examId!: number;

        @Prop({ default: false })
        showBtn!: boolean;

        private filter: NhisPrescriptionGroupCheckFilterDto = new NhisPrescriptionGroupCheckFilterDto();
        public async checkPrescriptions() {
            this.$loading.show();
            try {
                if (await localServerDiagnosticService.checkIsLocalServerActive()) {
                    this.fillDate();
                    this.fillFilterData();
                    const response = await nhisPrescriptionGroupCheckService.getPrescriptionCheckXmls(this.filter);
                    console.log(response);
                    await this.processingBlanks(response);
                    if (response.prescriptionCommands.some((command) => command.xmlString === '')) {
                        this.$notifier.showError(
                            'Внимание',
                            'Проблем при подписване на заявка за масова проверка на рецепти. Моля изберете подпис или проверете рецептите!'
                        );
                        return;
                    }
                    await nhisPrescriptionGroupCheckService.postToNhis(response, 'PostPrescriptionGroupCheck');
                }
            } finally {
                this.$loading.hide();
            }
        }

        private fillFilterData() {
            this.filter.deputyDoctorUin = userContextCache.current?.deputyDoctorUin ?? null;
            this.filter.doctorUin = userContextCache.current?.doctorUin ?? '';
            this.filter.practiceId = userContextCache.currentPracticeId ?? 0;
            this.filter.doctorEmployeeSeqNumber = userContextCache.currentDoctorEmployeeSeqNumber ?? 0;
            this.filter.examId = this.examId;
        }

        private fillDate() {
            this.filter.startDate = dateUtil.today();
            this.filter.endDate = dateUtil.today();
        }

        private async processingBlanks(item: NhisPrescriptionGroupCheckDto) {
            for (let itemIndex = 0; itemIndex < Object.keys(item)?.length; itemIndex++) {
                const property = Object.keys(item)[itemIndex] as keyof typeof item;
                if (Array.isArray(item[property])) {
                    for (let formIndex = 0; formIndex < item[property]?.length; formIndex++) {
                        const form = item[property][formIndex];
                        if (typeof form !== 'string' && Object.prototype.hasOwnProperty.call(form, 'xmlString')) {
                            /* eslint-disable no-await-in-loop */
                            form.xmlString = await this.singXml(form.xmlString);
                        }
                    }
                }
            }
            console.log(item);
        }

        private async singXml(xml: string) {
            const response = await signXmlService.signXml(xml);
            if (response.isError) {
                this.$notifier.showWarning('', 'Възникна проблем при подписване на файла');
            } else {
                return response.contents ?? '';
            }
            return '';
        }
    }
</script>

<style scoped></style>
