const config = {
    latestVersion: '1.0.12.0',
    downloadUrl: 'https://download.kontrax.bg/hippocrates/download/Tools/LocalServer_HIPPOCRATES_1.0.12.exe',
    requiredVersion: '1.0.11.0'
};

// Иван: добавих service вместо само модел с константи с идеята някой ден този service да изтегля от някъде
// download url-а, последната версия на локалния сървър и евентуално необходимата версия за Хипократ.
// Така локалният сървър ще може да се обновява без качване на версия на Хипократ. Не е ясно доколко е полезно.
export const localServerUpdateService = {
    getConfig() {
        return config;
    }
};
