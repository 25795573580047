<template>
    <v-card v-if="securityCodeHasConfirmed">
        <v-card-title>Финансов отчет - дейтален</v-card-title>
        <v-card-text>
            <report-date-picker v-model="command" />
            <v-row>
                <v-col md="3" cols="6">
                    <dropdown
                        v-model="command.createdByEmployeeSeqNumber"
                        :items="employees"
                        item-value="employeeSeqNumber"
                        item-text="employeeTitle"
                        label="Обработил"
                        clearable
                    ></dropdown>
                </v-col>
                <v-col md="3" cols="6">
                    <dropdown
                        v-model="command.creditToEmployeeSeqNumber"
                        :items="employees"
                        item-value="employeeSeqNumber"
                        item-text="employeeTitle"
                        label="В полза на"
                        clearable
                    ></dropdown>
                </v-col>
                <v-col md="3" cols="6">
                    <dropdown
                        v-model="command.paymentMethodCode"
                        label="Начин на плащане"
                        :items="paymentMethods"
                        item-value="code"
                        clearable
                    ></dropdown>
                </v-col>
                <v-col md="3" cols="6">
                    <dropdown
                        v-model="command.tariffSeqNumber"
                        label="Тарифа"
                        :items="tariffs"
                        item-value="seqNumber"
                        clearable
                    ></dropdown>
                </v-col>
                <v-col md="3" cols="6">
                    <dropdown
                        v-model="command.isPaid"
                        label="Статус на плащане"
                        :items="paidTypes"
                        item-value="value"
                    ></dropdown>
                </v-col>
                <v-col md="3" cols="6">
                    <dropdown
                        v-model="command.groupBy"
                        label="Групиране по"
                        :items="groupByTypes"
                        item-value="code"
                        @input="getReport"
                    ></dropdown>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions>
            <v-col cols="2">
                <btn action="List" block @click="getReport">Генериране</btn>
            </v-col>
            <v-col cols="2">
                <btn action="Export" block @click="exportExcel" />
            </v-col>
        </v-card-actions>
        <data-table :headers="detailedTableHeaders" :items="detailedFinancialReport" :group-by="command.groupBy" />
        <v-row class="mt-2">
            <v-spacer />
            <v-col cols="9" class="text-right"> Сума (общо) </v-col>
            <v-col cols="3"> {{ totalPrice }} лв </v-col>
        </v-row>
    </v-card>
    <FinancialStatementsProtection
        v-else
        v-model="securityCodeHasConfirmed"
        :doctor-employee-seq-number="currentDoctorEmployeeSeqNumber"
        :practice-id="practiceId"
    />
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import ReportDatePicker from '@/component/Date/ReportDatePicker.vue';
    import FinancialStatementsProtection from '@/component/PinCode/PinCode.vue';
    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { EmployeeTitleDto } from '@/model/Employee/EmployeeTitleDto';
    import { PaymentMethodDto } from '@/model/Nomenclature/PaymentMethodDto';
    import { TariffDto } from '@/model/Practice/Service/TariffDto';
    import { IsPaidType, isPaidTypes } from '@/model/Query/Reception/IsPaidType';
    import { ServiceOrderDetailedFinancialReportDto } from '@/model/Query/Reception/ServiceOrderDetailedFinancialReportDto';
    import { ServiceOrderFinancialReportCommand } from '@/model/Query/Reception/ServiceOrderFinancialReportCommand';
    import { ServiceOrderGroupType, serviceOrderGroupTypes } from '@/model/Query/Reception/ServiceOrderGroupType';
    import { employeeService } from '@/service/Employee/EmployeeService';
    import { paymentMethodService } from '@/service/Nomenclature/PaymentMethodService';
    import { tariffService } from '@/service/Practice/Service/TariffService';
    import { serviceOrderDetailedReportService } from '@/service/Query/Reception/ServiceOrderDetailedReportService';
    import { loadingState } from '@/store/LoadingState';
    import { userContextCache } from '@/store/User/UserContextCache';

    @Component({
        components: { ReportDatePicker, FinancialStatementsProtection }
    })
    export default class ServiceOrderDetailedReport extends Vue {
        @Prop({ required: true })
        private practiceId!: number;

        private command: ServiceOrderFinancialReportCommand = new ServiceOrderFinancialReportCommand();
        private employees: EmployeeTitleDto[] = [];
        private paymentMethods: PaymentMethodDto[] = [];
        private tariffs: TariffDto[] = [];
        private paidTypes: IsPaidType[] = isPaidTypes;
        private groupByTypes: ServiceOrderGroupType[] = serviceOrderGroupTypes;
        private detailedFinancialReport: ServiceOrderDetailedFinancialReportDto[] = [];
        private securityCodeHasConfirmed: boolean = false;

        private get detailedTableHeaders(): IDataTableHeader[] {
            return [
                { text: 'Услуга', value: 'serviceName' },
                { text: 'Цена', value: 'servicePrice' },
                { text: 'Дата', value: 'createdOn' },
                { text: 'Идентификатор на пациент', value: 'patientIdentifier' },
                { text: 'Име на пациент', value: 'patientName' },
                { text: 'Лекар', value: 'creditToEmployeeName' },
                { text: 'Регистор', value: 'createdByEmployeeName' }
            ];
        }

        private async getReport() {
            await this.getDetailedFinancialReport();
        }

        private get totalPrice() {
            const fractionDigit = 2;
            const sum = this.detailedFinancialReport
                .reduce((accumulator: number, serviceOrder: ServiceOrderDetailedFinancialReportDto) => {
                    const servicePrice = parseFloat(serviceOrder.servicePrice?.toString() ?? '');
                    return accumulator + (isNaN(servicePrice) ? 0 : servicePrice);
                }, 0)
                .toFixed(fractionDigit);

            return sum;
        }

        private get currentDoctorEmployeeSeqNumber() {
            return userContextCache.currentDoctorEmployeeSeqNumber;
        }

        private async getEmployeeTitles() {
            this.employees = await employeeService.getEmployeeTitles(this.practiceId);
        }

        private async getPaymentMethod() {
            this.paymentMethods = await paymentMethodService.getPaymentMethods();
        }

        private async getTariffs() {
            this.tariffs = await tariffService.getPracticeTariffs(this.practiceId);
        }

        private async getDetailedFinancialReport() {
            this.$loading.show();
            try {
                this.detailedFinancialReport = [];
                this.fillPractice();
                this.detailedFinancialReport = await serviceOrderDetailedReportService.getDetailedFinancialReport(
                    this.command
                );
            } finally {
                this.$loading.hide();
            }
        }

        private async exportExcel() {
            loadingState.show();
            try {
                this.fillPractice();
                await serviceOrderDetailedReportService.exportDataDetailedReport(this.command);
            } finally {
                loadingState.hide();
            }
        }

        private fillPractice() {
            this.command.practiceId = this.practiceId;
        }

        private async load() {
            this.$loading.show();
            try {
                this.command.fillCurrentMonth();
                await this.getEmployeeTitles();
                await this.getPaymentMethod();
                await this.getTariffs();
                await this.getReport();
            } finally {
                this.$loading.hide();
            }
        }

        private async mounted() {
            await this.load();
        }
    }
</script>
