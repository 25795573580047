import Vue from 'vue';

import { EmployeeSettingDto } from '@/model/Employee/EmployeeSettingDto';
import { employeeService } from '@/service/Employee/EmployeeService';

const emptySettings = new EmployeeSettingDto();

class EmployeeSettingCache {
    private _settings: EmployeeSettingDto = emptySettings;

    public get settings() {
        return this._settings;
    }

    public async load(practiceId: number, employeeSeqNumber: number) {
        this._settings = await employeeService.getEmployeeSettings(practiceId, employeeSeqNumber);
    }

    public clear() {
        this._settings = emptySettings;
    }
}

export const employeeSettingCache = Vue.observable(new EmployeeSettingCache());
