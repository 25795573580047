<template>
    <v-row>
        <v-col>
            <data-table :headers="headers" title="Решение на ЛКК" :items="items">
                <template #actions>
                    <btn action="New" :to="`/Exam/LkkDecision/Create/${visitId}`" />
                </template>
                <template #rowActions="{ item }">
                    <btn icon action="Edit" :to="`/Exam/LkkDecision/Edit/${item.id}`" />
                    <btn icon action="Print" :to="`/Print/Exam.LkkDecision/${item.id}`" />
                </template>
            </data-table>
        </v-col>
    </v-row>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { LkkDecisionViewDto } from '@/model/Exam/LkkDecision/LkkDecisionViewDto';
    import { lkkDecisionService } from '@/service/Exam/LkkDecisionService';
    import { formatters } from '@/util/Formatters';

    @Component
    export default class LkkDecisionView extends Vue {
        @Prop()
        visitId!: number;

        private items: LkkDecisionViewDto[] = [];

        private get headers(): IDataTableHeader[] {
            return [
                { text: 'Решение ЛКК №', value: 'lkkDecisionNumber' },
                { text: 'Решение дата', value: 'lkkDecisionDate', formatter: formatters.date },
                { text: 'Заседание №', value: 'sessionNumber' },
                { text: 'Заседание дата', value: 'issueDate', formatter: formatters.date },
                { text: 'Жалба №', value: 'complaintNumber' },
                { text: 'Жалба дата', value: 'complaintDate', formatter: formatters.date },
                { text: 'Тип заинтересовано лице', value: 'appealerType' },
                { text: 'Жалбоподател', value: 'appealerName' },
                { text: 'Болничен лист №', value: 'sickSheetNumber' },
                { text: 'Заключение', value: 'conclusion' },
                { text: 'Статус', value: 'status' },
                { text: '', value: 'actionButtons', sortable: false }
            ];
        }

        private async loadItems() {
            this.$loading.show();
            try {
                this.items = await lkkDecisionService.getLkkDecisionsForVisit(this.visitId);
            } finally {
                this.$loading.hide();
            }
        }

        private async mounted() {
            await this.loadItems();
        }
    }
</script>
