<template>
    <div v-if="question">
        <v-row>
            <v-col md="6" cols="12" class="pr-5">
                <text-field v-model="question.name" :label="`Въпрос ${position}`" readonly />
            </v-col>
            <v-col v-if="isAnswerRequired && question.examAnswerTypeCode == ExamAnswerTypeCode.Bool" md="3" cols="6">
                <v-checkbox v-model="value.examAnswerBoolean" dense :label="`Отговор въпрос ${position}`" />
            </v-col>
            <v-col
                v-else-if="isAnswerRequired && question.examAnswerTypeCode == ExamAnswerTypeCode.Number"
                md="3"
                cols="6"
            >
                <text-field
                    v-model="value.examAnswerQuantity"
                    type="number"
                    :label="`Отговор въпрос ${position}`"
                    required
                />
            </v-col>
            <v-col
                v-else-if="isAnswerRequired && question.examAnswerTypeCode == ExamAnswerTypeCode.Text"
                md="3"
                cols="6"
            >
                <v-textarea
                    v-model="value.examAnswerText"
                    dense
                    :label="`Отговор въпрос ${position}`"
                    rows="2"
                    class="required"
                    :rules="[$validator.required]"
                />
            </v-col>
            <v-col
                v-else-if="isAnswerRequired && question.examAnswerTypeCode == ExamAnswerTypeCode.Date"
                md="3"
                cols="6"
            >
                <date-picker v-model="value.examAnswerDate" :label="`Отговор въпрос ${position}`" required />
            </v-col>
            <v-col v-else-if="isAnswerRequired">
                <dropdown
                    v-if="!question.isMultipleChoice"
                    v-model="singleNomValue"
                    :items="getAnswersByType(question.examAnswerTypeCode)"
                    item-value="code"
                    required
                />
                <multi-select-dropdown
                    v-else
                    v-model="value.examAnswerCodes"
                    item-value="code"
                    :items="getAnswersByType(question.examAnswerTypeCode)"
                    required
                />
            </v-col>
            <v-col md="3" cols="6">
                <dropdown
                    v-model="value.examAnswerSourceTypeCode"
                    :items="examAnswerSourceTypes"
                    item-value="code"
                    label="Източник на информацията"
                    required
                />
            </v-col>
        </v-row>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import TextField from '@/component/Common/TextField.vue';
    import DatePicker from '@/component/Date/DatePicker.vue';
    import MultiSelectDropdown from '@/component/Dropdown/MultiSelectDropdown.vue';
    import { ExamAnswerTypeCode } from '@/enum/Exam/ExamAnswerTypeCode';
    import { ExamAnswerSourceTypeCode } from '@/enum/Nomenclature/ExamAnswerSourceTypeCode';
    import { NhisQuestionDto } from '@/model/Nhis/Exam/PreventiveActivities/Questionnaire/NhisQuestionDto';
    import { ExamAnswerSourceTypeDto } from '@/model/Nomenclature/ExamAnswerSourceTypeDto';
    import { examAnswerSourceTypeService } from '@/service/Nomenclature/ExamAnswerSourceTypeService';
    import { examAnswerEnumCache } from '@/store/Nomenclature/ExamAnswerEnumCache';
    import { examQuestionCache } from '@/store/Nomenclature/ExamQuestionCache';

    @Component({
        computed: {
            ExamAnswerTypeCode() {
                return ExamAnswerTypeCode;
            }
        },
        components: { TextField, MultiSelectDropdown, DatePicker }
    })
    export default class NhisQuestion extends Vue {
        @Prop()
        value!: NhisQuestionDto;

        @Prop()
        position!: number;

        examAnswerSourceTypes: ExamAnswerSourceTypeDto[] = [];

        private get isAnswerRequired() {
            return (
                this.value.examAnswerSourceTypeCode !== ExamAnswerSourceTypeCode.Notapplicable &&
                this.value.examAnswerSourceTypeCode !== ExamAnswerSourceTypeCode.Refusal
            );
        }

        private get question() {
            return examQuestionCache.items.find((question) => question.nhisCode === this.value.examQuestionCode);
        }

        private getAnswersByType(typeCode: string) {
            return examAnswerEnumCache.items.filter((answer) => answer.examAnswerTypeCode === typeCode);
        }

        private get singleNomValue() {
            return this.value?.examAnswerCodes ? this.value.examAnswerCodes[0] : '';
        }

        private set singleNomValue(item: string) {
            this.value.examAnswerCodes = [];
            this.value.examAnswerCodes.push(item);
        }

        private async loadExamAnswerSourceTypes() {
            this.examAnswerSourceTypes = [];
            const response = await examAnswerSourceTypeService.getExamAnswerSourceTypes();
            if (response) {
                this.examAnswerSourceTypes = response.data;
            }
        }

        async mounted() {
            await this.loadExamAnswerSourceTypes();
        }
    }
</script>

<style scoped></style>
