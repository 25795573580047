<template>
    <v-dialog v-model="dialogIsVisible" max-width="50%">
        <template #activator="{ on }">
            <btn block action="List" v-on="on">Импорт на месечно известие</btn>
        </template>
        <v-form ref="form" v-model="isValid">
            <v-card>
                <main-title> Импорт на месечно известие </main-title>
                <v-card-text>
                    <v-col cols="12" md="6">
                        <file-input v-model="inputFile" required />
                    </v-col>
                </v-card-text>
                <v-card-actions>
                    <btn action="Save" @click="onSave" />
                    <v-spacer />
                    <btn action="Cancel" @click="onClose" />
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>
</template>

<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator';

    import Btn from '@/component/Common/Btn.vue';
    import MainTitle from '@/component/Common/MainTitle.vue';
    import FileInput from '@/component/File/FileInput.vue';
    import { FileCommand } from '@/model/File/FileCommand';
    import { MonthlyNotificationDto } from '@/model/Invoice/Pis/MonthlyNotificationDto';

    @Component({
        components: { FileInput, MainTitle, Btn }
    })
    export default class MonthlyNotificationImport extends Vue {
        dialogIsVisible: boolean = false;
        inputFile: FileCommand | null = new FileCommand();
        isValid: boolean = false;
        notification: MonthlyNotificationDto = new MonthlyNotificationDto();

        onClose() {
            this.dialogIsVisible = false;
            this.inputFile = new FileCommand();
            this.notification = new MonthlyNotificationDto();
        }

        decodeFileContent() {
            //От компонент inputFile-input content пропъртито се връща като number[], с помощта на TextDecoder преобразуваме до първоначалния стринг
            const decoder = new TextDecoder();
            return decoder.decode(new Uint8Array(this.inputFile?.content ?? []).buffer);
        }

        findNotificationActivity() {
            const pattern = /(?<=<nhif_type>)(?<temp1>.*)(?=<\/nhif_type>)/gu;
            const found = this.notification.fileData.match(pattern);
            if (found && (found?.length ?? 0) > 0) {
                [this.notification.activity] = found;
            }
        }

        findNotificationFileType() {
            const pattern = /(?<=<inv_type_code>)(?<temp1>.*)(?=<\/inv_type_code>)/gu;
            const found = this.notification.fileData.match(pattern);
            if (found && (found?.length ?? 0) > 0) {
                const [type] = found;
                switch (type) {
                    case 'DT_NOTIF':
                        this.notification.fileType = 'C_FDOC_DTNOTE';
                        break;
                    case 'CT_NOTIF':
                        this.notification.fileType = 'C_FDOC_CTNOTE';
                        break;
                    default:
                        this.notification.fileType = 'C_FDOC_INV';
                }
            }
        }

        createMonthlyNotification() {
            this.notification.fileId = 'Imported';
            this.notification.fileData = this.decodeFileContent();
            if (this.notification.fileData) {
                this.findNotificationActivity();
                this.findNotificationFileType();
            }

            return this.notification;
        }

        onSave() {
            (this.$refs.form as Vue & { validate: () => boolean }).validate();
            if (this.isValid) {
                const result = this.createMonthlyNotification();
                if (result?.fileData?.length > 0) {
                    this.$emit('monthly-notification-input', result);
                }
            }
            this.onClose();
        }
    }
</script>

<style scoped></style>
