import Vue from 'vue';

import { ExamAnswerEnumDto } from '@/model/Nomenclature/ExamAnswerEnumDto';
import { examAnswerEnumService } from '@/service/Nomenclature/ExamAnswerEnumService';
import { ArrayCacheBase } from '@/store/Nomenclature/ArrayCacheBase';

class ExamAnswerEnumCache extends ArrayCacheBase<ExamAnswerEnumDto> {
    protected async load() {
        const response = await examAnswerEnumService.getExamAnswerEnum();
        this._items = response.data;
    }
}

export const examAnswerEnumCache = Vue.observable(new ExamAnswerEnumCache());
