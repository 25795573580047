<template>
    <div>
        <data-table :items="externalVisits" :headers="tableHeaders" title="История на заболяванията">
            <template #actions>
                <btn action="New" :to="`/Patient/ExternalVisit/Create/${id}`" />
                <btn action="Download" class="ma-1" @click="fetchExternalVisit">Изтегляне на прегледи</btn>
            </template>
            <template #rowActions="{ item }">
                <btn icon action="Edit" :to="`/Patient/ExternalVisit/Edit/${item.patientId}/${item.seqNumber}`" />
                <btn v-if="item.visitId" icon action="Visit" :to="`/Exam/AmbulatorySheet/Edit/${item.visitId}`"
                    >Посещение</btn
                >
            </template>
        </data-table>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { ExternalVisitRowDto } from '@/model/Exam/ExternalVisit/ExternalVisitRowDto';
    import { NhisExamExtractionCommand } from '@/model/Nhis/Exam/NhisExamExtractionCommand';
    import { externalVisitService } from '@/service/Exam/ExternalVisitService';
    import { nhisExamService } from '@/service/Nhis/NhisExamService';
    import { currentPatientCache } from '@/store/CurrentPatientCache';
    import { currentVisitCache } from '@/store/CurrentVisitCache';
    import { userContextCache } from '@/store/User/UserContextCache';
    import { formatters } from '@/util/Formatters';

    @Component
    export default class PatientExternalVisit extends Vue {
        @Prop()
        public id!: number;

        isFormValid: boolean = false;
        externalVisits: ExternalVisitRowDto[] = [];

        get tableHeaders(): IDataTableHeader[] {
            return [
                { text: 'Дата', value: 'date', formatter: formatters.date },
                { text: 'Вид на документа', value: 'documentType' },
                { text: 'Номер на документа', value: 'documentNumber' },
                { text: 'Лечебно заведение', value: 'practiceName' },
                { text: 'Лекар', value: 'doctorName' },
                { text: 'Специалност/Отделение', value: 'specialty' },
                { text: 'АЛ', value: 'visitNumberAndDate' }
            ];
        }

        async getTableItems() {
            this.$loading.show();
            try {
                this.externalVisits = await externalVisitService.getExternalVisits(this.id, null);
            } finally {
                this.$loading.hide();
            }
        }

        async fetchExternalVisit() {
            this.$loading.show();
            try {
                const command = new NhisExamExtractionCommand();
                command.patientVersionKey = currentPatientCache.value?.key;
                command.deputyDoctorUin = userContextCache.current?.deputyDoctorUin ?? null;
                command.doctorUin = userContextCache.current?.doctorUin ?? '';
                command.startDateTime = new Date();
                await nhisExamService.postExamMonthExtraction(command, currentVisitCache.value.id, null);
            } finally {
                this.$loading.hide();
            }
        }

        async mounted() {
            if (this.id) {
                await this.getTableItems();
            }
        }
    }
</script>
