<template>
    <v-card>
        <v-card-text>
            <v-progress-linear :value="queryDto.percentageCovered" color="info" :buffer-value="100" height="25" striped>
                <template #default="{ value }">
                    <strong> {{ value }}% обхванати пациенти </strong>
                </template>
            </v-progress-linear>
            <data-table
                v-model="emailCommand.patients"
                :items="queryDto.patients"
                :headers="headers"
                :loading="$loading.isVisible"
                title="Подлежащи на профилактичен преглед"
                :item-class="itemRowBackground"
                :show-select="true"
                :custom-select="true"
                item-key="identifier"
            >
                <template #rowActions="{ item }">
                    <btn icon action="Visit" :to="`/Patient/VisitSummary/${item.patientId}`" />
                </template>
                <template #actions>
                    <date-picker
                        v-model="command.referenceDate"
                        label="Към дата"
                        class="mt-2"
                        required
                        :disabled="$loading.isVisible"
                        @input="getTableItems"
                    />
                    <v-spacer></v-spacer>
                    <btn :disabled="!emailCommand.patients.length" action="List" @click="dialogIsVisible = true"
                        >Изпрати напомнящ мейл</btn
                    >
                    <btn action="Message" class="ml-2" @click="sendViberNotification">Изпрати Viber съобщение</btn>
                    <btn action="Export" :disabled="!command.referenceDate" @click="exportExcel" />
                </template>
                <template #selectable="{ item, select, isSelected }">
                    <v-checkbox
                        v-if="item.email && !$loading.isVisible && allowSelectItem(item)"
                        :value="isSelected"
                        dense
                        @click="onSelectClick(item, isSelected, select)"
                    ></v-checkbox>
                </template>
            </data-table>
            <v-row>
                <v-col cols="auto">Легенда:</v-col>
                <v-col cols="auto"><label class="py-1 style-uninsured">Неосигурен</label></v-col>
            </v-row>
        </v-card-text>
        <v-dialog v-model="dialogIsVisible" max-width="50%" @keydown.esc="closeDeleteDialog">
            <RichText v-model="emailCommand.emailBody" />
            <v-card>
                <v-card-actions>
                    <btn action="Cancel" @click="closeDeleteDialog()">Отказ</btn>
                    <v-spacer />
                    <btn action="Send" @click="sendEmailNotification">Изпрати</btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import RichText from '@/component/RichText/RichText.vue';
    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { PreventionQueryCommand } from '@/model/Query/Exam/PreventionQueryCommand';
    import { PreventionQueryDto } from '@/model/Query/Exam/PreventionQueryDto';
    import { PreventionQueryPatientDto } from '@/model/Query/Exam/PreventionQueryPatientDto';
    import { PreventionQuerySendEmailCommand } from '@/model/Query/Exam/PreventionQuerySendEmailCommand';
    import { subjectToPreventiveExamService } from '@/service/Query/Exam/SubjectToPreventiveExamService';
    import { loadingState } from '@/store/LoadingState';
    import { userContextCache } from '@/store/User/UserContextCache';
    import { formatters } from '@/util/Formatters';

    @Component({
        components: { RichText }
    })
    export default class SubjectToPreventiveExam extends Vue {
        @Prop({ required: true })
        private practiceId!: number;

        @Prop({ required: true })
        private doctorEmployeeSeqNumber!: number;

        // eslint-disable-next-line @typescript-eslint/typedef
        private defaultEmailText = `<p>Здравейте,</p><br/>
            <p>Информираме ви, че трябва да ви се направи годишен профилактичен преглед от вашия ОПЛ.</p>
            <p>Моля обадете се на личния си лекар за запазване на час!</p>
            <br/>
            <p>С уважение,</p>
            <p>Д-р ${userContextCache.current?.doctorName ?? ''}</p>`;

        private dialogIsVisible: boolean = false;
        private command: PreventionQueryCommand = new PreventionQueryCommand();
        private emailCommand: PreventionQuerySendEmailCommand = new PreventionQuerySendEmailCommand();
        private queryDto: PreventionQueryDto = new PreventionQueryDto();
        private percentageCovered: number = 0;

        private get headers(): IDataTableHeader[] {
            return [
                { text: 'Идентификатор', value: 'identifier' },
                { text: 'Име', value: 'name' },
                { text: 'Дата на раждане', value: 'birthDate', formatter: formatters.date },
                { text: `Възраст към ${formatters.date(this.command.referenceDate)}`, value: 'ageAtReferenceDate' },
                { text: 'Адрес', value: 'address' },
                { text: 'Телефон', value: 'mobilePhone' },
                { text: 'Email', value: 'email' },
                { text: 'Последно известен на', value: 'preventiveExamNotifTime', formatter: formatters.date }
            ];
        }

        private fillDoctorEmployee() {
            this.command.practiceId = this.practiceId;
            this.command.doctorEmployeeSeqNumber = this.doctorEmployeeSeqNumber;
        }

        private async mounted() {
            await this.getTableItems();
            this.emailCommand.emailBody = this.defaultEmailText;
        }

        private async getTableItems() {
            if (this.command.referenceDate) {
                this.$loading.show();
                try {
                    this.queryDto = new PreventionQueryDto();
                    this.fillDoctorEmployee();
                    this.queryDto = await subjectToPreventiveExamService.getSubjectToPreventiveExams(this.command);
                } finally {
                    this.$loading.hide();
                }
            }
        }

        private async exportExcel() {
            if (this.command.referenceDate) {
                loadingState.show();
                try {
                    this.fillDoctorEmployee();
                    await subjectToPreventiveExamService.exportSubjectToPreventiveExams(this.command);
                } finally {
                    loadingState.hide();
                }
            }
        }

        private async sendEmailNotification() {
            this.$loading.show();
            try {
                await subjectToPreventiveExamService.sendEmailToPreventiveExams(this.emailCommand);
            } finally {
                this.emailCommand = new PreventionQuerySendEmailCommand();
                this.closeDeleteDialog();
                this.$loading.hide();
            }
        }

        private sendViberNotification() {
            //TODO
            console.log('Пратихме известие по Viber!');
        }

        private itemRowBackground(patient: PreventionQueryPatientDto) {
            if (!patient?.patientIsInsured) {
                return 'style-uninsured';
            }
            return 'style-main';
        }

        private onSelectClick(item: PreventionQueryPatientDto, isSelected: boolean, select: (value: boolean) => void) {
            select(!isSelected);
        }

        private closeDeleteDialog() {
            this.dialogIsVisible = false;
            this.emailCommand.emailBody = this.defaultEmailText;
        }

        private allowSelectItem(item: PreventionQueryPatientDto) {
            if (item.email !== '') {
                const date = new Date();
                //kМанов след разговор оставяме 7 дни срок м/у изпращане на повторен mail.
                // eslint-disable-next-line @typescript-eslint/no-magic-numbers
                date.setDate(new Date().getDate() - 7);

                return item.preventiveExamNotifTime === null || item.preventiveExamNotifTime! < date;
            }
            return false;
        }
    }
</script>

<style lang="scss" scoped>
    @import '../../../asset/Css/Register.css';
</style>
