<template>
    <div>
        <v-dialog v-model="dialogIsVisible" persistent>
            <template #activator="{ on }">
                <btn action="Search" icon v-on="on">Дейности по профилактика НЗИС</btn>
            </template>
            <v-card>
                <main-title> Извличане на списък с профилактични дейности за пациент </main-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="6" md="3">
                            <date-picker v-model="searchCommand.fromDate" label="Oт дата:" />
                        </v-col>
                        <v-col cols="6" md="3">
                            <date-picker v-model="searchCommand.toDate" label="Oт дата:" />
                        </v-col>
                    </v-row>
                    <data-table
                        v-if="tableItems && tableItems.length > 0"
                        title="Профилактични дейности за пациент"
                        :items="tableItems"
                        :headers="tableHeaders"
                        show-expand
                        item-key="preventiveExamType"
                        :child-headers="childrenTableHeaders"
                    >
                        <template #rowActions="{ item }">
                            <btn icon action="Fill" @click="getExamDetails(item)" />
                        </template>
                    </data-table>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <btn action="Cancel" text @click="closeExtractionDialog" />
                    <btn action="Download" text @click="search">Извличане</btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator';

    import Btn from '@/component/Common/Btn.vue';
    import MainTitle from '@/component/Common/MainTitle.vue';
    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { NhisPreventiveExamCommand } from '@/model/Nhis/LongtermCare/NhisPreventiveExamCommand';
    import { NhisPreventiveExamDto } from '@/model/Nhis/LongtermCare/NhisPreventiveExamDto';
    import { nhisLongtermCareService } from '@/service/Nhis/NhisLongtermCareService';
    import { currentPatientCache } from '@/store/CurrentPatientCache';
    import { userContextCache } from '@/store/User/UserContextCache';
    import { formatters } from '@/util/Formatters';

    @Component({
        components: { Btn, MainTitle }
    })
    export default class NhisPreventiveExam extends Vue {
        private dialogIsVisible: boolean = false;
        private searchCommand: NhisPreventiveExamCommand = new NhisPreventiveExamCommand();
        private tableItems: NhisPreventiveExamDto[] = [];

        private get tableHeaders(): IDataTableHeader[] {
            return [
                { text: 'Тип:', value: 'preventiveExamType' },
                { text: 'Категория:', value: 'preventiveExamCategory' },
                { text: 'Статус:', value: 'preventiveExamStatus' },
                { text: 'Активен от:', value: 'activeFrom', formatter: formatters.date },
                { text: 'Активен до:', value: 'activeTo', formatter: formatters.date },
                { text: '', value: 'actionButtons', sortable: false }
            ];
        }

        private get childrenTableHeaders(): IDataTableHeader[] {
            return [
                { text: 'Описание:', value: 'description' },
                { text: 'Код на дейност:', value: 'code' },
                { text: 'НРН:', value: 'nrn' },
                { text: 'Номенклатура:', value: 'nomenclature' },
                { text: 'Бележки:', value: 'note' }
            ];
        }

        private closeExtractionDialog() {
            this.dialogIsVisible = false;
            this.searchCommand = new NhisPreventiveExamCommand();
        }

        private getSearchRequestData() {
            this.searchCommand.doctorUin = userContextCache.current?.doctorUin ?? '';
            this.searchCommand.deputyDoctorUin = userContextCache.current?.deputyDoctorUin ?? null;
            this.searchCommand.patientId = currentPatientCache.value.key.patientId;

            return this.searchCommand;
        }

        private async search() {
            const response = await nhisLongtermCareService.postPreventiveExamSearch(this.getSearchRequestData());
            console.log(response);
            if (response?.exams) {
                this.tableItems = response.exams;
            }
        }

        private getExamDetails(exam: NhisPreventiveExamDto) {
            console.log(exam);
            this.$emit('nhis-medical-history-change', exam.nhisMedicalHistory);
            this.closeExtractionDialog();
        }
    }
</script>

<style scoped></style>
