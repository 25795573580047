import { Type } from 'class-transformer';

import { dateUtil } from '@/util/DateUtil';

import { ProcessMemoryDto } from './ProcessMemoryDto';

const lastAndDiffs = (array: number[], unit: string) => {
    if (!array || !array.length) {
        return '';
    }
    let result = `${array[0]} ${unit}: `;
    let index = 1;
    while (index < array.length) {
        result += ` ${array[index - 1] - array[index]}`;
        ++index;
    }
    return result;
};

export class EnvironmentDto {
    public localTimeString: string = '';
    public uploadTime: Date | null = null;
    public startTime: Date | null = null;
    public warmupSeconds: number = 0;
    public instanceTag: string = '';
    public scmUrl: string = '';
    public processId: number = 0;
    public upTimeHours: number = 0;
    public processorCount: number = 0;

    public cpuLoadP: number[] = [];
    public cpuOsLoadP: number[] = [];
    public poolSize: number[] = [];
    public workerThreads: number[] = [];
    public ioThreads: number[] = [];
    public runningThreads: number[] = [];
    public totalThreads: number[] = [];
    public pendingWork: number[] = [];
    public workPerSecond: number[] = [];
    public mbPerSecond: number[] = [];
    public committedMb: number[] = [];
    public heapMb: number[] = [];
    public fragmentedMb: number[] = [];
    public allocatedMb: number[] = [];

    public recentCollections: number[] = [];
    public gcPauseTimeP: number = 0;
    public promotedMb: number = 0;
    public usedMemoryP: number = 0;
    public hostTotalMb: number = 0;

    @Type(() => ProcessMemoryDto)
    public processMemory: ProcessMemoryDto = new ProcessMemoryDto();

    public sampleTime: Date = new Date();
    public loadedInMs: number = 0;

    public get processText() {
        return this.processId ? `${this.instanceTag} ${this.processId}` : '';
    }

    public get startupText() {
        return this.startTime
            ? `${dateUtil.formatBgDateTime(this.startTime)}${this.warmupSeconds ? ` за ${this.warmupSeconds} сек.` : ''}`
            : '';
    }

    public get cpuText() {
        return this.processorCount ? `${this.cpuLoadP.join(' ')} %` : '';
    }

    public get cpuOsText() {
        return this.processorCount ? `${this.cpuOsLoadP.join(' ')} %` : '';
    }

    public get poolSizeText() {
        return this.processorCount ? `${this.poolSize.join(' ')} бр.` : '';
    }

    public get workerThreadsText() {
        return this.processorCount ? `${this.workerThreads.join(' ')} бр.` : '';
    }

    public get ioThreadsText() {
        return this.processorCount ? `${this.ioThreads.join(' ')} бр.` : '';
    }

    public get threadsText() {
        return this.processorCount ? `${this.runningThreads.join(' ')} / ${this.totalThreads[0]} бр.` : '';
    }

    public get pendingWorkText() {
        return this.processorCount ? `${this.pendingWork.join(' ')} бр.` : '';
    }

    public get workPerSecondText() {
        return this.processorCount ? lastAndDiffs(this.workPerSecond, 'бр/s') : '';
    }

    public get mbPerSecondText() {
        return this.processorCount ? lastAndDiffs(this.mbPerSecond, 'MB/s') : '';
    }

    public get gcMemoryText() {
        return this.hostTotalMb
            ? `${this.committedMb}, ${this.heapMb}, ${this.fragmentedMb}, ${this.allocatedMb} MB`
            : null;
    }

    public get committedText() {
        return this.hostTotalMb ? lastAndDiffs(this.committedMb, 'MB') : null;
    }

    public get heapText() {
        return this.hostTotalMb ? lastAndDiffs(this.heapMb, 'MB') : null;
    }

    public get fragmentedText() {
        return this.hostTotalMb ? lastAndDiffs(this.fragmentedMb, 'MB') : null;
    }

    public get allocatedText() {
        return this.hostTotalMb ? lastAndDiffs(this.allocatedMb, 'MB') : null;
    }

    public get gcWorkText() {
        return this.hostTotalMb
            ? `${this.recentCollections.join(', ')} бр. ; ${this.gcPauseTimeP} % ; ${this.promotedMb} MB`
            : null;
    }

    public get hostText() {
        return this.processorCount
            ? `${this.processorCount} ; ${this.usedMemoryP} % от ${this.hostTotalMb} MB ; ${this.upTimeHours} ч.`
            : '';
    }

    public get loadTimeText() {
        return this.processId
            ? `${new Date(this.sampleTime).toLocaleTimeString('bg-BG')} за ${this.loadedInMs} ms`
            : '';
    }
}
