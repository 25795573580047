<template>
    <div v-if="securityCodeHasConfirmed">
        <v-card-title>Фактури</v-card-title>
        <v-card-text>
            <v-row>
                <v-col cols="12">
                    <report-date-picker v-model="filter" @input="loadItems" />
                </v-col>
                <v-col cols="3">
                    <text-field v-model="filter.invoiceNumber" label="Документ номер:" @blur="loadItems" />
                </v-col>
                <v-col cols="3">
                    <text-field v-model="filter.recipient" label="Получател:" @blur="loadItems" />
                </v-col>
                <v-col cols="3">
                    <text-field v-model="filter.eik" label="ЕИК:" @blur="loadItems" />
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <data-table :headers="headers" :items="items">
                        <template #actions>
                            <btn action="New" :to="`/Finance/Invoice/Create/${practiceId}`" />
                        </template>
                        <template #rowActions="{ item }">
                            <btn icon action="Edit" :to="`/Finance/Invoice/Edit/${item.id}`" />
                            <btn icon action="Print" :to="`/Print/Invoice.Invoice/Invoice/Original/${item.id}`" />
                            <btn
                                v-if="item.documentTypeCode == 'Invoice'"
                                icon
                                action="DebitNote"
                                :to="`/Finance/Invoice/DebitNote/${item.id}`"
                            />
                            <btn
                                v-if="item.documentTypeCode == 'Invoice'"
                                icon
                                action="CreditNote"
                                :to="`/Finance/Invoice/CreditNote/${item.id}`"
                            />
                            <btn action="Copy" icon @click="copyInvoice(item)"> Копиране на фактура </btn>
                        </template>
                    </data-table>
                </v-col>
            </v-row>
        </v-card-text>
    </div>
    <FinancialStatementsProtection
        v-else
        v-model="securityCodeHasConfirmed"
        :doctor-employee-seq-number="doctorEmployeeSeqNumber"
        :practice-id="practiceId"
    />
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import ReportDatePicker from '@/component/Date/ReportDatePicker.vue';
    import FinancialStatementsProtection from '@/component/PinCode/PinCode.vue';
    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { InvoiceDto } from '@/model/Finance/Invoice/InvoiceDto';
    import { InvoiceSearchCommand } from '@/model/Finance/Invoice/InvoiceSearchCommand';
    import { InvoiceViewDto } from '@/model/Finance/Invoice/InvoiceViewDto';
    import { invoiceService } from '@/service/Invoice/InvoiceService';
    import { userContextCache } from '@/store/User/UserContextCache';
    import { formatters } from '@/util/Formatters';

    @Component({
        components: { ReportDatePicker, FinancialStatementsProtection }
    })
    export default class InvoiceView extends Vue {
        @Prop()
        practiceId!: number;

        private filter: InvoiceSearchCommand = new InvoiceSearchCommand();
        private items: InvoiceViewDto[] = [];
        private document: InvoiceDto = new InvoiceDto();
        private securityCodeHasConfirmed: boolean = false;

        private get doctorEmployeeSeqNumber() {
            return userContextCache.currentDoctorEmployeeSeqNumber;
        }

        private async mounted() {
            this.filter.fillCurrentMonth();
            await this.loadItems();
        }

        private async loadItems() {
            this.$loading.show();
            try {
                this.items = [];
                this.filter.practiceId = this.practiceId;
                this.items = await invoiceService.getInvoicesByFilter(this.filter);
            } finally {
                this.$loading.hide();
            }
        }

        private async prepareDocument(document: InvoiceDto) {
            const invoiceNumberLength = 10;
            document.id = 0;
            document.invoiceDate = new Date();
            document.taxDate = new Date();
            document.paymentDate = new Date();
            document.number = String(await invoiceService.getInvoiceNextNumber(this.document.practiceId)).padStart(
                invoiceNumberLength,
                '0'
            );

            if (document.invoiceLines.length) {
                document.invoiceLines.forEach((element) => {
                    element.id = 0;
                });
            }

            return document;
        }

        private async copyInvoice(item: InvoiceViewDto) {
            this.$loading.show();
            try {
                this.document = await invoiceService.getById(item.id);
                await invoiceService.create(await this.prepareDocument(this.document));
                await this.loadItems();
            } finally {
                this.$loading.hide();
            }
        }

        private get headers(): IDataTableHeader[] {
            return [
                { text: 'Дата:', value: 'invoiceDate', formatter: formatters.date },
                { text: 'Номер:', value: 'invoiceNumber' },
                { text: 'Документ:', value: 'documentType' },
                { text: 'Сума:', value: 'totalPrice' },
                { text: 'Клиент:', value: 'recipient' },
                { text: 'ЕИК:', value: 'eik' },
                { text: '', value: 'actionButtons', sortable: false }
            ];
        }
    }
</script>

<style scoped></style>
