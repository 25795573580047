import { Route, RouteConfig } from 'vue-router';

import { DoctorDeputyKey } from '@/model/Employee/Deputy/DoctorDeputyKey';

const employeeChildRoutes: RouteConfig[] = [
    {
        path: 'List/:practiceId',
        props: {
            practice: (route: Route) => ({ practiceId: Number(route.params.practiceId) })
        },
        components: {
            practice: () => import('@/view/Employee/EmployeeList.vue')
        }
    },
    {
        path: 'Create/:practiceId/:backToDoctorEmployeeSeqNumber?',
        props: {
            practice: (route: Route) => ({
                practiceId: Number(route.params.practiceId),
                backToDoctorEmployeeSeqNumber: route.params.backToDoctorEmployeeSeqNumber
                    ? Number(route.params.backToDoctorEmployeeSeqNumber)
                    : null
            })
        },
        components: {
            practice: () => import('@/view/Employee/EmployeeCreate.vue')
        }
    },
    {
        path: 'Edit/:practiceId/:employeeSeqNumber',
        props: {
            practice: (route: Route) => ({
                practiceId: Number(route.params.practiceId),
                employeeSeqNumber: Number(route.params.employeeSeqNumber)
            })
        },
        components: {
            practice: () => import('@/view/Employee/EmployeeEdit.vue')
        }
    },
    {
        path: 'Settings/:practiceId/:employeeSeqNumber',
        props: {
            practice: (route: Route) => ({
                practiceId: Number(route.params.practiceId),
                employeeSeqNumber: Number(route.params.employeeSeqNumber)
            })
        },
        components: {
            practice: () => import('@/view/Employee/EmployeeSetting.vue')
        }
    },
    {
        path: 'Hired/Create/:practiceId/:employeeSeqNumber',
        props: {
            practice: (route: Route) => ({
                practiceId: Number(route.params.practiceId),
                doctorEmployeeSeqNumber: Number(route.params.employeeSeqNumber)
            })
        },
        components: {
            practice: () => import('@/view/Employee/Deputy/DoctorDeputyCreateHired.vue')
        }
    },
    {
        path: 'Deputy/Create/:practiceId/:employeeSeqNumber',
        props: {
            practice: (route: Route) => ({
                practiceId: Number(route.params.practiceId),
                doctorEmployeeSeqNumber: Number(route.params.employeeSeqNumber)
            })
        },
        components: {
            practice: () => import('@/view/Employee/Deputy/DoctorDeputyCreateDeputy.vue')
        }
    },
    {
        path: 'DoctorDeputy/Edit/:practiceId/:primaryDoctorEmployeeSeqNumber/:deputyDoctorId/:deputyDoctorIsHired',
        props: {
            practice: (route: Route) => {
                const doctorDeputyKey = new DoctorDeputyKey();
                doctorDeputyKey.practiceId = Number(route.params.practiceId);
                doctorDeputyKey.primaryDoctorEmployeeSeqNumber = Number(route.params.primaryDoctorEmployeeSeqNumber);
                doctorDeputyKey.deputyDoctorId = Number(route.params.deputyDoctorId);
                const isHired = route.params.deputyDoctorIsHired;
                doctorDeputyKey.deputyDoctorIsHired = Boolean(isHired) && isHired.toLowerCase() === 'true';
                return { doctorDeputyKey };
            }
        },
        components: {
            practice: () => import('@/view/Employee/Deputy/DoctorDeputyEdit.vue')
        }
    },
    {
        path: 'EmployeeUser/Create/:practiceId/:employeeSeqNumber',
        props: {
            practice: (route: Route) => ({
                practiceId: Number(route.params.practiceId),
                employeeSeqNumber: Number(route.params.employeeSeqNumber)
            })
        },
        components: {
            practice: () => import('@/view/Employee/EmployeeUserCreate.vue')
        }
    }
];

export const employeeRoutes: RouteConfig = {
    path: '/Employee',
    // Администрирането на служители е с отделен routing, но е в меню "Управление", затова води към PracticeMenu.vue.
    // В AllPracticeRoutes.ts и в PracticeMenu.vue е обяснено защо тук има prop practiceId.
    props: (route: Route) => ({
        practiceId: route.params.practiceId ? Number(route.params.practiceId) : null
    }),
    component: () => import('@/view/Practice/PracticeMenu.vue'),
    children: employeeChildRoutes
};
