<template>
    <v-card>
        <main-title>Настройки на служител</main-title>
        <v-card-actions>
            <btn action="Back" :to="backRoute" />
            <btn action="Save" @click="save" />
            <v-spacer></v-spacer>
        </v-card-actions>
        <v-card-text>
            <v-row>
                <v-col cols="4" md="2">
                    <v-checkbox
                        v-model="model.examScheduleIsPublic"
                        dense
                        label="Online записване на час"
                        :disabled="!isGlobalAdminOrPracticeOwner"
                    ></v-checkbox>
                </v-col>
                <v-col cols="4" md="2">
                    <v-checkbox
                        v-model="model.hospitalizationCheckEnabled"
                        dense
                        label="Автоматична проверка за хоспитализация"
                    ></v-checkbox>
                </v-col>
                <v-col cols="4" md="2">
                    <v-checkbox v-model="showForm" dense label="Използване на парола за финансови справки"></v-checkbox>
                </v-col>
            </v-row>
            <v-form ref="form" v-model="isFormValid">
                <template v-if="showForm && !hasPinCode">
                    <v-row>
                        <v-col cols="12" md="6">
                            <v-text-field
                                v-model="model.pinCode"
                                :type="showPassword ? 'text' : 'password'"
                                :rules="[$validator.maxLength(10), $validator.digitsOnly]"
                                :required="showForm"
                                label="Парола за финансови справки"
                                :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                                @click:append="showPassword = !showPassword"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field
                                type="password"
                                :rules="[
                                    $validator.maxLength(10),
                                    $validator.digitsOnly,
                                    $validator.confirmPassword(model.pinCode ?? '')
                                ]"
                                :required="showForm"
                                label="Повторете паролата за финансови справки"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </template>
                <template v-if="!showForm && hasPinCode">
                    <v-row>
                        <v-col cols="12" md="6">
                            <v-text-field
                                v-model="confPassword"
                                type="password"
                                :rules="[$validator.required]"
                                :required="!showForm && hasPinCode"
                                label="Въведете паролата за финансови справки"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </template>
            </v-form>
            <v-row>
                <v-col cols="12" md="6">
                    <h4>Всички бланки</h4>
                    <alert type="info" dense
                        >За да виждате всички бланки в меню прегледи трябва поле "Предпочитани бланки" да бъде празно
                        или в него да изберете и подредите всички бланки, които искате да виждате.</alert
                    >
                    <v-sheet elevation="10" class="py-4 px-1">
                        <v-chip-group v-model="model.visitMenuItems" multiple active-class="primary--text" column>
                            <v-chip
                                v-for="tag in allMenuItems"
                                :key="tag.code"
                                :value="tag"
                                filter
                                :disabled="isMenuItemSelected(tag)"
                            >
                                {{ tag.title }}
                            </v-chip>
                        </v-chip-group>
                    </v-sheet>
                </v-col>
                <v-col cols="12" md="6">
                    <h4>Предпочитани бланки</h4>
                    <alert type="info" dense
                        >В това поле може да посочите кои бланки искате да се визуализират в меню прегледи и в какъв
                        ред. Редът на показване е същият, както е показано отдолу.</alert
                    >
                    <v-sheet elevation="10" class="py-4 px-1">
                        <draggable v-model="model.visitMenuItems" draggable=".item">
                            <v-list-item v-for="element in model.visitMenuItems" :key="element.code" class="item">
                                <v-chip :value="element">
                                    {{ element.title }}
                                    <btn icon action="Minus" @click="removeSelected(element)">Изтриване</btn>
                                </v-chip>
                            </v-list-item>
                        </draggable>
                    </v-sheet>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script lang="ts">
    import { Component, Prop } from 'vue-property-decorator';
    import draggable from 'vuedraggable';

    import MainTitle from '@/component/Common/MainTitle.vue';
    import { EmployeeSettingDto } from '@/model/Employee/EmployeeSettingDto';
    import { ExamDocumentTypeDto } from '@/model/Exam/ExamDocumentTypeDto';
    import { employeeService } from '@/service/Employee/EmployeeService';
    import { visitMenuService } from '@/service/Exam/VisitMenuService';
    import { employeeSettingCache } from '@/store/EmployeeSettingCache';
    import { userContextCache } from '@/store/User/UserContextCache';
    import { arrayUtil } from '@/util/ArrayUtil';
    import { EmployeeBase } from '@/view/Employee/EmployeeBase';

    @Component({
        components: { MainTitle, draggable }
    })
    export default class EmployeeSetting extends EmployeeBase {
        @Prop({ required: true })
        private practiceId!: number;

        @Prop({ required: true })
        private employeeSeqNumber!: number;

        private hasPinCode: boolean = false;
        private confPassword: string = '';
        private showForm: boolean = false;
        private showPassword: boolean = false;
        private isFormValid: boolean = false;

        private model: EmployeeSettingDto = new EmployeeSettingDto();
        private allMenuItems: ExamDocumentTypeDto[] = [];

        private removeSelected(item: ExamDocumentTypeDto) {
            const index = this.model.visitMenuItems.indexOf(item);
            arrayUtil.removeAt(this.model.visitMenuItems, index);
        }

        private isMenuItemSelected(item: ExamDocumentTypeDto) {
            return this.model.visitMenuItems?.some((element) => element.code === item.code);
        }

        private setSelectedItemsPosition() {
            if (this.model.visitMenuItems?.length > 0) {
                this.model.visitMenuItems.forEach((item) => {
                    item.position = this.model.visitMenuItems.indexOf(item) + 1;
                });
            }
        }

        private async save() {
            this.$loading.show();
            try {
                (this.$refs.form as Vue & { validate: () => boolean }).validate();
                if (this.isFormValid) {
                    this.setSelectedItemsPosition();
                    if (!this.showForm && this.hasPinCode) {
                        const result = await employeeService.confirmPinCode(
                            this.model.practiceId,
                            this.model.employeeSeqNumber,
                            this.confPassword
                        );
                        if (result === true) {
                            this.model.pinCode = 'false';
                        }
                    }
                    await employeeService.updateEmployeeSettings(this.model);
                    this.$notifier.showSuccess('', 'Успешно записани промени');
                    await employeeSettingCache.load(this.practiceId, this.employeeSeqNumber);

                    this.model = await employeeService.getEmployeeSettings(this.practiceId, this.employeeSeqNumber);
                    if (this.model.pinCode === 'true') {
                        this.hasPinCode = true;
                        this.showForm = true;
                    } else {
                        this.hasPinCode = false;
                        this.showForm = false;
                    }
                }
            } finally {
                this.confPassword = '';
                this.$loading.hide();
            }
        }

        private get backRoute() {
            return `/Employee/List/${this.practiceId}`;
        }

        private async mounted() {
            this.allMenuItems = visitMenuService.getAvailableMenuItemsForSpecialty(
                userContextCache.current?.specialtyCode ?? null
            );
            if (this.employeeSeqNumber && this.practiceId) {
                this.model = await employeeService.getEmployeeSettings(this.practiceId, this.employeeSeqNumber);
                if (this.model.pinCode === 'true') {
                    this.hasPinCode = true;
                    this.showForm = true;
                }
            }
        }
    }
</script>

<style scoped></style>
