import Vue from 'vue';

import { ExamQuestionDto } from '@/model/Nomenclature/ExamQuestionDto';
import { examQuestionService } from '@/service/Nomenclature/ExamQuestionService';
import { ArrayCacheBase } from '@/store/Nomenclature/ArrayCacheBase';

class ExamQuestionCache extends ArrayCacheBase<ExamQuestionDto> {
    protected async load() {
        const response = await examQuestionService.getExamQuestions();
        this._items = response.data;
    }
}

export const examQuestionCache = Vue.observable(new ExamQuestionCache());
