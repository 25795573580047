export class LkkDecisionViewDto {
    public id: number = 0;
    public lkkDecisionNumber: number | null = null;
    public lkkDecisionDate: Date | null = new Date();
    public issueDate: Date = new Date();
    public sessionNumber: string | null = null;
    public complaintNumber: string | null = null;
    public complaintDate: Date | null = null;
    public appealerType: string | null = null;
    public appealerName: string | null = null;
    public lkkDecisionSickSheetDays: number | null = null;
    public lkkDecisionSickSheetStartDate: Date | null = null;
    public lkkDecisionSickSheetEndDate: Date | null = null;
    public sickSheetNumber: string | null = null;
    public status: string = '';
    public conclusion: string = '';
}
