import { plainToInstance } from 'class-transformer';

import { PreventionQueryCommand } from '@/model/Query/Exam/PreventionQueryCommand';
import { PreventionQueryDto } from '@/model/Query/Exam/PreventionQueryDto';
import { PreventionQuerySendEmailCommand } from '@/model/Query/Exam/PreventionQuerySendEmailCommand';
import { httpService } from '@/service/Infrastructure/HttpService';

export const subjectToPreventiveExamService = {
    async getSubjectToPrevention(command: PreventionQueryCommand): Promise<PreventionQueryDto> {
        const response = await httpService.get<PreventionQueryDto>('SubjectToPreventiveExam/GetSubjectToPrevention', {
            params: command
        });
        return plainToInstance(PreventionQueryDto, response.data);
    },

    exportSubjectToPrevention(command: PreventionQueryCommand): Promise<void> {
        return httpService.download('/SubjectToPreventiveExam/ExportExcelPrevention', { params: command });
    },

    async getSubjectToPreventiveExams(command: PreventionQueryCommand): Promise<PreventionQueryDto> {
        const response = await httpService.get<PreventionQueryDto>(
            'SubjectToPreventiveExam/GetSubjectToPreventiveExams',
            {
                params: command
            }
        );
        return plainToInstance(PreventionQueryDto, response.data);
    },

    exportSubjectToPreventiveExams(command: PreventionQueryCommand): Promise<void> {
        return httpService.download('/SubjectToPreventiveExam/ExportExcelPreventiveExams', { params: command });
    },

    async sendEmailToPreventiveExams(command: PreventionQuerySendEmailCommand) {
        await httpService.post('SubjectToPreventiveExam/SendEmailToPreventiveExams', command);
    }
};
