// Вариант 1: literals.
//type ActionType =
//    | 'Back'
//    ...
//    | 'Visit';

// Вариант 2: enum.
enum ActionCode {
    Account = 'Account',
    Back = 'Back',
    Cancel = 'Cancel',
    CheckDocument = 'CheckDocument',
    CheckList = 'CheckList',
    Close = 'Close',
    CreditNote = 'CreditNote',
    CloseDocument = 'CloseDocument',
    Copy = 'Copy',
    Delete = 'Delete',
    DebitNote = 'DebitNote',
    Details = 'Details',
    Download = 'Download',
    Edit = 'Edit',
    Email = 'Email',
    Export = 'Export',
    Fill = 'Fill',
    Help = 'Help',
    List = 'List',
    Memory = 'Memory',
    Message = 'Message',
    Minus = 'Minus',
    New = 'New',
    Next = 'Next',
    NewAccount = 'NewAccount',
    OpenDocument = 'OpenDocument',
    Pill = 'Pill',
    Primary = 'Primary',
    Print = 'Print',
    Pay = 'Pay',
    Refresh = 'Refresh',
    RefreshBatch = 'RefreshBatch',
    Regulation = 'Regulation',
    Save = 'Save',
    Search = 'Search',
    Secondary = 'Secondary',
    Select = 'Select',
    Send = 'Send',
    Sign = 'Sign',
    Swap = 'Swap',
    Success = 'Success',
    Upload = 'Upload',
    Visit = 'Visit',
    XmlReport = 'XmlReport',
    Location = 'Location',
    Show = 'Show',
    Import = 'Import'
}

interface IAction {
    icon: string;
    color: string;
    defaultTitle?: string;
}

const actions: Record<ActionCode, IAction> = {
    Account: {
        icon: 'mdi-account-outline',
        color: 'primary'
    },
    Back: {
        icon: 'mdi-chevron-left',
        color: 'secondary',
        defaultTitle: 'Назад'
    },
    Cancel: {
        icon: '',
        color: 'secondary',
        defaultTitle: 'Отказ'
    },
    CheckDocument: {
        icon: 'mdi-note-check-outline',
        color: 'info',
        defaultTitle: 'Проверка'
    },
    CheckList: {
        icon: 'mdi-playlist-check',
        color: 'info'
    },
    Close: {
        icon: 'mdi-close',
        color: 'error',
        defaultTitle: 'Затваряне'
    },
    CreditNote: {
        icon: 'mdi-receipt-text-minus',
        color: 'error',
        defaultTitle: 'Кредитно известие'
    },
    CloseDocument: {
        icon: 'mdi-file-lock-outline',
        color: 'primary',
        defaultTitle: 'Затваряне'
    },
    Copy: {
        icon: 'mdi-content-copy',
        color: 'primary',
        defaultTitle: 'Копиране'
    },
    Delete: {
        icon: 'mdi-delete',
        color: 'error',
        defaultTitle: 'Изтриване'
    },
    DebitNote: {
        icon: 'mdi-receipt-text-plus',
        color: 'primary',
        defaultTitle: 'Дебитно известие'
    },
    Details: {
        icon: 'mdi-dots-horizontal',
        color: 'info',
        defaultTitle: 'Детайли'
    },
    Download: {
        icon: 'mdi-download-outline',
        color: 'info',
        defaultTitle: 'Изтегляне'
    },
    Edit: {
        icon: 'mdi-pencil',
        color: 'primary',
        defaultTitle: 'Редактиране'
    },
    Email: {
        icon: 'mdi-email',
        color: 'white',
        defaultTitle: 'Съобщения'
    },
    Export: {
        icon: 'mdi-microsoft-excel',
        color: 'primary',
        defaultTitle: 'Експортиране'
    },
    Fill: {
        icon: 'mdi-content-save-move-outline',
        color: 'primary',
        defaultTitle: 'Попълване'
    },
    Help: {
        icon: 'mdi-help',
        color: 'info',
        defaultTitle: 'Помощ'
    },
    List: {
        icon: 'mdi-format-list-bulleted',
        color: 'primary',
        defaultTitle: 'Списък'
    },
    Memory: {
        icon: 'mdi-memory',
        color: 'primary',
        defaultTitle: 'Памет'
    },
    Message: {
        icon: 'mdi-message',
        color: 'primary',
        defaultTitle: 'Съобщение'
    },
    Minus: {
        icon: 'mdi-minus',
        color: 'error',
        defaultTitle: 'Минус'
    },
    New: {
        icon: 'mdi-plus',
        color: 'success',
        defaultTitle: 'Добавяне'
    },
    NewAccount: {
        icon: 'mdi-account-plus-outline',
        color: 'success',
        defaultTitle: 'Добавяне'
    },
    Next: {
        icon: 'mdi-chevron-right',
        color: 'primary',
        defaultTitle: 'Напред'
    },
    OpenDocument: {
        icon: 'mdi-file-lock-open-outline',
        color: 'primary',
        defaultTitle: 'Отваряне'
    },
    Pill: {
        icon: 'mdi-pill',
        color: 'primary',
        defaultTitle: 'Лекарство'
    },
    Primary: {
        icon: '',
        color: 'primary'
    },
    Print: {
        icon: 'mdi-printer',
        color: 'secondary',
        defaultTitle: 'Печат'
    },
    Pay: {
        icon: 'mdi-cash-check',
        color: 'secondary',
        defaultTitle: 'Плащане'
    },
    Refresh: {
        icon: 'mdi-refresh',
        color: 'secondary'
    },
    RefreshBatch: {
        icon: 'mdi-archive-refresh-outline',
        color: 'info',
        defaultTitle: 'Обновяване на партидни номера'
    },
    Regulation: {
        icon: 'mdi-file-outline',
        color: 'primary',
        defaultTitle: 'Наредба'
    },
    Save: {
        icon: 'mdi-content-save-outline',
        color: 'primary',
        defaultTitle: 'Запис'
    },
    Send: {
        icon: 'mdi-file-send-outline',
        color: 'info',
        defaultTitle: 'Изпращане'
    },
    Search: {
        icon: 'mdi-magnify',
        color: 'info',
        defaultTitle: 'Търсене'
    },
    Secondary: {
        icon: '',
        color: 'secondary'
    },
    Select: {
        icon: 'mdi-check',
        color: 'primary',
        defaultTitle: 'Избор'
    },
    Sign: {
        icon: 'mdi-draw',
        color: 'primary',
        defaultTitle: 'Подписване'
    },
    Success: {
        icon: '',
        color: 'success'
    },
    Swap: {
        icon: 'mdi-swap-vertical',
        color: 'info'
    },
    Upload: {
        icon: 'mdi-upload-outline',
        color: 'info',
        defaultTitle: 'Качване'
    },
    Visit: {
        icon: 'mdi-stethoscope',
        color: 'primary',
        defaultTitle: 'Посещения'
    },
    XmlReport: {
        icon: 'mdi-xml',
        color: 'primary',
        defaultTitle: 'Генериране на отчет'
    },
    Location: {
        icon: 'mdi-map-marker',
        color: 'primary',
        defaultTitle: 'Локация'
    },
    Show: {
        icon: 'mdi-eye',
        color: 'primary',
        defaultTitle: 'Показване'
    },
    Import: {
        icon: 'mdi-import',
        color: 'primary',
        defaultTitle: 'Импорт'
    }
};

// Към 2022-12 IAction не се използва директно.
//export { type IAction, ActionCode, actions };
export { ActionCode, actions };
