<template>
    <div>
        <v-menu
            v-model="pickerIsVisible"
            min-width="0"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
        >
            <template #activator="{ on }">
                <v-text-field
                    v-model="formattedText"
                    dense
                    :label="label"
                    :name="'dateTimePicker' + _uid"
                    :class="required ? 'pa-0 required' : 'pa-0'"
                    :readonly="readonly"
                    :disabled="disabled"
                    :hint="hint"
                    :persistent-hint="!!hint"
                    :rules="
                        required
                            ? [$validator.dateFormat(textPattern), $validator.required]
                            : [$validator.dateFormat(textPattern)]
                    "
                    @input="onTextChangedManually"
                    @keyup="$emit('keyup', ...arguments)"
                >
                    <template slot="append">
                        <v-icon :disabled="disabled" v-on="on" @click="timePickerIsVisible = false">
                            mdi-calendar
                        </v-icon>
                        <v-icon v-if="showTime" :disabled="disabled" v-on="on" @click="timePickerIsVisible = true">
                            mdi-clock
                        </v-icon>
                    </template>
                </v-text-field>
            </template>
            <v-time-picker
                v-if="timePickerIsVisible"
                v-model="time"
                dense
                :readonly="readonly"
                :disabled="disabled"
                :locale="locale"
                @click:minute="pickerIsVisible = false"
            ></v-time-picker>
            <v-date-picker
                v-else
                v-model="date"
                dense
                no-title
                :first-day-of-week="1"
                :readonly="readonly"
                :disabled="disabled"
                :min="minDate"
                :max="maxDate"
                :locale="locale"
                @input="pickerIsVisible = false"
            ></v-date-picker>
        </v-menu>
    </div>
</template>

<script lang="ts">
    import moment from 'moment';
    import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';

    import { dateUtil } from '@/util/DateUtil';

    const formatConfig = {
        datePartLength: 10,
        hourLength: 2,
        minuteLength: 2
    };

    @Component
    export default class DatePicker extends Vue {
        @Prop()
        private value!: Date;

        @Prop({ default: 'Дата' })
        private label!: string;

        @Prop()
        private showTime!: boolean;

        @Prop()
        private readonly!: boolean;

        @Prop()
        private disabled!: boolean;

        @Prop()
        private required!: boolean;

        @Prop({ default: 'bg' })
        private locale!: string;

        @Prop()
        private minDate!: string;

        @Prop()
        private maxDate!: string;

        @Prop()
        private hint!: string;

        private date: string | null = null;
        private time: string | null = null;
        private formattedText: string | null = null;
        private pickerIsVisible: boolean = false;
        private timePickerIsVisible: boolean = false;
        private ignoreDateChangeOnce: boolean = false;
        private ignoreTimeChangeOnce: boolean = false;

        private mounted() {
            this.splitDateTimeParts(this.value ? this.value.toISOString() : null);
            this.updateFormattedText();
        }

        private onTextChangedManually() {
            const momentObj = moment(this.formattedText, this.textPattern, true);
            if (momentObj.isValid()) {
                this.splitDateTimeParts(momentObj.toISOString(true));
                this.input();
            } else if (this.formattedText === '') {
                this.date = null;
                // Показват се указания за правилния формат на дата и час без да се нулира или emit-ва нищо.
            }
        }

        @Watch('date')
        private onDateChanged() {
            this.updateFormattedText();
            if (this.ignoreDateChangeOnce) {
                this.ignoreDateChangeOnce = false;
            } else {
                this.input();
            }
        }

        @Watch('time')
        private onTimeChanged() {
            this.updateFormattedText();
            if (this.ignoreTimeChangeOnce) {
                this.ignoreTimeChangeOnce = false;
            } else {
                this.input();
            }
        }

        @Watch('value')
        private onValueChanged(newValue: Date | null) {
            this.splitDateTimeParts(newValue ? newValue.toISOString() : null);
        }

        @Emit()
        private input() {
            return this.date ? this.datePlusTimeToMoment().toDate() : null;
        }

        private updateFormattedText(): void {
            this.formattedText = this.date ? this.datePlusTimeToMoment().format(this.textPattern) : null;
        }

        private get textPattern(): string {
            return this.showTime ? dateUtil.bgDateTimeFormat : dateUtil.bgDateFormat;
        }

        private datePlusTimeToMoment() {
            const momentObj = moment(this.date);
            if (this.showTime) {
                const timeSplitted = this.time?.split(':');
                if (timeSplitted && timeSplitted.length > 1) {
                    const [hours, minutes] = timeSplitted;
                    momentObj.add(hours, 'hours');
                    momentObj.add(minutes, 'minutes');
                }
            }
            return momentObj;
        }

        private splitDateTimeParts(isoString: string | null) {
            const newDate = isoString ? this.getDatePart(isoString) : null;
            if (newDate !== this.date) {
                this.ignoreDateChangeOnce = true;
                this.date = newDate;
            }
            const newTime = isoString ? this.getTimePart(isoString) : null;
            if (newTime !== this.time) {
                this.ignoreTimeChangeOnce = true;
                this.time = newTime;
            }
        }

        private getDatePart(isoString: string) {
            const isoStringWithTimeOffset = moment(isoString).toISOString(true);
            return isoStringWithTimeOffset.substr(0, formatConfig.datePartLength);
        }

        private getTimePart(isoString: string) {
            const dateObj = new Date(isoString);
            return `${dateObj.getHours().toString().padStart(formatConfig.hourLength, '0')}:${dateObj
                .getMinutes()
                .toString()
                .padStart(formatConfig.minuteLength, '0')}`;
        }
    }
</script>
