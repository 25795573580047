const base64ToArrayBuffer = function base64ToArrayBuffer(base64: string) {
    const binaryString = window.atob(base64);
    const binaryLen = binaryString.length;
    const bytes = new Uint8Array(binaryLen);
    for (let el = 0; el < binaryLen; el++) {
        bytes[el] = binaryString.charCodeAt(el);
    }
    return bytes;
};

const createFileOfType = function createFileOfType(base64: string, type: string) {
    const arrayBuffer = base64ToArrayBuffer(base64);
    const file = new Blob([arrayBuffer], { type });
    return URL.createObjectURL(file);
};

export const base64Service = {
    printFile(base64: string, type: string = 'application/pdf') {
        const fileUrl = createFileOfType(base64, type);
        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        iframe.src = fileUrl;
        iframe.onload = function onload() {
            iframe.contentWindow?.print();
        };
        document.body.appendChild(iframe);
    },

    downloadFile(base64: string, type: string = 'application/pdf', fileName: string = 'file') {
        const fileUrl = createFileOfType(base64, type);
        const link = document.createElement('a');
        link.href = fileUrl;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
    },

    openFile(base64: string, type: string = 'application/pdf') {
        const fileUrl = createFileOfType(base64, type);
        window.open(fileUrl);
    },

    async bufferToBase64(buffer: ArrayBuffer) {
        const base64url: string = await new Promise((item) => {
            const reader = new FileReader();
            reader.onload = () => item(reader.result as string);
            reader.readAsDataURL(new Blob([buffer]));
        });
        return base64url.slice(base64url.indexOf(',') + 1);
    },

    fromBase64ToString(encoded: string) {
        const bytes = base64ToArrayBuffer(encoded);
        const decoder = new TextDecoder();
        return decoder.decode(bytes);
    }
};
