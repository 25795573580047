<template>
    <ExamLayout
        :can-delete="document.id > 0 && !disableEdit"
        :can-print="document.id > 0"
        :can-save="!disableEdit"
        title="Медицинска бележка"
        @updated="updateEventHandler"
        @deleted="deleteEventHandler"
        @printed="printEventHandler"
        @printed-without-preview="printWithoutPreviewHandler"
    >
        <nhis-medical-note
            :id="documentId"
            v-model="document.nhisMedicalNote"
            :exam-id="examId"
            :disable="disableEdit"
        />
        <v-card :disabled="disableEdit">
            <main-title>Медицинска бележка</main-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="9">
                        <text-field v-model="document.practiceName" disabled label="МЗ - здр. заведение" />
                    </v-col>
                    <v-col cols="12" md="3">
                        <text-field v-model="document.practiceCity" disabled label="РЗОК" />
                    </v-col>
                    <v-spacer />
                </v-row>
                <v-row>
                    <v-col cols="9">
                        <text-field
                            :value="`${currentPatient.firstName} ${currentPatient.middleName ?? ''} ${
                                currentPatient.lastName
                            }`"
                            disabled
                            label="Име"
                        />
                    </v-col>
                    <v-col cols="3"><text-field v-model="currentPatient.age" disabled label="Възраст" /></v-col>
                </v-row>
                <v-row>
                    <v-col md="9"><text-field :value="currentPatientAddress" disabled label="Адрес" /></v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="3">
                        <date-picker v-model="document.issueDate" label="Дата на издаване" required />
                    </v-col>
                    <v-col cols="12" md="3">
                        <dropdown
                            v-model="document.reasonCode"
                            :items="medicalNoteReasons"
                            item-value="code"
                            label="Основание за издаване"
                            required
                            @input="onReasonCodeChange"
                        />
                    </v-col>
                    <v-col cols="6" md="3">
                        <ExamDiagnosisPicker v-model="examDiagnosis" required dense use-main-icd />
                    </v-col>
                    <v-col cols="12" md="3">
                        <date-picker v-model="document.startDate" label="Валидна от" required />
                    </v-col>
                    <v-col v-if="isSick" cols="12" md="3">
                        <date-picker v-model="document.endDate" label="Валидна до" required />
                    </v-col>
                    <v-col v-if="isSick" cols="12" md="3">
                        <dropdown
                            v-model="document.treatmentLocationCode"
                            :items="medicalNoteTreatmentLocations"
                            item-value="code"
                            label="Място на лечение"
                            required
                        />
                    </v-col>
                </v-row>
                <v-row class="align-end">
                    <v-col cols="12" md="6"><v-textarea v-model="document.diagnosis" label="Диагноза" /></v-col>
                    <v-col cols="6" md="3">
                        <dropdown
                            :items="necessityТemplates"
                            item-text="templateText"
                            item-value="templateText"
                            label="Избор от шаблон 'Нуждае се от'"
                            @input="onNecessityChanged"
                        ></dropdown>
                        <v-textarea v-model="document.necessity" label="Нуждае се от" />
                    </v-col>
                    <v-col cols="6" md="3">
                        <dropdown
                            :items="purposeТemplates"
                            item-text="templateText"
                            item-value="templateText"
                            label="Избор от шаблон 'Да послужи пред'"
                            @input="onPurposeChanged"
                        ></dropdown>
                        <v-textarea
                            v-model="document.purpose"
                            label="Да послужи пред"
                            :rules="[$validator.required]"
                            class="required"
                        />
                    </v-col>
                    <v-col cols="12" md="8">
                        <text-field v-model="currentVisit.doctorNames" disabled label="Лекар" />
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <UnsavedChanges ref="leaveDialog" />
    </ExamLayout>
</template>

<script lang="ts">
    import { Component, Prop, Ref, Vue } from 'vue-property-decorator';

    import Dropdown from '@/component/Dropdown/Dropdown.vue';
    import ExamDiagnosisPicker from '@/component/Exam/ExamDiagnosisPicker.vue';
    import ExamLayout from '@/component/Exam/ExamLayout.vue';
    import { MedicalNoteReasonCode } from '@/enum/Nomenclature/MedicalNoteReasonCode';
    import { MedicalNoteDto } from '@/model/Exam/Certificate/MedicalNoteDto';
    import { MedicalNoteReasonDto } from '@/model/Nomenclature/MedicalNoteReasonDto';
    import { MedicalNoteTreatmentLocationDto } from '@/model/Nomenclature/MedicalNoteTreatmentLocationDto';
    import { TemplateDto } from '@/model/Setting/Template/TemplateDto';
    import { TemplateFilterDto } from '@/model/Setting/Template/TemplateFilterDto';
    import { medicalNoteService } from '@/service/Exam/Certificate/MedicalNoteService';
    import { eventBus } from '@/service/Infrastructure/EventBus';
    import { medicalNoteReasonService } from '@/service/Nomenclature/MedicalNoteReasonService';
    import { medicalNoteTreatmentLocationService } from '@/service/Nomenclature/MedicalNoteTreatmentLocationService';
    import { printService } from '@/service/PrintService';
    import { templateService } from '@/service/Setting/TemplateService';
    import { currentPatientCache } from '@/store/CurrentPatientCache';
    import { currentVisitCache } from '@/store/CurrentVisitCache';
    import UnsavedChanges from '@/view/Exam/UnsavedChanges.vue';
    import NhisMedicalNote from '@/view/Nhis/MedicalNote/NhisMedicalNote.vue';

    @Component({
        components: { NhisMedicalNote, Dropdown, ExamDiagnosisPicker, UnsavedChanges, ExamLayout },
        async beforeRouteLeave(to, from, next) {
            await this.$data.leaveDialogRef.check(this.$data.initialValue, this.$data.document, next);
        }
    })
    export default class MedicalNote extends Vue {
        @Ref()
        readonly leaveDialog!: UnsavedChanges;

        @Prop()
        private examId!: number;

        @Prop()
        private documentId!: number;

        private document: MedicalNoteDto = new MedicalNoteDto();
        private initialValue: MedicalNoteDto = new MedicalNoteDto();
        private leaveDialogRef: UnsavedChanges = new UnsavedChanges();
        private necessityТemplates: TemplateDto[] = [];
        private purposeТemplates: TemplateDto[] = [];
        private medicalNoteReasons: MedicalNoteReasonDto[] = [];
        private medicalNoteTreatmentLocations: MedicalNoteTreatmentLocationDto[] = [];

        private async mounted() {
            await this.getTreatmentLocations();
            await this.getMedicalNoteReasons();
            await this.load();
            this.copyObject();
        }

        private get disableEdit() {
            return this.document?.nhisMedicalNote?.nrn?.length > 0;
        }

        private get isSick() {
            return this.document.reasonCode !== null && this.document.reasonCode !== MedicalNoteReasonCode.Healthy;
        }

        private get examDiagnosis() {
            return currentVisitCache.value.exam?.getExamDiagnosis(this.document.examDiagnosisId);
        }

        private set examDiagnosis(examDiagnosis) {
            this.document.diagnosis = examDiagnosis ? examDiagnosis.icdName : '';
            this.document.examDiagnosisId = examDiagnosis ? examDiagnosis.id : null;
        }

        private get currentPatient() {
            return currentPatientCache.value;
        }

        private get currentVisit() {
            return currentVisitCache.value;
        }

        private get currentPatientAddress() {
            return `${this.currentPatient.address.cityName} ${
                this.currentPatient.address.street !== null ? this.currentPatient.address.street : ' '
            } ${this.currentPatient.address.streetNumber !== null ? this.currentPatient.address.streetNumber : ' '} ${
                this.currentPatient.address.neighborhood !== null ? this.currentPatient.address.neighborhood : ' '
            }`;
        }

        private onReasonCodeChange(reason: string) {
            if (reason === MedicalNoteReasonCode.Healthy) {
                this.document.endDate = null;
                this.document.treatmentLocationCode = null;
            }
        }

        private async getTreatmentLocations() {
            this.medicalNoteTreatmentLocations =
                await medicalNoteTreatmentLocationService.getMedicalNoteTreatmentLocation();
        }

        private async getMedicalNoteReasons() {
            this.medicalNoteReasons = await medicalNoteReasonService.getMedicalNoteReasons();
        }

        private async load() {
            this.$loading.show();
            try {
                this.leaveDialogRef = this.leaveDialog;
                if (!this.documentId) {
                    const responseExam = await medicalNoteService.getMedicalNoteInfoByExamId(this.examId);
                    this.document = responseExam.data;
                    this.document.reasonCode = MedicalNoteReasonCode.Sick;
                } else {
                    const response = await medicalNoteService.getMedicalNote(this.documentId);
                    this.document = response.data;
                    await currentVisitCache.load(this.document.examId);
                }
            } finally {
                this.$loading.hide();
            }

            const templateFilterSymptoms: TemplateFilterDto = new TemplateFilterDto('', 'ItNeeds');
            const responseTemplatesSymp = await templateService.getFilteredTemplates(templateFilterSymptoms);
            this.necessityТemplates = responseTemplatesSymp.data;

            const templateFilterCondition: TemplateFilterDto = new TemplateFilterDto('', 'ToServe');
            const responseTemplatesCondition = await templateService.getFilteredTemplates(templateFilterCondition);
            this.purposeТemplates = responseTemplatesCondition.data;
        }

        private copyObject() {
            this.initialValue = this.$lodash.cloneDeep(this.document);
        }

        private async updateEventHandler() {
            this.$loading.show();
            try {
                let documentId = this.document.id;
                if (documentId === 0) {
                    const response = await medicalNoteService.createMedicalNote(this.document);
                    documentId = Number(response.data);
                    this.copyObject();
                    eventBus.$emit('create-referral-event', documentId, 'MedicalNote', 'Медицинска бележка');
                    this.$router.push(`/Exam/MedicalNote/Edit/${documentId}`);
                } else {
                    await medicalNoteService.updateMedicalNote(this.document);
                    eventBus.$emit('update-referral-event', 'Медицинска бележка');
                    this.copyObject();
                }
            } finally {
                this.$loading.hide();
            }
        }

        private async deleteEventHandler() {
            this.$loading.show();
            try {
                const documentId = this.document.id;
                await medicalNoteService.deleteMedicalNote(documentId);
                this.$router.push(`/Exam/MedicalNote/Create/${currentVisitCache.value.id}`);
                eventBus.$emit('delete-referral-event', documentId, 'MedicalNote', 'Медицинска бележка');
            } finally {
                this.$loading.hide();
            }
        }

        private printEventHandler() {
            if (this.documentId) {
                this.$router.push(`/Print/Exam.Certificate.MedicalNote/${this.documentId}`);
            }
        }

        private async printWithoutPreviewHandler() {
            this.$loading.show();
            try {
                const parameters: { [key: string]: string } = {};
                parameters.id = this.documentId.toString();
                const report = 'Exam.Certificate.MedicalNote';
                await printService.printReport(report, parameters);
            } finally {
                this.$loading.hide();
            }
        }

        private onNecessityChanged(value: TemplateDto) {
            this.document.necessity = `${this.document.necessity} ${value}`;
        }

        private onPurposeChanged(value: TemplateDto) {
            this.document.purpose = `${this.document.purpose} ${value}`;
        }
    }
</script>
