import { ExamDocumentTypeDto } from '@/model/Exam/ExamDocumentTypeDto';

export class EmployeeSettingDto {
    public practiceId: number = 0;
    public employeeSeqNumber: number = 0;
    public examScheduleIsPublic: boolean = false;
    public hospitalizationCheckEnabled: boolean = false;
    public pinCode: string | null = null;
    public visitMenuItems: ExamDocumentTypeDto[] = [];
}
