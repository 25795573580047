import { Route, RouteConfig } from 'vue-router';

export const optionalExamRoutes: RouteConfig[] = [
    {
        path: 'StagedEpicrisis/:visitId',
        props: {
            exam: (route: Route) => ({ visitId: Number(route.params.visitId) })
        },
        components: {
            exam: () => import('@/view/Exam/StagedEpicrisis/StagedEpicrisisView.vue')
        }
    },
    {
        // Вариант 1: 'StagedEpicrisis/CreateVisit' е от два сегмента и е в конфликт със 'StagedEpicrisis/:visitId'.
        // Вариант 2: 'StagedEpicrisis/Create/Visit' е от три сегмента, но някак си не е в конфликт със 'StagedEpicrisis/Create/:visitId'.
        // На същия принцип са 'SickSheet/Create/Visit', 'FileUpload/Create/Visit' и 'ExternalVisit/Create/Visit'.
        path: 'StagedEpicrisis/Create/Visit',
        components: {
            exam: () => import('@/view/Exam/StagedEpicrisis/StagedEpicrisis.vue')
        }
    },
    {
        path: 'StagedEpicrisis/Create/:visitId',
        props: {
            exam: (route: Route) => ({ visitId: Number(route.params.visitId) })
        },
        components: {
            exam: () => import('@/view/Exam/StagedEpicrisis/StagedEpicrisis.vue')
        }
    },
    {
        path: 'StagedEpicrisis/Edit/:documentId',
        props: {
            exam: (route: Route) => ({ documentId: Number(route.params.documentId) })
        },
        components: {
            exam: () => import('@/view/Exam/StagedEpicrisis/StagedEpicrisis.vue')
        }
    },
    {
        path: 'SickSheet/:visitId',
        props: {
            exam: (route: Route) => ({ visitId: Number(route.params.visitId) })
        },
        components: {
            exam: () => import('@/view/Exam/SickSheet/SickSheetMenuView.vue')
        }
    },
    {
        path: 'SickSheet/CreateVisit',
        components: {
            exam: () => import('@/view/Exam/SickSheet/SickSheet.vue')
        }
    },
    {
        path: 'SickSheet/Create/:visitId',
        props: {
            exam: (route: Route) => ({ visitId: Number(route.params.visitId) })
        },
        components: {
            exam: () => import('@/view/Exam/SickSheet/SickSheet.vue')
        }
    },
    {
        path: 'SickSheet/Edit/:documentId',
        props: {
            exam: (route: Route) => ({ documentId: Number(route.params.documentId) })
        },
        components: {
            exam: () => import('@/view/Exam/SickSheet/SickSheet.vue')
        }
    },
    {
        path: 'SickSheet/Copy/:copySickSheetId',
        props: {
            exam: (route: Route) => ({ copySickSheetId: Number(route.params.copySickSheetId) })
        },
        components: {
            exam: () => import('@/view/Exam/SickSheet/SickSheet.vue')
        }
    },
    {
        path: 'LkkDecision/:visitId',
        props: {
            exam: (route: Route) => ({ visitId: Number(route.params.visitId) })
        },
        components: {
            exam: () => import('@/view/Exam/LkkDecision/LkkDecisionView.vue')
        }
    },
    {
        path: 'LkkDecision/Create/:visitId',
        props: {
            exam: (route: Route) => ({ visitId: Number(route.params.visitId) })
        },
        components: {
            exam: () => import('@/view/Exam/LkkDecision/LkkDecision.vue')
        }
    },
    {
        path: 'LkkDecision/Edit/:documentId',
        props: {
            exam: (route: Route) => ({ documentId: Number(route.params.documentId) })
        },
        components: {
            exam: () => import('@/view/Exam/LkkDecision/LkkDecision.vue')
        }
    },
    {
        path: 'FileUpload/:visitId',
        props: {
            exam: (route: Route) => ({ visitId: Number(route.params.visitId) })
        },
        components: {
            exam: () => import('@/view/File/VisitFileView.vue')
        }
    },
    {
        path: 'FileUpload/Create/Visit',
        components: {
            exam: () => import('@/view/File/CreateVisitWithFiles.vue')
        }
    },
    {
        path: 'FileUpload/Create/:visitId',
        props: {
            exam: (route: Route) => ({ visitId: Number(route.params.visitId) })
        },
        components: {
            exam: () => import('@/view/File/VisitFileUpload.vue')
        }
    }
];
