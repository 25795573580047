<template>
    <v-dialog v-model="dialogIsVisible" persistent max-width="1000">
        <v-form ref="form" v-model="isFormValid">
            <v-card>
                <v-card-title>
                    <span class="text-h5">Детайлен избор на МКБ</span>
                </v-card-title>
                <v-card-text>
                    <dropdown
                        v-model="icd10AreaSelectedRange"
                        label="Група"
                        item-value="code"
                        :items="icd10Areas"
                        required
                    ></dropdown>
                    <dropdown
                        v-model="icd10SubAreaSelectedRange"
                        label="Подгрупа"
                        item-value="code"
                        :items="icd10SubAreas"
                        required
                    ></dropdown>
                    <dropdown
                        v-model="icd10SelectedValue"
                        label="МКБ"
                        item-value="code"
                        :items="icd10s"
                        required
                    ></dropdown>
                </v-card-text>
                <v-card-actions class="pb-5">
                    <btn action="Cancel" @click.native="onCancelClicked" />
                    <v-spacer></v-spacer>
                    <btn action="Select" @click.native="onPickClicked" />
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>
</template>

<script lang="ts">
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

    import { IcdDto } from '@/model/Nomenclature/IcdDto';
    import { icd10Service } from '@/service/Nomenclature/Icd10Service';
    import { icd10Cache } from '@/store/Nomenclature/Icd10Cache';

    @Component
    export default class IcdDetailedPickerDialog extends Vue {
        @Prop()
        private dialogIsVisible!: boolean;

        private isFormValid: boolean = false;

        //private icd10Areas: IcdDto[] = [];
        private icd10SubAreas: IcdDto[] = [];
        private icd10s: IcdDto[] = [];

        private icd10AreaSelectedRange: string = '';
        private icd10SubAreaSelectedRange: string = '';
        private icd10SelectedValue: string = '';

        private get icd10Areas() {
            return icd10Cache.items;
        }
        // Private async mounted() {
        //     this.icd10Areas = (await icd10Service.getAllIcd10Areas()).data;
        // }

        private onCancelClicked() {
            this.resetValues();
            this.$emit('cancel');
        }

        private onPickClicked() {
            (this.$refs.form as Vue & { validate: () => boolean }).validate();

            if (this.isFormValid) {
                this.$emit('icd10Picked', this.icd10SelectedValue);
                this.resetValues();
            }
        }

        private resetValues() {
            this.icd10SubAreas = [];
            this.icd10s = [];
            this.icd10AreaSelectedRange = '';
            this.icd10SubAreaSelectedRange = '';
            this.icd10SelectedValue = '';
        }

        @Watch('icd10AreaSelectedRange')
        private async onIcd10AreaSelectedRangeChanged() {
            this.icd10s = [];
            this.icd10SelectedValue = '';
            this.icd10SubAreaSelectedRange = '';
            this.icd10SubAreas = await icd10Service.getIcd10SubAreasByAreaRange(this.icd10AreaSelectedRange);
        }

        @Watch('icd10SubAreaSelectedRange')
        private async onIcd10SubAreaSelectedRangeChanged() {
            this.icd10SelectedValue = '';
            this.icd10s = await icd10Service.getIcd10sBySubAreaRange(this.icd10SubAreaSelectedRange);
        }
    }
</script>
