<template>
    <v-card>
        <v-card-title>Локален сървър</v-card-title>
        <v-card-actions>
            <btn action="Refresh" @click="checkLocalServer">Проверка</btn>
            <btn action="Download" :to="downloadUrl" />
        </v-card-actions>
        <v-card-title>Версия</v-card-title>
        <v-card-text>{{ version }}, {{ edition }}</v-card-text>
        <v-card-title>Възможности</v-card-title>
        <v-card-text>{{ capabilities }}</v-card-text>
        <v-card-title>Настройки</v-card-title>
        <v-card-text>
            <pre>{{ settings }}</pre>
        </v-card-text>
    </v-card>
</template>

<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator';

    import { localServerDiagnosticService } from '@/service/LocalServer/LocalServerDiagnosticService';
    import { localServerUpdateService } from '@/service/LocalServer/LocalServerUpdateService';

    @Component
    export default class LocalServer extends Vue {
        private downloadUrl: string = '';
        private version: string = '';
        private settings: object = new Object();
        private edition: string | null = null;
        private capabilities: string[] | null = null;

        private async checkLocalServer() {
            this.$loading.show();
            try {
                const isAlive = await localServerDiagnosticService.checkIsLocalServerActive();
                if (isAlive) {
                    this.version = await localServerDiagnosticService.getVersion();
                    this.edition = await localServerDiagnosticService.getEdition();
                    this.capabilities = await localServerDiagnosticService.getCapability();
                    this.settings = await localServerDiagnosticService.getSettings();
                }
            } finally {
                this.$loading.hide();
            }
        }

        private async mounted() {
            this.downloadUrl = localServerUpdateService.getConfig().downloadUrl;
            await this.checkLocalServer();
        }
    }
</script>
