export abstract class ArrayCacheBase<T> {
    private _loadingItems: T[] = [{ name: 'Зареждане...' } as T];
    private _loadErrorItems: T[] = [{ name: 'Грешка при зареждане' } as T];
    private _isLoaded: boolean = false;

    protected _items: T[] = [];

    public get items(): T[] {
        //this.loadOnceDelayed();
        this.loadOnce();
        return this._items;
    }

    protected async loadOnce(): Promise<void> {
        // Тест на hit-овете на кеша.
        //console.log(`${this.isLoaded ? 'Има' : 'Няма'} кеширани ${this.title}.`);
        if (!this._isLoaded && !this.isLoading && !this.isLoadError) {
            //debugger;
            this._items = this._loadingItems;
            try {
                // Наследеният load() трябва да присвои заредените елементи на this._items.
                await this.load();
                this._isLoaded = true;
            } catch {
                this._items = this._loadErrorItems;
            }
        }
    }

    public get isLoading(): boolean {
        return this._items === this._loadingItems;
    }

    public get isLoadError(): boolean {
        return this._items === this._loadErrorItems;
    }

    public get isLoaded(): boolean {
        return this._isLoaded;
    }

    // Тест с изкуствено забавено зареждане, за да се проследи реакцията на екрана, докато зарежда.
    private loadOnceDelayed() {
        // Тест на hit-овете на кеша.
        console.log('Бавно зареждане');
        //console.log(`${this.isLoaded ? 'Има' : 'Няма'} кеширани ${this.title}.`);
        if (!this._isLoaded && !this.isLoading && !this.isLoadError) {
            this._items = this._loadingItems;

            const delay = 5000;
            setTimeout(async () => {
                console.log('Отложено зареждане...');
                try {
                    await this.load();
                    this._isLoaded = true;
                } catch {
                    this._items = this._loadErrorItems;
                }
            }, delay);
        }
    }

    protected abstract load(): Promise<void>;
}
