import { plainToInstance } from 'class-transformer';

import { JsonWrapperDto } from '@/model/Common/JsonWrapperDto';
import { NhifProtocolDocumentCheckDto } from '@/model/Exam/DrugProtocol/NhifProtocolDocumentCheckDto';
import { NhifProtocolDocumentCheckGetJsonCommand } from '@/model/Exam/DrugProtocol/NhifProtocolDocumentCheckGetJsonCommand';
import { NhifProtocolDocumentCheckResultCommand } from '@/model/Exam/DrugProtocol/NhifProtocolDocumentCheckResultCommand';
import { NhifProtocolInfoDto } from '@/model/Exam/DrugProtocol/NhifProtocolInfoDto';
import { NhifProtocolUploadCommand } from '@/model/Exam/DrugProtocol/NhifProtocolUploadCommand';
import { ProtocolPreviewDto } from '@/model/Exam/DrugProtocol/Preview/ProtocolPreviewDto';
import { PrescriptionNhifDto } from '@/model/Exam/Prescription/PrescriptionNhifDto';
import { NhifCommand } from '@/model/Nhif/NhifCommand';
import { NhifProtocolFetchResultCommand } from '@/model/Nhif/Protocol/NhifProtocolFetchResultCommand';
import { NhifProtocolInfoCommand } from '@/model/Nhif/Protocol/NhifProtocolInfoCommand';
import { NhifProtocolSearchCommand } from '@/model/Nhif/Protocol/NhifProtocolSearchCommand';
import { NhifStatusProtocolDto } from '@/model/Nhif/Protocol/NhifStatusProtocolDto';
import { httpService } from '@/service/Infrastructure/HttpService';
import { nhifKey } from '@/store/NhisNhifAccessTokenCache';

export const nhifProtocolService = {
    async getStatusProtocolJson(command: NhifProtocolSearchCommand): Promise<JsonWrapperDto> {
        const response = await httpService.post<JsonWrapperDto>('/Nhif/GetStatusProtocolJson/', command);
        console.log(response.data);
        return response.data;
    },

    async getProtocolSearchResult(response: JsonWrapperDto): Promise<NhifStatusProtocolDto> {
        const result = await httpService.post<NhifStatusProtocolDto>(`${nhifKey}/GetProtocolSearchResult`, response);
        return result.data;
    },

    async setProtocolStatus(response: string): Promise<NhifProtocolInfoDto> {
        const result = await httpService.get<NhifProtocolInfoDto>(`${nhifKey}/SetProtocolStatus`, {
            params: {
                response
            }
        });
        return result.data;
    },

    async getProtocolFetchJson(command: NhifCommand): Promise<JsonWrapperDto> {
        const response = await httpService.post<JsonWrapperDto>('/Nhif/GetProtocolFetchJson/', command);
        console.log(response);
        return response.data;
    },

    async getProtocolFetchResult(
        jsonResponse: string,
        examId: number,
        formTypeCode: string
    ): Promise<PrescriptionNhifDto> {
        const command = new NhifProtocolFetchResultCommand(jsonResponse, examId, formTypeCode);
        const response = await httpService.post<PrescriptionNhifDto>(`${nhifKey}/GetProtocolFetchResult`, command);
        return plainToInstance(PrescriptionNhifDto, response?.data);
    },

    async getProtocolPreviewResult(
        jsonResponse: string,
        examId: number,
        formTypeCode: string
    ): Promise<ProtocolPreviewDto> {
        const command = new NhifProtocolFetchResultCommand(jsonResponse, examId, formTypeCode);
        const response = await httpService.post<ProtocolPreviewDto>(`${nhifKey}/GetProtocolPreviewResult`, command);
        return plainToInstance(ProtocolPreviewDto, response?.data);
    },

    async getProtocolPreviewByNrn(patientId: number, nrn: string): Promise<ProtocolPreviewDto> {
        const response = await httpService.get<ProtocolPreviewDto>(`${nhifKey}/GetProtocolPreviewByNrn`, {
            params: {
                patientId,
                nrn
            }
        });
        return plainToInstance(ProtocolPreviewDto, response?.data);
    },

    async getProtocolCreateJson(command: NhifCommand): Promise<JsonWrapperDto> {
        const response = await httpService.post<JsonWrapperDto>('/Nhif/GetCreateProtocolJson/', command);
        console.log(response.data);
        return response.data;
    },

    async getProtocolCreateResult(command: NhifProtocolInfoCommand): Promise<NhifProtocolInfoDto> {
        const response = await httpService.post<NhifProtocolInfoDto>(`${nhifKey}/GetCreateProtocolResult`, command);
        return plainToInstance(NhifProtocolInfoDto, response?.data);
    },

    async getProtocolCancelationJson(command: NhifCommand): Promise<JsonWrapperDto> {
        const response = await httpService.post<JsonWrapperDto>('/Nhif/GetCancelationProtocolJson/', command);
        console.log(response);
        return response.data;
    },

    async getProtocolCancelationResult(command: NhifProtocolInfoCommand): Promise<NhifProtocolInfoDto> {
        const response = await httpService.post<NhifProtocolInfoDto>(
            `${nhifKey}/GetCancelationProtocolResult`,
            command
        );
        return plainToInstance(NhifProtocolInfoDto, response?.data);
    },

    async getProtocolFinishJson(command: NhifCommand): Promise<JsonWrapperDto> {
        const response = await httpService.post<JsonWrapperDto>('/Nhif/GetFinishProtocolJson/', command);
        console.log(response);
        return response.data;
    },

    async getProtocolFinishResult(command: NhifProtocolInfoCommand): Promise<NhifProtocolInfoDto> {
        const response = await httpService.post<NhifProtocolInfoDto>(`${nhifKey}/GetFinishProtocolResult`, command);
        return plainToInstance(NhifProtocolInfoDto, response?.data);
    },

    async getProtocolUploadDocumentsJson(command: NhifProtocolUploadCommand): Promise<JsonWrapperDto> {
        const response = await httpService.post<JsonWrapperDto>(`${nhifKey}/GetProtocolUploadDocumentsJson`, command);
        console.log(response.data);
        return response.data;
    },

    async getProtocolDocumentCheckJson(command: NhifProtocolDocumentCheckGetJsonCommand): Promise<JsonWrapperDto> {
        const response = await httpService.post<JsonWrapperDto>('/Nhif/GetProtocolDocumentCheckJson/', command);
        console.log(response.data);
        return response.data;
    },

    async getProtocolDocumentCheckResult(jsonResponse: string): Promise<NhifProtocolDocumentCheckDto> {
        const response = await httpService.post<NhifProtocolDocumentCheckDto>(
            `${nhifKey}/GetProtocolDocumentCheckResult`,
            new NhifProtocolDocumentCheckResultCommand(jsonResponse)
        );
        return plainToInstance(NhifProtocolDocumentCheckDto, response?.data);
    }
};
