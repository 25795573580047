import Vue from 'vue';

import { RhifDto } from '@/model/Nomenclature/RhifDto';
import { rhifService } from '@/service/Nomenclature/RhifService';
import { ArrayCacheBase } from '@/store/Nomenclature/ArrayCacheBase';

const loadingItem = new RhifDto();
loadingItem.name = 'Зареждане...';

class RhifCache extends ArrayCacheBase<RhifDto> {
    protected async load() {
        const response = await rhifService.getRhifs();
        this._items = response.data;
    }

    public getRhifById(id: number) {
        return this.items.find((rh) => rh.id === id) ?? loadingItem;
    }
}

export const rhifCache = Vue.observable(new RhifCache());
