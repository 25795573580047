import { plainToInstance } from 'class-transformer';

import { LkkDecisionAppealerTypeDto } from '@/model/Nomenclature/LkkDecisionAppealerTypeDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const lkkDecisionAppealerTypeService = {
    async getLkkDecisionAppealerTypes(): Promise<LkkDecisionAppealerTypeDto[]> {
        const response = await httpService.get<LkkDecisionAppealerTypeDto[]>(
            '/LkkDecisionAppealerType/GetLkkDecisionAppealerTypes'
        );
        return plainToInstance(LkkDecisionAppealerTypeDto, response?.data);
    }
};
