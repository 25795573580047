<template>
    <v-row no-gutters class="no-margin-no-padding">
        <v-col cols="12">
            <v-row no-gutters>
                <v-col v-if="isExpertise" cols="12" md="4">
                    <dropdown
                        v-model="examDto.lkkCommitteeSeqNumber"
                        :items="lkkCommittees"
                        item-value="seqNumber"
                        required
                        label="ЛКК комисия"
                    />
                </v-col>
            </v-row>
        </v-col>
        <v-col cols="12" class="no-margin-no-padding">
            <v-row no-gutters class="no-margin-no-padding">
                <v-col cols="3" class="text-center">
                    <h5>Прегледи по лекар</h5>
                    <btn
                        :disabled="isLoadingPrevNextExam || !prevNextExamForDoctor.prevVisitId"
                        action="Back"
                        icon
                        @click="
                            goToVisit(prevNextExamForDoctor.prevPatientVersionKey, prevNextExamForDoctor.prevVisitId)
                        "
                    >
                        <!--                        <v-icon>mdi-chevron-left</v-icon> Предишен АЛ-->
                    </btn>
                    <btn
                        :disabled="isLoadingPrevNextExam || !prevNextExamForDoctor.nextVisitId"
                        action="Next"
                        icon
                        @click="
                            goToVisit(prevNextExamForDoctor.nextPatientVersionKey, prevNextExamForDoctor.nextVisitId)
                        "
                    >
                        <!--                        Следващ АЛ <v-icon>mdi-chevron-right</v-icon>-->
                    </btn>
                </v-col>
                <v-col cols="2" md="2">
                    <fund-selection v-model="examDto.fundCode" />
                </v-col>
                <v-col cols="2" md="1">
                    <text-field
                        v-model.number="examDto.ambulatorySheetNumber"
                        required
                        label="A.Л. номер"
                        type="number"
                    />
                </v-col>
                <v-col cols="4" md="3">
                    <date-picker
                        v-model="examDto.startDateTime"
                        show-time
                        required
                        :disabled="disableEdit"
                        label="Дата и час на началото на прегледа"
                        @input="onDatePickerInput"
                    ></date-picker>
                </v-col>
                <v-col cols="3" class="text-center">
                    <h5>Прегледи по пациент</h5>
                    <btn
                        :disabled="isLoadingPrevNextExam || !prevNextExamForPatient.prevVisitId"
                        action="Back"
                        icon
                        @click="
                            goToVisit(prevNextExamForPatient.prevPatientVersionKey, prevNextExamForPatient.prevVisitId)
                        "
                    >
                        <!--                        <v-icon>mdi-chevron-left</v-icon> Предишен АЛ-->
                    </btn>
                    <btn
                        :disabled="isLoadingPrevNextExam || !prevNextExamForPatient.nextVisitId"
                        action="Next"
                        icon
                        @click="
                            goToVisit(prevNextExamForPatient.nextPatientVersionKey, prevNextExamForPatient.nextVisitId)
                        "
                    >
                        <!--                        Следващ АЛ <v-icon>mdi-chevron-right</v-icon>-->
                    </btn>
                </v-col>
            </v-row>
        </v-col>
        <v-dialog v-model="patientFundsDialogIsVisible" persistent>
            <v-card>
                <v-card-title class="pa-0">
                    <v-toolbar flat>
                        <v-toolbar-title>Избор на фонд</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <btn icon action="Close" @click="closeDialog" />
                    </v-toolbar>
                </v-card-title>
                <patient-fund :patient-id="currentPatient.key.patientId" @change="updateFunds" />
            </v-card>
        </v-dialog>
        <v-row v-if="showFundWarning">
            <v-col>
                <alert type="warning"> Пациентът не е осигурен по избраният фонд. </alert>
            </v-col>
            <v-col>
                <btn icon action="Edit" @click="openPatientFundsDialog"></btn>
            </v-col>
        </v-row>
    </v-row>
</template>

<script lang="ts">
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

    import FundSelection from '@/component/Picker/FundPicker.vue';
    import { ReferralTypeCode } from '@/enum/Exam/ReferralTypeCode';
    import { ExamDto } from '@/model/Exam/ExamDto';
    import { PrevNextExamDto } from '@/model/Exam/ExamDuration/PrevNextExamDto';
    import { FundDto } from '@/model/Nomenclature/FundDto';
    import { PatientVersionKey } from '@/model/Patient/PatientVersionKey';
    import { LkkCommitteeDropdownDto } from '@/model/Practice/LkkCommittee/LkkCommitteeDropdownDto';
    import { examDurationService } from '@/service/Exam/ExamDurationService';
    import { fundService } from '@/service/Nomenclature/FundService';
    import { patientFundService } from '@/service/Patient/PatientFundService';
    import { lkkCommitteeService } from '@/service/Practice/LkkCommittee/LkkCommitteeService';
    import { currentPatientCache } from '@/store/CurrentPatientCache';
    import { currentVisitCache } from '@/store/CurrentVisitCache';
    import { userContextCache } from '@/store/User/UserContextCache';
    import { userSettingsState } from '@/store/User/UserSettingsState';
    import PatientFund from '@/view/Patient/PatientEdit/PatientFund.vue';

    @Component({
        components: { FundSelection, PatientFund }
    })
    export default class AmbulatorySheetNumberDatePaidBy extends Vue {
        @Prop({ required: true })
        private visitId!: number | null;

        @Prop()
        private examDto!: ExamDto;

        private lkkCommittees: LkkCommitteeDropdownDto[] = [];
        private funds: FundDto[] = [];
        private prevNextExamForDoctor: PrevNextExamDto = new PrevNextExamDto();
        private prevNextExamForPatient: PrevNextExamDto = new PrevNextExamDto();
        private isLoadingPrevNextExam: boolean = false;
        private showFundWarning: boolean = false;
        private patientFundsDialogIsVisible: boolean = false;

        private get disableEdit() {
            return this.examDto?.nhisExam?.nrn !== null && this.examDto?.nhisExam?.nrn !== '';
        }

        private get currentPatient() {
            return currentPatientCache.value;
        }

        // Използва се като сигнал, че данните за прегледа (съществуващ или нов) са заредени.
        // При редактиране се извиква два пъти - с id нула и по-късно с реалното id на преглед.
        @Watch('examDto', { immediate: true })
        private async onExamLoaded() {
            if (this.examDto.id) {
                // Редактиране преглед.
                await this.getPrevNextExamForDoctor();
                await this.getPrevNextExamForPatient();
                this.timeOverlapCheck(true);
            } else if (!this.visitId) {
                // Нов преглед.
                if (userSettingsState.userSettings.ui.useMinutesBetweenVisits) {
                    this.examDto.startDateTime = await examDurationService.getNextExamTimeForToday();
                }
                await this.getPrevNextExamForDoctor();
                await this.getPrevNextExamForPatient();
            }
        }

        private async onDatePickerInput() {
            await this.getPrevNextExamForDoctor();
            await this.getPrevNextExamForPatient();
            this.timeOverlapCheck(true);
        }

        @Watch('examDto.nhifVisitReasonCode')
        onNhifVisitReasonChange() {
            // Проверява времето само до следващия преглед. Времето до предишния преглед не зависи от този nhifVisitReasonCode.
            this.timeOverlapCheck(false);
        }

        private async getPrevNextExamForDoctor() {
            this.isLoadingPrevNextExam = true;
            try {
                this.prevNextExamForDoctor = await examDurationService.getPrevNextExamForDoctor(
                    this.examDto.startDateTime,
                    this.visitId
                );
            } finally {
                this.isLoadingPrevNextExam = false;
            }
        }

        private async getPrevNextExamForPatient() {
            this.isLoadingPrevNextExam = true;
            try {
                this.prevNextExamForPatient = await examDurationService.getPrevNextExamForPatient(
                    this.examDto.startDateTime,
                    currentPatientCache.value.key.patientId,
                    this.visitId
                );
            } finally {
                this.isLoadingPrevNextExam = false;
            }
        }

        private timeOverlapCheck(checkPrevExam: boolean) {
            if (userSettingsState.userSettings.ui.useMinutesBetweenVisits) {
                const { prevVisitStartDateTime, prevExamNhifVisitReasonCode, nextVisitStartDateTime } =
                    this.prevNextExamForDoctor;

                if (checkPrevExam && prevVisitStartDateTime && prevExamNhifVisitReasonCode) {
                    examDurationService.timeOverlapCheck(
                        prevVisitStartDateTime,
                        prevExamNhifVisitReasonCode,
                        this.examDto.startDateTime,
                        'предходния и текущия'
                    );
                }

                if (nextVisitStartDateTime) {
                    examDurationService.timeOverlapCheck(
                        this.examDto.startDateTime,
                        this.examDto.nhifVisitReasonCode,
                        nextVisitStartDateTime,
                        'текущия и следващия'
                    );
                }
            }
        }

        private async goToVisit(patientVersionKey: PatientVersionKey, visitId: number) {
            this.$loading.show();
            await currentPatientCache.loadSpecificVersion(patientVersionKey);
            await currentVisitCache.load(visitId);
            this.$router.push(`/Exam/AmbulatorySheet/Edit/${visitId}`);
        }

        private get isExpertise() {
            const expertiseNhisCode = '2';
            return (
                this.examDto?.incomingReferral?.referralTypeCode === ReferralTypeCode.Referral6 &&
                this.examDto?.incomingReferral?.referralSubtypeNhisCode === expertiseNhisCode
            );
        }

        private openPatientFundsDialog() {
            this.patientFundsDialogIsVisible = true;
        }

        private async updateFunds() {
            this.patientFundsDialogIsVisible = false;
            await this.checkPatientFunds();
        }

        private async closeDialog() {
            this.patientFundsDialogIsVisible = false;
            await this.checkPatientFunds();
        }

        async checkPatientFunds() {
            this.showFundWarning = false;
            if (this.funds.length === 0) {
                const fundsResponse = (await fundService.getFunds()).data;
                this.funds = fundsResponse ?? [];
            }

            const selectedFund = this.funds.find((fund) => fund.code === this.examDto.fundCode);
            if (selectedFund?.nhisCode === '3') {
                const response = await patientFundService.getPatientActiveFunds(
                    currentPatientCache.value.key.patientId
                );
                if (response) {
                    this.showFundWarning = !response.some((fund) => fund.fundCode === this.examDto.fundCode);
                }
            }
        }

        @Watch('isExpertise')
        async onLkkVisitChange(newVal: boolean) {
            if (newVal) {
                this.lkkCommittees = await lkkCommitteeService.getLkkCommitteesDropdowns(
                    userContextCache.currentPracticeId ?? 0,
                    userContextCache.currentDoctorEmployeeSeqNumber ?? 0
                );
            } else {
                this.examDto.lkkCommitteeSeqNumber = null;
            }
        }

        @Watch('examDto.fundCode')
        async onFundCodeChange() {
            await this.checkPatientFunds();
        }
    }
</script>
