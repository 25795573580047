<template>
    <data-table
        :headers="tableHeaders"
        :title="title"
        :items="items.lines"
        :child-headers="childHeaders"
        item-key="nhifCode"
        disable-pagination
        show-expand
    >
        <template #actions>
            <btn action="Export" @click="simpleExportExcel">Опростено експортиране</btn>
            <btn action="Export" @click="exportExcel" />
            &nbsp;&nbsp;
            <v-menu open-on-hover bottom offset-y pointer>
                <template #activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                        <btn action="Print" block />
                    </div>
                </template>

                <v-list>
                    <v-list-item @click="printEventHandler(false)">
                        <v-list-item-title class="pointer">Печат (опростен)</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="printEventHandler(true)">
                        <v-list-item-title class="pointer">Печат</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
            <!--<btn action="Print" @click="printEventHandler" />-->
            &nbsp;&nbsp;Всичко общо: {{ items.total }} лв
            <v-spacer />
            <btn icon action="Close" @click="$emit('close-dialog')" />
        </template>
    </data-table>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { ExamClaimCommand } from '@/model/Report/Pis/GpExamClaim/ExamClaimCommand';
    import { ExamClaimReportDto } from '@/model/Report/Pis/GpExamClaim/ExamClaimReportDto';
    import { examClaimReportService } from '@/service/Query/Exam/ExamClaimReportService';
    import { loadingState } from '@/store/LoadingState';
    import { dateUtil } from '@/util/DateUtil';
    import { formatters } from '@/util/Formatters';

    @Component
    export default class GpExamClaimReport extends Vue {
        @Prop()
        items!: ExamClaimReportDto;

        @Prop({ default: '' })
        title!: string;

        @Prop()
        filter!: ExamClaimCommand;

        @Prop({ default: false })
        showDoctorName!: boolean;

        private get tableHeaders(): IDataTableHeader[] {
            return [
                { text: 'НЗОК код', value: 'nhifCode' },
                { text: 'Описание', value: 'description' },
                { text: 'Брой', value: 'count' },
                { text: 'Ед. цена', value: 'price' },
                { text: 'Общо', value: 'summary' }
            ];
        }

        private get childHeaders(): IDataTableHeader[] {
            const headers = [
                { text: 'Номер на АЛ', value: 'number' },
                { text: 'НРН на АЛ', value: 'nrn' },
                { text: 'Идентификатор на пациента', value: 'patientIdentifier' },
                { text: 'Имена на пациента', value: 'patientName' },
                { text: 'Дата', value: 'startDate', formatter: formatters.date }
            ];
            if (this.showDoctorName) {
                headers.push({ text: 'Лекар', value: 'doctorName' });
            }
            return headers;
        }

        private printEventHandler(isExtended: boolean) {
            if (this.filter.practiceId) {
                const parameters: { [key: string]: string | null } = {};
                parameters.practiceId = this.filter.practiceId.toString();
                parameters.doctorEmployeeSeqNumber = this.filter.doctorEmployeeSeqNumber?.toString() || null;
                parameters.deputyDoctorIdAndRole = this.filter.deputyDoctorIdAndRole?.toString() || null;
                parameters.reportByPractice = this.filter.reportByPractice.toString();
                parameters.startDate = dateUtil.formatBgDate(this.filter.startDate);
                parameters.endDate = dateUtil.formatBgDate(this.filter.endDate);
                parameters.isExtended = isExtended.toString();
                this.$router.push(
                    `/Print/Query.Exam.ExamClaimReport/ExamClaimReport/${parameters.practiceId}/${parameters.doctorEmployeeSeqNumber}/${parameters.reportByPractice}/${parameters.deputyDoctorIdAndRole}/${parameters.isExtended}/${parameters.startDate}/${parameters.endDate}`
                );
            }
        }

        private async exportExcel() {
            loadingState.show();
            try {
                await examClaimReportService.exportExcelExamClaimReport(this.filter);
            } finally {
                loadingState.hide();
            }
        }

        private async simpleExportExcel() {
            loadingState.show();
            try {
                await examClaimReportService.simpleExportExcelExamClaimReport(this.filter);
            } finally {
                loadingState.hide();
            }
        }
    }
</script>
