import { plainToInstance } from 'class-transformer';

import { LkkDecisionConclusionDto } from '@/model/Nomenclature/LkkDecisionConclusionDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const lkkDecisionConclusionService = {
    async getLkkDecisionConclusions(): Promise<LkkDecisionConclusionDto[]> {
        const response = await httpService.get<LkkDecisionConclusionDto[]>(
            '/LkkDecisionConclusion/GetLkkDecisionConclusions'
        );
        return plainToInstance(LkkDecisionConclusionDto, response?.data);
    }
};
