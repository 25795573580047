<template>
    <data-table :headers="headers" :items="items" :loading="$loading.isVisible" title="Неизпратени бланки към НЗИС">
        <template #actions>
            <report-date-picker v-model="filter" :disabled="$loading.isVisible" @input="getTableItems" />
        </template>
        <template #rowActions="{ item }">
            <btn icon action="Edit" :to="getEditRoute(item)" />
            <btn icon action="Send" @click="postAction(item)" />
        </template>
    </data-table>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import ReportDatePicker from '@/component/Date/ReportDatePicker.vue';
    import { PrescriptionFormTypeCode } from '@/enum/Exam/PrescriptionFormTypeCode';
    import { NhisExamStatusCode } from '@/enum/Nhis/NhisExamStatusCode';
    import { ExamDocumentTypeCode } from '@/enum/VisitList/ExamDocumentTypeCode';
    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { PrescriptionKey } from '@/model/Exam/Prescription/PrescriptionKey';
    import { UnpostedFormDto } from '@/model/Query/Nhis/UnpostedFormDto';
    import { UnpostedImmunizationDto } from '@/model/Query/Nhis/UnpostedImmunizationDto';
    import { ReportIntervalFilterDto } from '@/model/Report/ReportIntervalFilterDto';
    import { nhisExamService } from '@/service/Nhis/NhisExamService';
    import { nhisImmunizationService } from '@/service/Nhis/NhisImmunizationService';
    import { nhisMedicalNoteService } from '@/service/Nhis/NhisMedicalNoteService';
    import { nhisPrescriptionService } from '@/service/Nhis/NhisPrescriptionService';
    import { nhisReferralService } from '@/service/Nhis/NhisReferralService';
    import { nhisService } from '@/service/Nhis/NhisService';
    import { unpostedFormService } from '@/service/Nhis/UnpostedFormService';
    import { formatters } from '@/util/Formatters';

    const formTypes = {
        referral: 'Referral',
        ambulatorySheet: 'AmbulatorySheet',
        prescription: 'Prescription',
        eimmunization: 'EImmunization',
        medicalNote: 'MedicalNote'
    };

    const resultType = {
        sent: 'изпратен',
        closed: 'затворен',
        edited: 'редактиран'
    };

    @Component({
        components: { ReportDatePicker }
    })
    export default class UnpostedForms extends Vue {
        @Prop({ required: true })
        private practiceId!: number;

        @Prop({ required: true })
        private doctorEmployeeSeqNumber!: number;

        private items: UnpostedFormDto[] = [];
        private filter: ReportIntervalFilterDto = new ReportIntervalFilterDto();

        private headers: IDataTableHeader[] = [
            { text: 'Вид', value: 'formName' },
            { text: 'АЛ номер', value: 'ambulatorySheetNumber' },
            { text: 'АЛ НРН', value: 'ambulatorySheetNrn' },
            { text: 'Идентификатор', value: 'identifier' },
            { text: 'Пациент', value: 'patient' },
            { text: 'Дата', value: 'issueDate', formatter: formatters.date },
            { text: 'Статус', value: 'statusName' },
            { text: 'Извършил прегледа', value: 'doctorName' }
        ];

        private async mounted() {
            if (!this.filter?.startDate || !this.filter?.endDate) {
                this.filter.fillCurrentMonth();
            }
            await this.getTableItems();
        }

        private async getTableItems() {
            this.$loading.show();
            try {
                this.items = [];
                this.fillDoctorEmployee();
                const response = await unpostedFormService.getUnpostedForms(this.filter);
                this.items = response.data;
            } finally {
                this.$loading.hide();
            }
        }

        private fillDoctorEmployee() {
            this.filter.practiceId = this.practiceId;
            this.filter.doctorEmployeeSeqNumber = this.doctorEmployeeSeqNumber;
        }

        private getEditRoute(item: UnpostedFormDto) {
            if (item.formType === formTypes.eimmunization) {
                // За имунизациите сървърът връща повече информация в клас-наследник.
                const { patientId, immunizationSeqNumber } = item as UnpostedImmunizationDto;
                return immunizationSeqNumber
                    ? `/Exam/${item.formType}/Edit/${patientId}/${immunizationSeqNumber}`
                    : 'NoKey';
            } else if (item.formType.includes(formTypes.prescription)) {
                return `/Exam/${item.formType}/Edit/${item.examId}/${item.formId}`;
            }
            return `/Exam/${item.formType}/Edit/${item.formId}`;
        }

        private async postAction(item: UnpostedFormDto) {
            if (item.formType.includes(formTypes.referral)) {
                await this.referralAction(item);
            } else if (item.formType === formTypes.ambulatorySheet) {
                await this.examActions(item);
            } else if (item.formType.includes(formTypes.prescription)) {
                await this.prescriptionActions(item);
            } else if (item.formType === formTypes.eimmunization) {
                // За имунизациите сървърът връща повече информация в клас-наследник.
                await this.immunizationActions(item as UnpostedImmunizationDto);
            } else if (item.formType === formTypes.medicalNote) {
                await this.medicalNoteAction(item);
            }
        }

        private async referralAction(item: UnpostedFormDto) {
            if (!item.statusCode) {
                const response = await nhisReferralService.postReferral(this.requestData(item.formId, item.formType));
                if (response) {
                    this.nofitySuccess(resultType.sent, response.nrn);
                }
                await this.getTableItems();
            }
        }

        private async medicalNoteAction(item: UnpostedFormDto) {
            if (!item.statusCode) {
                const response = await nhisMedicalNoteService.postMedicalNote(
                    this.requestData(item.formId, item.formType)
                );
                if (response) {
                    this.nofitySuccess(resultType.sent, response.nrn);
                }
                await this.getTableItems();
            }
        }

        private async examActions(item: UnpostedFormDto) {
            if (!item.statusCode) {
                const response = await nhisExamService.postOpeningExam(this.requestData(item.formId, item.formType));
                if (response) {
                    this.nofitySuccess(resultType.sent, response.nrn);
                }
                await this.getTableItems();
            } else if (item.statusCode === NhisExamStatusCode.Open) {
                const response = await nhisExamService.postClosingExam(this.requestData(item.formId, item.formType));
                if (response) {
                    this.nofitySuccess(resultType.closed, response.nrn);
                }
                await this.getTableItems();
            } else if (item.statusCode === NhisExamStatusCode.ClosedToBeEdited) {
                const response = await nhisExamService.postCorrectionExam(this.requestData(item.formId, item.formType));
                if (response) {
                    this.nofitySuccess(resultType.edited, response.nrn);
                }
                await this.getTableItems();
            }
        }

        private async prescriptionActions(item: UnpostedFormDto) {
            if (!item.statusCode) {
                const prescriptionFormType =
                    item.formType === ExamDocumentTypeCode.PrescriptionNhifTypeA
                        ? PrescriptionFormTypeCode.Nhif5a
                        : item.formType === ExamDocumentTypeCode.PrescriptionNhif
                          ? PrescriptionFormTypeCode.Nhif5
                          : item.formType === ExamDocumentTypeCode.PrescriptionNhifGreen
                            ? PrescriptionFormTypeCode.Green
                            : item.formType === ExamDocumentTypeCode.PrescriptionNhifYellow
                              ? PrescriptionFormTypeCode.Yellow
                              : PrescriptionFormTypeCode.White;
                const response = await nhisPrescriptionService.postPrescription(
                    new PrescriptionKey(item.examId, item.formId),
                    prescriptionFormType
                );
                if (response) {
                    this.nofitySuccess(resultType.sent, response.nrn);
                }
                await this.getTableItems();
            }
        }

        private async immunizationActions(item: UnpostedImmunizationDto) {
            if (!item.statusCode) {
                const response = await nhisImmunizationService.postImmunization(
                    item.patientId,
                    item.immunizationSeqNumber,
                    item.isNewImmunization
                );
                if (response) {
                    this.nofitySuccess(resultType.sent, response.nrn);
                }
                await this.getTableItems();
            }
        }

        private nofitySuccess(action: string, nrn: string) {
            this.$notifier.showSuccess('', `Успешно ${action} документ с НРН: ${nrn}`);
        }

        private requestData(entityId: number, formType: string) {
            return nhisService.requestData(entityId, formType);
        }
    }
</script>
