<template>
    <v-form ref="form" v-model="isFormValid">
        <v-card class="ma-auto">
            <v-card-title>Въведете паролата за финансови справки</v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="4">
                        <text-field
                            v-model="confPassword"
                            type="password"
                            :rules="[$validator.required, $validator.maxLength(10), $validator.digitsOnly]"
                            label="Въведете паролата за финансови справки"
                        ></text-field> </v-col
                    ><v-col cols="12" md="3"> <btn dense action="Send" @click="checkPinCode" /></v-col></v-row
            ></v-card-text>
        </v-card>
    </v-form>
</template>
<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { employeeService } from '@/service/Employee/EmployeeService';

    @Component
    export default class FinancialStatementsProtection extends Vue {
        @Prop({ required: true })
        private practiceId!: number;

        @Prop({ required: true })
        private doctorEmployeeSeqNumber!: number;

        @Prop({ required: true })
        private value!: boolean;

        private confPassword: string | null = null;
        private isFormValid: boolean = false;
        private wrongPass: boolean = false;

        private async checkPinCode() {
            (this.$refs.form as Vue & { validate: () => boolean }).validate();
            if (this.isFormValid && this.confPassword !== null) {
                const result = await employeeService.confirmPinCode(
                    this.practiceId,
                    this.doctorEmployeeSeqNumber,
                    this.confPassword
                );
                if (result === true) {
                    this.$emit('input', true);
                } else {
                    this.$notifier.showWarning('Грешна паролата за финансови справки', '');
                }
            }
        }

        private async mounted() {
            const result = await employeeService.getEmployeeSettings(this.practiceId, this.doctorEmployeeSeqNumber);
            if (result.pinCode === 'true') {
                this.$emit('input', false);
            } else {
                this.$emit('input', true);
            }
        }
    }
</script>
