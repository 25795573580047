<template>
    <v-row>
        <v-col v-if="documentId > 0 && canPost" cols="3">
            <btn action="Send" block @click="sendLkkDecision()"> Изпращане </btn>
        </v-col>
        <v-col v-if="documentId > 0 && canDelete" cols="3">
            <btn action="Swap" block @click="setActiveToBeDeletedStatus()"> За заличаване </btn>
        </v-col>
        <v-col v-if="documentId > 0 && canReturnToActive" cols="3">
            <btn action="Swap" block @click="returnToActiveStatus()"> Премахни заличаване </btn>
        </v-col>
        <v-col v-if="documentId > 0" cols="3">
            <btn action="Send" block @click="validateLkkDecision()"> Проверка </btn>
        </v-col>
        <v-col v-if="documentId > 0" cols="3">
            <btn action="Send" block @click="downloadXml()"> Сваляне на файл </btn>
        </v-col>
        <v-col v-if="uploadResult && uploadResult.length > 0" cols="3">
            <btn action="Send" @click="resultDialogIsVisible = true">Резултат от изпращане</btn>
        </v-col>
        <v-dialog v-model="resultDialogIsVisible">
            <data-table v-if="tableItems" :headers="headers" :items="tableItems" disable-pagination />
        </v-dialog>
    </v-row>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { LkkDecisionStatusCode } from '@/enum/Exam/LkkDecisionStatusCode';
    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { NssiUploadResponseDto } from '@/model/Integration/Nssi/NssiUploadResponseDto';
    import { lkkDecisionService } from '@/service/Exam/LkkDecisionService';
    import { notifierService } from '@/service/Infrastructure/NotifierService';
    import { localServerNssiService } from '@/service/Integration/LocalServerNssiService';
    import { localServerDiagnosticService } from '@/service/LocalServer/LocalServerDiagnosticService';
    import { userContextCache } from '@/store/User/UserContextCache';

    @Component
    export default class LkkDecisionUpload extends Vue {
        @Prop()
        documentId!: number;

        @Prop()
        status!: string | null;

        resultDialogIsVisible: boolean = false;
        uploadResult: string[] = [];
        xmlString: string | null = null;

        get headers(): IDataTableHeader[] {
            return [
                {
                    text: 'Резултат от изпращане',
                    value: 'item'
                }
            ];
        }

        async downloadXml() {
            const xml = await this.generateXmlFile();
            const element = document.createElement('a');
            const blob = new Blob([xml ?? ''], { type: 'application/xml' });
            const url = URL.createObjectURL(blob);
            element.setAttribute('href', url);
            element.setAttribute('download', `LkkDecision-${this.documentId}`);
            element.click();
        }

        get canPost() {
            return (
                !this.status ||
                this.status === LkkDecisionStatusCode.None ||
                this.status === LkkDecisionStatusCode.ActiveToBeDeleted
            );
        }

        get canDelete() {
            return this.status === LkkDecisionStatusCode.Active;
        }

        get canReturnToActive() {
            return this.status === LkkDecisionStatusCode.ActiveToBeDeleted;
        }

        get tableItems() {
            return this.uploadResult.map((item) => ({ item }));
        }

        async sendLkkDecision() {
            if (await this.checkLocalServerVersion()) {
                await this.generateXml();
                const response = await this.uploadFile(this.xmlString ?? '');
                await this.getUploadResult(response);
                this.$emit('reload-document');
            }
        }

        setActiveToBeDeletedStatus() {
            this.changeStatus(LkkDecisionStatusCode.ActiveToBeDeleted);
        }

        returnToActiveStatus() {
            this.changeStatus(LkkDecisionStatusCode.Active);
        }

        async validateLkkDecision() {
            if (await this.checkLocalServerVersion()) {
                await this.generateXml();
                const response = await this.validateFile(this.xmlString ?? '');
                if (response) {
                    response.isTest = true;
                    await this.getValidationResult(response);
                }
            }
        }

        async generateXml() {
            this.xmlString = await this.generateLkkDecision();
            console.log(this.xmlString);
        }

        generateXmlFile() {
            const isFileCreate = true;
            return this.generateLkkDecision(isFileCreate);
        }

        async checkLocalServerVersion() {
            const requiredVersion = '1.0.13.0';
            const result = await localServerDiagnosticService.getVersion();
            if (result !== requiredVersion) {
                notifierService.showWarning(
                    'Внимание',
                    `Версията на локалния сървър е ${result}, изисква се версия ${requiredVersion}!
`
                );
                return false;
            }
            return true;
        }

        generateLkkDecision(isFileCreate: boolean = false): Promise<string> {
            if (this.documentId) {
                return lkkDecisionService.generateLkkDecisionXml(this.documentId, isFileCreate);
            }
            return Promise.resolve('');
        }

        async uploadFile(xmlString: string): Promise<NssiUploadResponseDto | null> {
            const isLocalServerActive = await localServerDiagnosticService.checkIsLocalServerActive();
            if (isLocalServerActive) {
                try {
                    const uploadResponse = await localServerNssiService.uploadLkkDecision(xmlString);
                    return uploadResponse?.data;
                } catch {
                    this.$notifier.showWarning(
                        '',
                        'Възникна проблем при изпращане на решение на лекарска консултативна комисия'
                    );
                }
            }
            return null;
        }

        async validateFile(xmlString: string): Promise<NssiUploadResponseDto | null> {
            const isLocalServerActive = await localServerDiagnosticService.checkIsLocalServerActive();
            if (isLocalServerActive) {
                try {
                    const uploadResponse = await localServerNssiService.validateLkkDecision(xmlString);
                    return uploadResponse?.data;
                } catch {
                    this.$notifier.showWarning(
                        '',
                        'Възникна проблем при изпращане на решение на лекарска консултативна комисия'
                    );
                }
            }
            return null;
        }

        async getUploadResult(response: NssiUploadResponseDto | null) {
            const { current } = userContextCache;
            if (current && response) {
                response.sickSheetId = this.documentId;
                this.uploadResult = [];
                console.log(response);
                const result = await lkkDecisionService.updateLkkDecisionStatus(current.practiceId, response);
                if (result) {
                    this.uploadResult = result;
                    this.resultDialogIsVisible = true;
                }
            }
        }

        async getValidationResult(response: NssiUploadResponseDto | null) {
            const { current } = userContextCache;
            if (current && response) {
                this.uploadResult = [];
                console.log(response);
                const result = await lkkDecisionService.getLkkDecisionValidateResult(current.practiceId, response);
                if (result) {
                    this.uploadResult = result;
                    this.resultDialogIsVisible = true;
                }
            }
        }

        changeStatus(status: string) {
            this.$emit('status-change', status);
        }
    }
</script>
