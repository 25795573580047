<template>
    <v-row>
        <!--        За момента скривам този чекбокс, защото не сме сигурни има ли такъв начин на изписване с различно дозировки за деня или са само за период
                    ако се наложи да го има, трябва да се преработи още изготвянето на json-а за протокола-->
        <v-col cols="12">
            <v-checkbox
                v-if="!calculateDosage && value.medicineTakeFrequency > 1"
                v-model="value.useCustomQuantity"
                label="Искам да използвам различни стойности за различните приложения"
                dense
                @change="onUseCustomQuantityChange"
            />
        </v-col>
        <v-col md="1" cols="6">
            <text-field
                v-model.number="value.packageCount"
                :label="nhifDrug.isQuantityByForm ? 'Ед. брой' : 'Брой опаковки'"
                min="1"
                max="999"
                type="number"
                :value="1"
                @input="onCountChange"
            />
        </v-col>
        <v-col v-if="!calculateDosage" md="1" cols="6">
            <text-field
                v-model.number="value.medicineTakeFrequency"
                type="number"
                min="1"
                max="999"
                required="isDrugChoose"
                label="Пъти"
                :rules="isDrugChoose ? onlyIntegerNumbers : []"
                @input="onFrequencyChange"
            />
        </v-col>
        <v-col v-if="!calculateDosage" md="1" cols="6">
            <text-field
                v-for="(quantity, index) in value.medicineTakeQuantity"
                :key="index"
                v-model.number="value.medicineTakeQuantity[index]"
                type="number"
                min="1"
                max="999"
                required="isDrugChoose"
                :label="
                    isDrugProtocol && nhifDrug.unitMeasure && nhifDrug.unitMeasure !== '{count}'
                        ? `По ${nhifDrug.unitMeasure}`
                        : 'Броя'
                "
                value="1"
                @input="calculateDrug"
            />
        </v-col>
        <v-col md="1" cols="6">
            <text-field
                v-model.number="value.medicineDosagePeriodCount"
                type="number"
                min="1"
                max="999"
                label="Период:"
                @input="calculateDrug"
            />
        </v-col>
        <v-col md="1" cols="6">
            <dropdown
                v-if="isPrescriptionType5"
                v-model="value.medicineDosageUnitCode"
                :items="nhisPeriodUnits"
                item-text="name"
                item-value="code"
                label="Период за:"
                @input="calculateDrug"
            />
        </v-col>
        <v-col md="1" cols="6">
            <text-field
                v-if="isPrescriptionType5"
                v-model.number="value.therapyDuration"
                type="number"
                min="1"
                max="999"
                label="Продължителност на лечението"
            />
        </v-col>
        <v-col md="6" cols="12">
            <text-field
                v-if="calculateDosage"
                v-model="value.dosage"
                class="required"
                label="Инсулинова доза"
                hint="Xmg/kg + Xmg/kg + Xmg/kg"
                :rules="onlyDigitAndSeparatorAndMaxChars"
                @input="calculateStripsPackageCount"
            />
            <text-field v-else v-model="writeDosage" disabled label="Схема на приемане" />
        </v-col>
    </v-row>
</template>

<script lang="ts">
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

    import { NhisPeriodUnitCode, nhisPeriodUnits } from '@/enum/Nhis/NhisPeriodUnitCode';
    import { DosageInstructionDto } from '@/model/Exam/Prescription/DosageInstructionDto';
    import { PrescriptionDrugConfig } from '@/model/Exam/Prescription/PrescriptionDrugConfig';
    import { NhifDrugDto } from '@/model/Nomenclature/NhifDrugDto';

    type ruleType<T> = ((val: T) => void)[] | boolean[] | string[];

    const drugConfig = {
        maxPackageCount: 999,
        maxDays: 30,
        daysInYear: 365,
        daysInMonth: 30,
        daysInWeek: 7,
        hoursPerDay: 24,
        minutesPerHour: 60,
        secondsPerMinute: 60,
        // Средна стойност на една капка в мл (20 капки = 1 мл)
        oneDropInMl: 0.05,
        oneDropOfGel: 1,
        defaultQuantityInMl: 5,
        maxDaysTakingDrug: 30,
        maxDosageChars: 100,
        checkStripsName: 'ТЕСТ ЛЕНТИ'
    };

    const drugRegexConfig = {
        regexForMl: /(?<quantity>[\d+.,?\d*]+\s?(?<type>ml|ML)+)/gu,
        regexForGrams: /(?<quantity>[\d+.,?\d*]+\s?(?<type>g|гр.)+)/gu,
        regexForDoses: /(?<quantity>[\d+.,?\d*]+\s?(?<type>dos|doses)+)/gu,
        regexForDigits: /(?<digit>[\s,+ /]+)/gu,
        regexForMcgToKg: /(?<quantity>[0-9]{1,3}[^IUml]*)/gu
    };

    @Component
    export default class DrugCalculator extends Vue {
        @Prop()
        value!: DosageInstructionDto;

        @Prop()
        nhifDrug!: NhifDrugDto;

        @Prop({ default: false })
        isDrugProtocol!: boolean;

        @Prop({ default: false })
        isPrescriptionType5!: boolean;

        @Prop({ default: false })
        calculateDosage!: boolean;

        @Prop({ default: false })
        checkStrips!: boolean;

        private nhisPeriodUnits: { code: NhisPeriodUnitCode; name: string }[] = nhisPeriodUnits;
        private initialDosage: string = '';

        private onlyIntegerNumbers: ruleType<number> = [
            (val: number) => Number.isInteger(val) || 'Допуска се въвеждането само на цели числа'
        ];

        private onlyDigitAndSeparatorAndMaxChars: ruleType<string> = [
            (val: string) =>
                (val && !val.match(/[a-zA-Zа-яА-Я]+/gmu)) ||
                'Допустимият шаблон за описване се състои от число и разделител от тип: "интервал", "+", "/", "запетая"',
            (val: string) =>
                (val && val.length <= drugConfig.maxDosageChars) ||
                `Дължината на текста не може да е повече от ${drugConfig.maxDosageChars} символа`
        ];

        private onUseCustomQuantityChange(newVal: boolean) {
            const defaultQuantity = 1;
            this.value.medicineTakeQuantity = [];
            const frequency = newVal ? (this.value.medicineTakeFrequency ?? 1) : 1;

            for (let currentQuantity = 1; currentQuantity <= frequency; currentQuantity++) {
                this.value.medicineTakeQuantity.push(defaultQuantity);
            }
            this.calculateDrug();
        }

        private onFrequencyChange(newVal: number | null) {
            if (newVal) {
                this.onUseCustomQuantityChange(this.value.useCustomQuantity);
            }
            this.calculateDrug();
        }

        private get writeDosage() {
            let dayValue: string = 'брой';
            let timesValue: string = 'път';
            this.value.dosage = '';

            if (this.value.medicineTakeFrequency && this.value.medicineTakeQuantity) {
                let dosage = '';
                for (const quantity of this.value.medicineTakeQuantity) {
                    if (quantity > 1) {
                        dayValue = 'броя';
                    }
                    if ((this.value?.medicineTakeFrequency ?? 0) > 1) {
                        timesValue = 'пъти';
                    }
                    dosage = `${
                        this.value.useCustomQuantity ? 1 : this.value.medicineTakeFrequency
                    } ${timesValue} по ${quantity} ${
                        this.isDrugProtocol && this.nhifDrug?.unitMeasure !== '{count}'
                            ? this.nhifDrug.unitMeasure
                            : dayValue
                    } `;
                    this.value.dosage += dosage;
                }
                return this.value.dosage;
            }
            return "Моля въведете данни за 'Пъти' и 'Броя' на приема лек. продукт";
        }

        private get isDrugChoose() {
            return Boolean(this.nhifDrug.tradeName);
        }

        private onCountChange(value: number) {
            if (this.calculateDosage) {
                this.nhifDrug.packageCount = value;
                this.calculateInsulineDaysTaking(this.initialDosage);
            } else {
                this.calculateDrug();
            }
        }

        private calculateStripsPackageCount(value: string) {
            if (value && this.nhifDrug.internationalName?.includes(PrescriptionDrugConfig.insulin)) {
                this.initialDosage = value;
                this.calculateInsulineDaysTaking(value);
            }
        }

        private calculateDrug() {
            if (this.nhifDrug) {
                if (this.nhifDrug.internationalName?.includes(PrescriptionDrugConfig.insulin)) {
                    this.calculateInsulineDaysTaking(this.initialDosage);
                } else if (this.isDrugProtocol && this.nhifDrug.unit) {
                    this.calculateProtocolDaysTakingDrug(this.nhifDrug);
                } else {
                    this.calculateDaysTakingDrug(this.nhifDrug);
                }
            }
        }

        private calculateDaysTakingDrug(nhifDrug: NhifDrugDto) {
            const medFormat = nhifDrug.medicineFormat;
            let daysTaking = 0;

            if (PrescriptionDrugConfig.itemsGivingByQuantityPerDay.indexOf(medFormat) >= 0 || this.checkStrips) {
                daysTaking = this.basicCalculation(nhifDrug.singlePackage, 1, this.value.packageCount);
            } else if (PrescriptionDrugConfig.earAndEyeDrops.indexOf(medFormat) >= 0) {
                daysTaking = this.calculateEarAndEyeDrops(nhifDrug);
                //Продукти, който са по един брой или се премат наведнъж
            } else if (PrescriptionDrugConfig.itemsForOneTimeUse.indexOf(medFormat) >= 0) {
                daysTaking = this.basicCalculation(nhifDrug.singlePackage, 1, this.value.packageCount);
            } else if (PrescriptionDrugConfig.gelAndOintmentItems.indexOf(medFormat) >= 0) {
                daysTaking = this.basicCalculation(
                    this.findQuantityInOnePackage(nhifDrug.quantity),
                    drugConfig.oneDropOfGel,
                    this.value.packageCount
                );
            } else if (PrescriptionDrugConfig.itemsWithDoseInName.indexOf(medFormat) >= 0) {
                daysTaking = this.basicCalculation(
                    this.findQuantitySpray(nhifDrug.quantity),
                    1,
                    this.value.packageCount
                );
            }
            if (daysTaking > 0) {
                this.value.therapyDuration = this.onPeriodAndUnitCodeDosageChange(
                    this.value.medicineDosagePeriodCount,
                    this.value.medicineDosageUnitCode,
                    daysTaking ?? 0
                );
            }
        }

        private calculateEarAndEyeDrops(nhifDrug: NhifDrugDto) {
            const days = this.basicCalculation(
                this.findQuantityOfDrugInMl(nhifDrug.quantity),
                drugConfig.oneDropInMl,
                nhifDrug.packageCount
            );
            if (days > drugConfig.maxDaysTakingDrug) {
                return drugConfig.maxDaysTakingDrug;
            }
            return days;
        }

        private findQuantityOfDrugInMl(quantity: string) {
            const found = this.regexFindResult(quantity, drugRegexConfig.regexForMl);
            const quantityOfDrugInMl: number = found
                ? Number(found[0].replace(/[^\d.,]/gu, ''))
                : drugConfig.defaultQuantityInMl;
            return quantityOfDrugInMl;
        }

        private findQuantityInOnePackage(quantity: string) {
            const foundQuantity = this.regexFindResult(quantity, drugRegexConfig.regexForGrams);
            const quantityInOnePackage: number = foundQuantity ? Number(foundQuantity[0].replace(/\D/gu, '')) : 0;
            return quantityInOnePackage;
        }

        private findQuantitySpray(quantity: string) {
            const foundSpray = this.regexFindResult(quantity, drugRegexConfig.regexForDoses);
            const quantityInOnePackage: number = foundSpray ? Number(foundSpray[0].replace(/\D/gu, '')) : 0;
            return quantityInOnePackage;
        }

        private regexFindResult(quantity: string, regex: RegExp) {
            const found = quantity.match(regex);
            if (found) {
                return found;
            }
            return false;
        }

        private calculateProtocolDaysTakingDrug(nhifDrug: NhifDrugDto) {
            let daysTaking = 0;

            daysTaking = this.basicCalculation(
                this.nhifDrug.singlePackage,
                nhifDrug.unit ?? 0,
                this.value.packageCount
            );

            if (daysTaking > 0) {
                this.value.therapyDuration = this.onPeriodAndUnitCodeDosageChange(
                    this.value.medicineDosagePeriodCount,
                    this.value.medicineDosageUnitCode,
                    daysTaking ?? 0
                );
            }
        }

        // Следният метод преобразува мерната единица за време в дни, защото при изпращането на данните
        // е нужно да се пратят дните на прием
        private onPeriodAndUnitCodeDosageChange(period: number, code: string, therapyDuration: number): number {
            if (this.nhifDrug && (this.nhifDrug?.nhifId ?? 0) > 1 && this.value.therapyDuration) {
                if (code === NhisPeriodUnitCode.Second) {
                    return Math.ceil(
                        period / (drugConfig.hoursPerDay * drugConfig.minutesPerHour * drugConfig.secondsPerMinute)
                    );
                } else if (code === NhisPeriodUnitCode.Minute) {
                    return Math.ceil(period / (drugConfig.hoursPerDay * drugConfig.minutesPerHour));
                } else if (code === NhisPeriodUnitCode.Hour) {
                    return Math.ceil(period / drugConfig.hoursPerDay);
                } else if (code === NhisPeriodUnitCode.Day) {
                    return therapyDuration * period;
                } else if (code === NhisPeriodUnitCode.Week) {
                    return therapyDuration * period * drugConfig.daysInWeek;
                } else if (code === NhisPeriodUnitCode.Month) {
                    return therapyDuration * period * drugConfig.daysInMonth;
                } else if (code === NhisPeriodUnitCode.Year) {
                    return therapyDuration * period * drugConfig.daysInYear;
                }
            }
            return 0;
        }

        private basicCalculation(
            quantityInOnePackage: number,
            quantityInOneSimple: number,
            packageCount: number
        ): number {
            if (this.value.medicineTakeFrequency && this.value.medicineTakeQuantity) {
                //Защото medicineTakeQuantity е масив трябва да сумираме отделните количества, ако се използва useCustomQuantity,
                //ако не се използва то тогава броя на елементите в medicineTakeQuantity ще бъде само един
                const quantity = this.value.medicineTakeQuantity.reduce(
                    (partialSum, element) => partialSum + element,
                    0
                );
                //Ако използваме различни количества за всеки прием, то тогава честотата трябва да бъде 1, защото
                //в горната променлива quantity сме сумирали всички отделни количества, а когато не използваме различни количества
                // за всеки прием вземаме frequency такова, каквото е въведено
                const frequency = this.value.useCustomQuantity ? 1 : this.value.medicineTakeFrequency;
                let days: number = 0;
                if (this.nhifDrug.isDivisible && this.nhifDrug.isQuantityByForm) {
                    //Когато изчисляваме делимо лекарство умножаваме packageCount
                    // (в този случай това са отделните бройки) * количеството в една бройка(флакон или друго)
                    days = Math.floor((packageCount * quantityInOneSimple) / (frequency * quantity));
                } else {
                    //При неделимите лекарства
                    // умножаваме броя на опаковките * броя единици в една опаковка * по количеството на една единица
                    days = Math.floor(
                        (quantityInOnePackage * packageCount * quantityInOneSimple) / (frequency * quantity)
                    );
                }
                return days;
            }
            return 1;
        }

        private calculateInsulineDaysTaking(value: string) {
            if (value && this.value.packageCount) {
                //IU => international unit
                //Това е предвидено основно за смятане на дните на прием на инсулин и на лекарствата които се приемата mcg/кг
                const dosage = value;
                const splitDosage: number = dosage
                    .split(/[\s,+/]+/u)
                    .map((numb) => Number(numb))
                    .reduce((prevValue, currentVal) => prevValue + currentVal, 0);
                const match = Array.from(this.nhifDrug.quantity.matchAll(drugRegexConfig.regexForMcgToKg), (matchStr) =>
                    Number(matchStr[0])
                );
                const quantityUIperOnePenfil: number =
                    this.nhifDrug.unit !== null
                        ? this.nhifDrug.unit
                        : match.length > 0 && match.length === 1
                          ? match[0]
                          : match[0] * match[1];

                if (splitDosage >= 1) {
                    if (this.nhifDrug.isQuantityByForm) {
                        this.value.therapyDuration = Math.floor(
                            (quantityUIperOnePenfil * this.value.packageCount) / splitDosage
                        );
                    } else {
                        this.value.therapyDuration = Math.floor(
                            (quantityUIperOnePenfil * this.value.packageCount * this.nhifDrug.singlePackage) /
                                splitDosage
                        );
                    }
                }

                this.value.therapyDuration = this.onPeriodAndUnitCodeDosageChange(
                    this.value.medicineDosagePeriodCount,
                    this.value.medicineDosageUnitCode,
                    this.value.therapyDuration ?? 0
                );
            }
        }

        @Watch('value.therapyDuration')
        onTherapyDurationChange(val: number | null) {
            if (!val) {
                this.value.therapyDuration = 1;
            }
        }
    }
</script>
