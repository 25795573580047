<template>
    <div>
        <v-row>
            <v-col class="d-flex" cols="12">
                <v-file-input
                    v-model="value"
                    label="Файл"
                    dense
                    :accept="accept ? accept : ''"
                    :error="isOverSize"
                    :rules="required ? [$validator.required] : []"
                    @change="onFileInput($event)"
                    @click:clear="onFileInput($event)"
                />
            </v-col>
        </v-row>
        <alert :value="isOverSize" class="mt-3" type="warning">
            Общата големина на файла е по-голяма от максимално допустимата ({{ filesTotalMaxSize }} мв).
        </alert>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { FileCommand } from '@/model/File/FileCommand';

    @Component
    export default class FileInput extends Vue {
        @Prop({ default: [] })
        private value!: FileCommand;

        @Prop({ default: false })
        private required!: boolean;

        @Prop()
        private accept?: string;

        //Залагаме по подразбиране максималната обща големи на файловете да бъде 50 мб
        @Prop({ default: 50 })
        private filesTotalMaxSize!: number;

        private totalSize: number = 0;

        private get isOverSize() {
            const mbToByte = 1048576;
            return this.totalSize > this.filesTotalMaxSize * mbToByte;
        }

        private onFileInput(file: File) {
            if (file?.size) {
                this.$loading.show();

                this.totalSize += file.size;

                this.value.fileName = file.name;

                this.getFileContent(file).then((resolve) => {
                    this.value.content = resolve;
                    this.$loading.hide();
                });
                this.$emit('change', this.value);
            } else {
                this.$emit('change', null);
            }
        }

        private getFileContent(fileContent: File) {
            return new Promise<number[]>((resolve) => {
                const fileReader = new FileReader();
                fileReader.readAsArrayBuffer(fileContent);

                fileReader.onload = () => {
                    const array = new Uint8Array(fileReader.result as ArrayBuffer);
                    const byteArray: number[] = [];

                    for (let index = 0; index < array.length; index++) {
                        byteArray.push(array[index]);
                    }
                    resolve(byteArray);
                };
            });
        }
    }
</script>
