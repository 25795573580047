export class LkkDecisionDto {
    public id: number = 0;
    public visitId: number = 0;
    public lkkDecisionNumber: number | null = null;
    public lkkDecisionDate: Date | null = new Date();
    public issueDate: Date = new Date();
    public sessionNumber: string | null = null;
    public complaintNumber: string | null = null;
    public complaintDate: Date | null = null;
    public appealerTypeCode: string | null = null;
    public appealerName: string | null = null;
    public appealReasonCode: string | null = null;
    public sickSheetNumber: string | null = null;
    public issuedByPracticeName: string | null = null;
    public issuedByPracticeNumber: string | null = null;
    public issuedByPracticeCityId: number | null = null;
    public issuedByPracticeAddress: string | null = null;
    public sickSheetDays: number | null = null;
    public sickSheetStartDate: Date | null = null;
    public sickSheetEndDate: Date | null = null;
    public icdCode: string | null = null;
    public patientWorkplaceId: number = 0;
    public labTest: string | null = null;
    public conclusionCode: string | null = null;
    public lkkDecisionSickSheetDays: number | null = null;
    public lkkDecisionSickSheetStartDate: Date | null = null;
    public lkkDecisionSickSheetEndDate: Date | null = null;
    public lkkMotive: string | null = null;
    public lkkDecisionCopies: number | null = null;
    public statusCode: string | null = null;
    //Данни за лкк комисията, може да съдържа до 5 членове според схемата на нои
    public lkkCommissionName: string | null = null;
    public IsSpecializedCommission: boolean = false;
    public familyNameDoctor1: string | null = null;
    public specialtyNameDoctor1: string | null = null;
    public uin1: string | null = null;
    public familyNameDoctor2: string | null = null;
    public specialtyNameDoctor2: string | null = null;
    public uin2: string | null = null;
    public familyNameDoctor3: string = '';
    public specialtyNameDoctor3: string = '';
    public uin3: string = '';
    public familyNameDoctor4: string = '';
    public specialtyNameDoctor4: string = '';
    public uin4: string = '';
    public familyNameDoctor5: string = '';
    public specialtyNameDoctor5: string = '';
    public uin5: string = '';
    public deleteReason: string = '';

    public nameOfHospital: string = '';
    public cityOfHospital: string = '';
    public addressOfHospital: string = '';
    public nhifCode: string = '';
}
